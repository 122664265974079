import { CopyOutline } from '@deepup/icons';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface NotCopiedDialogProps {
  showNotCopiedWarning: boolean;
  setShowNotCopiedWarning: Dispatch<SetStateAction<boolean>>;
  password?: string;
  onContinue: () => void;
}

export const NotCopiedWarningDialog: FC<NotCopiedDialogProps> = ({
  showNotCopiedWarning,
  password,
  setShowNotCopiedWarning,
  onContinue,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      PaperProps={{
        elevation: 0,
      }}
      open={showNotCopiedWarning}
    >
      <DialogTitle>
        <Typography fontWeight="bold" variant="h6">
          {t('pages.user.form.dialog.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{t('pages.user.form.dialog.content')}</Typography>
        <Box my={2} />
        <Button
          onClick={() => {
            password && navigator.clipboard.writeText(password);
          }}
          startIcon={<CopyOutline />}
          variant="outlined"
        >
          {t('pages.user.form.custom.copyToClipboard')}
        </Button>
      </DialogContent>
      <DialogActions sx={(theme) => ({ padding: theme.spacing(2.5) })}>
        <Button color="secondary" onClick={() => setShowNotCopiedWarning(false)} variant="outlined">
          {t('common.cancel')}
        </Button>
        <Box mx={0.5} />
        <Button onClick={onContinue} variant="contained">
          {t('common.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
