import { boolean, object, string, number, type InferType } from 'yup';

type SchemaError = {
  name: string;
  dashboardSource: string;
  onlySpaces: string;
  countryCodeFormat: string;
  priceModel: string;
};

const priceModelSchema = (error: SchemaError) =>
  number()
    .transform((value, originalValue) => {
      if (originalValue === '') return null;

      // allow number input with comma
      if (isNaN(value) && originalValue.includes(','))
        return parseFloat(originalValue.replace(/,/g, '.'));

      return value;
    })
    .nullable()
    .notRequired()
    .typeError(error.priceModel)
    .min(0, error.priceModel);

export const organizationFormSchema = ({ error }: { error: SchemaError }) =>
  object({
    name: string().required(error.name).matches(/\S/, error.onlySpaces),
    abbreviation: string().nullable(),
    dashboardSource: string().url(error.dashboardSource).nullable(),
    invoiceToolEnabled: boolean(),
    dbbAssetReviewEnabled: boolean(),
    priceModel: object({
      pricePerMeterClosed: priceModelSchema(error),
      pricePerMeterOpen: priceModelSchema(error),
      pricePerMeterLayjet: priceModelSchema(error),
      pricePerMeterOther: priceModelSchema(error),
      pricePerScannerPerMonth: priceModelSchema(error),
    }),
    billingAddress: object({
      companyName: string(),
      contactPersonName: string(),
      streetAndNumber: string(),
      zipCode: string(),
      city: string(),
      countryCode: string()
        .test('empty-or-two-uppercase', error.countryCodeFormat, (value) => {
          if (!value) {
            return true;
          }

          return /^[A-Z]{2}$/.test(String(value));
        })
        .notRequired(),
      vatNumber: string(),
    }),
    labeledMeterInvoiceFormat: string(),
  });

export type OrganizationFormSchema = InferType<ReturnType<typeof organizationFormSchema>>;
