import { ArrowUpBox } from '@deepup/icons';
import { Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { ProjectMappingApiExportProps } from './ProjectMappingApiExport';

type ButtonOpenUploadDialogProps = Pick<ProjectMappingApiExportProps, 'disabled'> & {
  onClick: () => void;
  file?: File;
};

export const ButtonOpenUploadDialog = ({
  onClick,
  file,
  disabled,
}: ButtonOpenUploadDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const iconSize = theme.spacing(3);

  const buttonColor = file ? 'secondary' : 'primary';
  const buttonVariant = file ? 'outlined' : 'contained';
  const buttonLabel = file
    ? t('pages.projects.create.uploadApiExport.btnReplaceMappingFile')
    : t('pages.projects.create.uploadApiExport.btnUploadMappingFile');

  return (
    <Button
      color={buttonColor}
      disabled={disabled}
      onClick={onClick}
      startIcon={<ArrowUpBox height={iconSize} width={iconSize} />}
      variant={buttonVariant}
    >
      {buttonLabel}
    </Button>
  );
};
