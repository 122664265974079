import { useCallback } from 'react';

import type { Group, GroupProjectsUpdateRequestData } from '@hooks/api/groups/types';
import { useJsonFetch } from '@hooks/api/useJsonFetch';

export const useGroupsProjectsUpdateApi = () => {
  const jsonFetch = useJsonFetch<Group>();

  const fetcher = useCallback(
    ({ id, projectPermissions }: GroupProjectsUpdateRequestData) => {
      const validatedProjectPermissions = projectPermissions.map(
        ({ projectId, hasDocumentation, hasMonitoring, hasInvoiceTool, backofficeRole }) => ({
          projectId,
          hasDocumentation,
          hasMonitoring,
          hasInvoiceTool,
          backofficeRole,
        }),
      );

      return jsonFetch(`/groups/${id}/projects`, {
        method: 'PUT',

        body: JSON.stringify(validatedProjectPermissions),
      });
    },
    [jsonFetch],
  );

  return fetcher;
};
