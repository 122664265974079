import { Plus } from '@deepup/icons';
import { Box, Button } from '@mui/material';
import { DataGrid, type GridColDef, type GridRowsProp } from '@mui/x-data-grid';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { renderCellContent } from '@components/TableCell/TableCell';
import type { Project, ProjectPermission } from '@hooks/api/projects';
import { usePlatformPermissions } from '@hooks/usePlatformPermissions';

interface AllProjectsTableProps {
  projects?: Project[];
  searchString?: string;
  onAddProject: (projectsToAdd: Project[]) => void;
  selectedProjects: ProjectPermission[];
  isLoading: boolean;
}

export const AllProjectsTable: FC<AllProjectsTableProps> = ({
  projects = [],
  searchString = '',
  onAddProject,
  selectedProjects,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { isPlatformAdmin, isOrganizationAdmin } = usePlatformPermissions();

  const canEdit = isPlatformAdmin || isOrganizationAdmin;

  const filteredProjects = useMemo(() => {
    return projects.filter((project) => {
      const matchesSearch = searchString
        ? project.name.toLowerCase().includes(searchString.toLowerCase())
        : true;
      const notSelected = !selectedProjects.some(
        (projectPermission) => projectPermission.project.id === project.id,
      );

      return matchesSearch && notSelected;
    });
  }, [projects, searchString, selectedProjects]);

  const handleAddAllProjects = useCallback(() => {
    if (filteredProjects) {
      onAddProject(filteredProjects);
    }
  }, [filteredProjects, onAddProject]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        flex: 1,
        headerName: t('entities.projects.fields.name.label'),
        sortable: true,
        renderCell: (params) =>
          renderCellContent({
            entityType: 'projects',
            params,
            isLoading,
            columnField: 'name',
          }),
      },
      {
        field: 'addAction',
        headerName: '',
        flex: 1,
        minWidth: 300,
        renderHeader: () => (
          <Box display="flex" justifyContent="flex-end" width="100vw">
            <Button
              color="secondary"
              disabled={filteredProjects.length === 0 || !canEdit}
              onClick={handleAddAllProjects}
              startIcon={<Plus />}
              variant="text"
            >
              {t('pages.groupsPermissions.create.components.btnAddAllProjects')}
            </Button>
          </Box>
        ),
        renderCell: (params) => {
          renderCellContent({
            entityType: 'projects',
            params,
            isLoading,
            columnField: 'addAction',
          });

          return (
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Button
                color="secondary"
                disabled={!canEdit}
                onClick={() => onAddProject([params.row])}
                startIcon={<Plus />}
                variant="text"
              >
                {t('pages.groupsPermissions.create.components.btnAddToGroup')}
              </Button>
            </Box>
          );
        },
        sortable: false,
      },
    ],
    [t, isLoading, filteredProjects.length, canEdit, handleAddAllProjects, onAddProject],
  );

  const rows: GridRowsProp = filteredProjects.map((project) => ({
    id: project.id,
    name: project.name,
  }));

  return (
    <Box sx={(theme) => ({ height: theme.spacing(50), width: '100%' })}>
      <DataGrid
        autoHeight
        columns={columns}
        data-testid="table-groups-allProjects"
        disableColumnMenu
        disableRowSelectionOnClick
        loading={isLoading}
        rows={rows}
        sortingOrder={['asc', 'desc']}
      />
    </Box>
  );
};
