import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { useFlags } from 'flagsmith/react';
import { useEffect, useState, type FC } from 'react';
import { useForm, useFormState, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BottomFormNavigation } from '@components/BottomFormNavigation';
import { useOrganizationsApi } from '@hooks/api/organizations';
import type { User } from '@hooks/api/users';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { PlatformUserType, usePlatformPermissions } from '@hooks/usePlatformPermissions';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { isUserInDeepUpOrg } from '@utils/user';

import { useFormUserState } from '../useFormUserState';

import {
  NotCopiedWarningDialog,
  UserFormConnections,
  UserFormMasterData,
} from './UserFormSections';
import { internalUserFormSchema } from './schema';

interface UserFormProps {
  onSubmit: (data: FieldValues) => void;
}

// Container component for managing the whole form state.
export const UserForm: FC<UserFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { isItemEnabled } = useRenderConfig();
  const {
    isPlatformAdmin,
    isAdminOfOrg,
    permissions: { organizationId: adminOrganizationId },
  } = usePlatformPermissions();
  const navigate = useNavigate();
  const featureFlags = useFlags(['invoice_tool_permissions_enabled']);

  const { user: currentUser } = useFormUserState();
  const userFormSchemaWithTranslations = internalUserFormSchema({
    error: {
      name: t('entities.user.fields.name.error'),
      onlySpaces: t('entities.user.fields.name.errorOnlySpaces'),
      email: t('entities.user.fields.email.error'),
      invalidEmail: t('entities.user.fields.invalidEmail.error'),
      organization: t('entities.user.fields.organization.error'),
      userLanguage: t('entities.user.fields.userLanguage.error'),
    },
    isExternal: currentUser?.platformUserType === PlatformUserType.external,
  });
  const { showMessage } = useSnackbarMessage();
  const { data, error } = useOrganizationsApi({ isDeepUpOrg: isUserInDeepUpOrg(currentUser) });
  const { register, handleSubmit, control, setValue, setError, getValues, watch } = useForm<User>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(userFormSchemaWithTranslations),
  });
  const { errors: formErrors } = useFormState({ control });
  const isFormEnabled = isItemEnabled('user.edit.form.mode');
  const [password, setPassword] = useState<string>();
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);
  const [showNotCopiedWarning, setShowNotCopiedWarning] = useState<boolean>(false);
  const [advancedSettings, setAdvancedSettings] = useState<{
    isItemEnabled: boolean;
    isOwnPasswordSet: boolean;
  }>({
    isItemEnabled: false,
    isOwnPasswordSet: false,
  });

  useEffect(() => {
    // in edit mode user email is not editable, therefore it is not part of the form and we need to set it manually so form can be submitted
    if (currentUser?.email) setValue('email', currentUser.email);
  }, [setValue, currentUser?.email]);

  const handleToggleAdvancedSettings = (isItemEnabled: boolean) =>
    setAdvancedSettings({
      ...advancedSettings,
      isItemEnabled,
    });

  const handleChangePassword = (newPassword: string | undefined, copiedToClipboard: boolean) => {
    // This routine is required as the form field for password is filled with a generated password
    // AND we need to know if there is a password generated (in case advanced settings are enabled, for deciding if the submit button should be re-enabled)
    // AND field values are only accessible after submit
    if (newPassword) {
      setValue('password', newPassword);
      setPassword(newPassword);
      setCopiedToClipboard(copiedToClipboard);
    }
  };

  const handleToggleSetOwnPassword = (isOwnPasswordSet: boolean) =>
    setAdvancedSettings({
      ...advancedSettings,
      isOwnPasswordSet,
    });

  const handleSubmitTry = (data: FieldValues) => {
    if (advancedSettings.isItemEnabled && advancedSettings.isOwnPasswordSet && !password)
      return setError('password', { message: t('pages.user.form.messages.errorPassword') });

    if (advancedSettings.isOwnPasswordSet && !copiedToClipboard) {
      setShowNotCopiedWarning(true);

      return;
    }

    onSubmit(isPlatformAdmin ? data : { ...data, organizationId: adminOrganizationId });
  };

  const handleClickCancel = () => {
    navigate('/users');
  };

  const hasFormEnabled =
    isItemEnabled('user.edit.form.mode') &&
    (!currentUser || isAdminOfOrg(currentUser?.organizationId));

  if (error) return <div>failed to load</div>;
  if (!data) return <div>loading...</div>;

  const formErrorHandler = (errors: FieldValues | undefined) => {
    if (errors) {
      showMessage({ message: t('common.missingData'), type: 'error' });
    }
  };

  const { content: organizations } = data;

  const selectedOrganizationId = currentUser?.organizationId ?? watch('organizationId');
  const selectedOrganization = organizations.find(({ id }) => selectedOrganizationId === id);

  const hasInvoiceToolAdminOption =
    selectedOrganization?.invoiceToolEnabled &&
    featureFlags.invoice_tool_permissions_enabled.enabled;

  return (
    <form onSubmit={handleSubmit(handleSubmitTry, formErrorHandler)}>
      <Box display="flex" flexDirection="column">
        <UserFormMasterData
          advancedSettingsProps={{
            onChangeAdvancedSettingsEnabled: handleToggleAdvancedSettings,
            onChangePassword: handleChangePassword,
            onChangeSetOwnPassword: handleToggleSetOwnPassword,
            password,
            settings: advancedSettings,
          }}
          control={control}
          disabled={!hasFormEnabled}
          formErrors={formErrors}
          register={register}
          user={currentUser}
        />

        {(!currentUser || !!currentUser.organizationId) && (
          <UserFormConnections
            control={control}
            disabled={!!currentUser?.organizationId || !hasFormEnabled}
            formErrors={formErrors}
            hasInvoiceToolAdminOption={hasInvoiceToolAdminOption}
            organizations={organizations}
            register={register}
          />
        )}
        <Box my={4} />
        <BottomFormNavigation
          hasNext={isFormEnabled}
          nextType="submit"
          onGoBack={handleClickCancel}
        />
      </Box>
      <NotCopiedWarningDialog
        onContinue={() => onSubmit(getValues())}
        password={password}
        setShowNotCopiedWarning={setShowNotCopiedWarning}
        showNotCopiedWarning={showNotCopiedWarning}
      />
    </form>
  );
};
