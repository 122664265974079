import { ArrowUpBox } from '@deepup/icons';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import type { ProjectMappingError } from './ProjectMappingErrorAlert';

interface UploadDropZoneProps {
  onChange: (uploadedFile?: File) => void;
  onFileCountUploadError: (error: ProjectMappingError) => void;
  onDragUploadError: (error: ProjectMappingError) => void;
}

export const UploadDropZone = ({
  onChange,
  onFileCountUploadError,
  onDragUploadError,
}: UploadDropZoneProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const iconSize = theme.spacing(8);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps, isDragReject } = useDropzone({
    maxFiles: 1,
    accept: {
      'text/csv': ['.csv'],
    },
  });

  useEffect(() => {
    if (isDragReject) {
      onDragUploadError({
        title: t('pages.projects.create.uploadApiExport.messages.errorDnDFileType.title'),
        message: t('pages.projects.create.uploadApiExport.messages.errorDnDFileType.message'),
      });

      return;
    }
    if (fileRejections.length > 0)
      onFileCountUploadError({
        title: t('pages.projects.create.uploadApiExport.messages.errorTooManyFiles.title'),
        message: t('pages.projects.create.uploadApiExport.messages.errorTooManyFiles.message'),
      });
  }, [fileRejections, onFileCountUploadError, isDragReject, onDragUploadError, t]);

  useEffect(() => {
    onChange(acceptedFiles?.[0]);
  }, [acceptedFiles, onChange]);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Stack {...getRootProps({ className: 'dropzone' })} alignItems="center" gap={2}>
        <input {...getInputProps()} />
        <ArrowUpBox fill={theme.palette.primary.main} height={iconSize} width={iconSize} />
        <Stack alignItems="center">
          <Typography>
            {t('pages.projects.create.uploadApiExport.descriptionDragAndDrop')}
          </Typography>
          <Typography textTransform="uppercase">{t('common.or')}</Typography>
        </Stack>
        <Button>{t('pages.projects.create.uploadApiExport.btnBrowseFiles')}</Button>
      </Stack>
    </Stack>
  );
};
