const getBuildEnvironment = () => {
  const {
    VITE_AUTH0_MANAGE,
    VITE_AUTH0_AUDIENCE,
    VITE_AUTH0_CLIENT_ID,
    VITE_AUTH0_DOMAIN,
    VITE_AUTH0_SCOPE,
    VITE_ENABLE_PRODUCTION_PROFILING,
    VITE_SERVICE_API_URL,
    VITE_COCKPIT_URL,
    VITE_CSS_URL,
    VITE_FLAGSMITH_ENV_ID,
    VITE_FLAGSMITH_LISTENING_INTERVAL,
    DEV,
  } = import.meta.env;

  return {
    auth0Manage: VITE_AUTH0_MANAGE,
    auth0Audience: VITE_AUTH0_AUDIENCE,
    auth0ClientId: VITE_AUTH0_CLIENT_ID,
    auth0Domain: VITE_AUTH0_DOMAIN,
    auth0Scope: VITE_AUTH0_SCOPE,
    enableProductionProfiling: VITE_ENABLE_PRODUCTION_PROFILING === 'true',
    serviceApiUrl: VITE_SERVICE_API_URL,
    isDev: DEV,
    cockpitUrl: VITE_COCKPIT_URL,
    cssUrl: VITE_CSS_URL,
    flagsmithEnvId: VITE_FLAGSMITH_ENV_ID,
    flagsmithListeningInterval: VITE_FLAGSMITH_LISTENING_INTERVAL,
  };
};

const getRuntimeEnvironment = () => {
  const env = window.__env__;

  const {
    VITE_AUTH0_MANAGE,
    VITE_AUTH0_AUDIENCE,
    VITE_AUTH0_CLIENT_ID,
    VITE_AUTH0_DOMAIN,
    VITE_AUTH0_SCOPE,
    VITE_ENABLE_PRODUCTION_PROFILING,
    VITE_SERVICE_API_URL,
    VITE_COCKPIT_URL,
    VITE_CSS_URL,
    VITE_FLAGSMITH_ENV_ID,
    VITE_FLAGSMITH_LISTENING_INTERVAL,
    DEV,
  } = env;

  const envVars = {
    auth0Manage: VITE_AUTH0_MANAGE,
    auth0Audience: VITE_AUTH0_AUDIENCE,
    auth0ClientId: VITE_AUTH0_CLIENT_ID,
    auth0Domain: VITE_AUTH0_DOMAIN,
    auth0Scope: VITE_AUTH0_SCOPE,
    enableProductionProfiling: VITE_ENABLE_PRODUCTION_PROFILING
      ? VITE_ENABLE_PRODUCTION_PROFILING === 'true'
      : undefined,
    serviceApiUrl: VITE_SERVICE_API_URL,
    isDev: DEV,
    cockpitUrl: VITE_COCKPIT_URL,
    cssUrl: VITE_CSS_URL,
    flagsmithEnvId: VITE_FLAGSMITH_ENV_ID,
    flagsmithListeningInterval: VITE_FLAGSMITH_LISTENING_INTERVAL,
  };

  // Filter out entries with undefined values
  const definedVars = Object.fromEntries(
    Object.entries(envVars).filter(([, value]) => value !== undefined),
  );

  return definedVars;
};

export const useEnvironment = (): Environment => {
  const env = {
    ...getBuildEnvironment(),
    ...getRuntimeEnvironment(),
  };

  return env;
};

export type Environment = {
  auth0Manage: string;
  auth0Audience: string;
  auth0ClientId: string;
  auth0Domain: string;
  auth0Scope: string;
  serviceApiUrl: string;
  enableProductionProfiling: boolean;
  isDev: boolean;
  cockpitUrl: string;
  cssUrl: string;
  flagsmithEnvId: string;
  flagsmithListeningInterval: string;
};
