import { useCallback } from 'react';

import type { Group, GroupUserUpdateRequestData } from '@hooks/api/groups/types';
import { useJsonFetch } from '@hooks/api/useJsonFetch';

export const useGroupsUserUpdateApi = () => {
  const jsonFetch = useJsonFetch<Group>();

  const fetcher = useCallback(
    ({ id, userPermissions }: GroupUserUpdateRequestData) => {
      const apiUserPermissions: { userId: string; isGroupAdmin: boolean }[] = userPermissions.map(
        ({ userId, isGroupAdmin }) => ({
          userId,
          isGroupAdmin,
        }),
      );

      return jsonFetch(`/groups/${id}/users`, {
        method: 'PUT',

        body: JSON.stringify(apiUserPermissions),
      });
    },
    [jsonFetch],
  );

  return fetcher;
};
