import { Box } from '@mui/material';
import {
  DataGrid,
  type GridColDef,
  type GridFilterModel,
  type GridPaginationModel,
  type GridSortModel,
} from '@mui/x-data-grid';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DeleteDialog } from '@components/DeleteDialog';
import { renderCellContent } from '@components/TableCell/TableCell';
import type { GroupWithOrganization } from '@hooks/api/groups';
import { useGroupsDeleteApi } from '@hooks/api/groups';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

import { GroupsContextMenu } from './GroupsContextMenu';

interface GroupsTableProps {
  isDeepUpInternal?: boolean;
  groups: GroupWithOrganization[];
  name: string;
  organizationId: string;
  onEdit: (groupsId: string) => void;
  isLoading: boolean;
  page: number;
  size: number;
  onPageChange: (page: number) => void;
  onSizeChange: (pageSize: number) => void;
  onSortModelChange: (model: GridSortModel) => void;
  sortModel: GridSortModel;
  rowCount: number;
}

export const GroupsTable: FC<GroupsTableProps> = ({
  groups,
  onEdit,
  isLoading,
  page,
  size,
  sortModel,
  rowCount,
  onSizeChange,
  onPageChange,
  onSortModelChange,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showMessage } = useSnackbarMessage();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [deletingActive, setDeletingActive] = useState<boolean>(false);
  const [deleteGroupId, setDeleteGroupId] = useState<string>();

  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: page,
    pageSize: size,
  });

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPaginationModel(newModel);
    onPageChange(newModel.page);
    onSizeChange(newModel.pageSize);
  };

  const deleteGroup = useGroupsDeleteApi();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('entities.groupsPermissions.fields.name.label'),
      flex: 1,
      renderCell: (params) =>
        renderCellContent({
          entityType: 'groups',
          params,
          isLoading,
          columnField: 'name',
        }),
    },
    {
      field: 'organization',
      headerName: t('entities.groupsPermissions.fields.organization.label'),
      flex: 1,
      valueGetter: (params) => params.row.organization?.name || '',
      renderCell: (params) =>
        renderCellContent({
          entityType: 'groups',
          params,
          isLoading,
          columnField: 'organization',
        }),
    },
    {
      field: 'context-menu',
      headerName: '',
      width: 50,
      renderCell: (params) =>
        renderCellContent({
          entityType: 'groups',
          params,
          isLoading,
          columnField: 'context-menu',
          renderContent: () => {
            const handleEdit = () => navigate(`/groups/${params.row.id}/user`);

            return (
              <GroupsContextMenu
                groupId={params.row.id}
                groupName={params.row.name}
                hasEdit
                onClickDelete={() => {
                  setDeleteGroupId(params.row.id);
                  setConfirmDialogOpen(true);
                }}
                onClickEdit={handleEdit}
              />
            );
          },
        }),
      sortable: false,
      filterable: false,
    },
  ];

  const enableUserInteractionAfterDeletion = () => {
    setDeletingActive(false);
    setConfirmDialogOpen(false);
  };

  const handleConfirmedDelete = async (confirmed: boolean) => {
    if (confirmed && deleteGroupId) {
      setDeletingActive(true);
      try {
        await deleteGroup(deleteGroupId);
        showMessage({
          message: t('pages.groupsPermissions.overview.messages.successfullyDeleted'),
          type: 'success',
        });
        revalidateCacheForEntity('/groups');
      } catch (error) {
        console.error(error);
        showMessage({ message: (error as Error).toString(), type: 'error' });
      } finally {
        setDeleteGroupId(undefined);
        enableUserInteractionAfterDeletion();
      }
    } else {
      setConfirmDialogOpen(false);
    }
  };

  return (
    <>
      <Box width="100%">
        <DataGrid
          autoHeight
          columns={columns}
          data-testid="table-groups"
          disableColumnMenu
          filterMode="server"
          filterModel={filterModel}
          loading={isLoading}
          onFilterModelChange={setFilterModel}
          onPaginationModelChange={handlePaginationModelChange}
          onRowClick={(params) => onEdit(params.row.id)}
          onSortModelChange={onSortModelChange}
          paginationMode="server"
          paginationModel={paginationModel}
          rowCount={rowCount}
          rowSelection={false}
          rows={groups}
          sortModel={sortModel}
          sortingMode="server"
          sortingOrder={['asc', 'desc']}
        />
        <DeleteDialog
          isDeletingActive={deletingActive}
          message={t('pages.groupsPermissions.edit.dialog.content')}
          onClick={handleConfirmedDelete}
          open={confirmDialogOpen}
          title={t('pages.groupsPermissions.edit.dialog.title')}
        />
      </Box>
    </>
  );
};
