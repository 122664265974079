export const countryCodes = [
  { id: 'AF', name: 'AF - Afghanistan' },
  { id: 'AL', name: 'AL - Albania' },
  { id: 'DZ', name: 'DZ - Algeria' },
  { id: 'AS', name: 'AS - American Samoa' },
  { id: 'AD', name: 'AD - Andorra' },
  { id: 'AO', name: 'AO - Angola' },
  { id: 'AI', name: 'AI - Anguilla' },
  { id: 'AQ', name: 'AQ - Antarctica' },
  { id: 'AG', name: 'AG - Antigua and Barbuda' },
  { id: 'AR', name: 'AR - Argentina' },
  { id: 'AM', name: 'AM - Armenia' },
  { id: 'AW', name: 'AW - Aruba' },
  { id: 'AU', name: 'AU - Australia' },
  { id: 'AT', name: 'AT - Austria' },
  { id: 'AZ', name: 'AZ - Azerbaijan' },
  { id: 'BS', name: 'BS - Bahamas' },
  { id: 'BH', name: 'BH - Bahrain' },
  { id: 'BD', name: 'BD - Bangladesh' },
  { id: 'BB', name: 'BB - Barbados' },
  { id: 'BY', name: 'BY - Belarus' },
  { id: 'BE', name: 'BE - Belgium' },
  { id: 'BZ', name: 'BZ - Belize' },
  { id: 'BJ', name: 'BJ - Benin' },
  { id: 'BM', name: 'BM - Bermuda' },
  { id: 'BT', name: 'BT - Bhutan' },
  { id: 'BO', name: 'BO - Bolivia' },
  { id: 'BA', name: 'BA - Bosnia and Herzegovina' },
  { id: 'BW', name: 'BW - Botswana' },
  { id: 'BR', name: 'BR - Brazil' },
  { id: 'BN', name: 'BN - Brunei Darussalam' },
  { id: 'BG', name: 'BG - Bulgaria' },
  { id: 'BF', name: 'BF - Burkina Faso' },
  { id: 'BI', name: 'BI - Burundi' },
  { id: 'KH', name: 'KH - Cambodia' },
  { id: 'CM', name: 'CM - Cameroon' },
  { id: 'CA', name: 'CA - Canada' },
  { id: 'CV', name: 'CV - Cape Verde' },
  { id: 'KY', name: 'KY - Cayman Islands' },
  { id: 'CF', name: 'CF - Central African Republic' },
  { id: 'TD', name: 'TD - Chad' },
  { id: 'CL', name: 'CL - Chile' },
  { id: 'CN', name: 'CN - China' },
  { id: 'CO', name: 'CO - Colombia' },
  { id: 'KM', name: 'KM - Comoros' },
  { id: 'CG', name: 'CG - Congo' },
  { id: 'CD', name: 'CD - Congo, Democratic Republic of the' },
  { id: 'CR', name: 'CR - Costa Rica' },
  { id: 'CI', name: "CI - Côte d'Ivoire" },
  { id: 'HR', name: 'HR - Croatia' },
  { id: 'CU', name: 'CU - Cuba' },
  { id: 'CY', name: 'CY - Cyprus' },
  { id: 'CZ', name: 'CZ - Czech Republic' },
  { id: 'DK', name: 'DK - Denmark' },
  { id: 'DJ', name: 'DJ - Djibouti' },
  { id: 'DM', name: 'DM - Dominica' },
  { id: 'DO', name: 'DO - Dominican Republic' },
  { id: 'EC', name: 'EC - Ecuador' },
  { id: 'EG', name: 'EG - Egypt' },
  { id: 'SV', name: 'SV - El Salvador' },
  { id: 'GQ', name: 'GQ - Equatorial Guinea' },
  { id: 'ER', name: 'ER - Eritrea' },
  { id: 'EE', name: 'EE - Estonia' },
  { id: 'SZ', name: 'SZ - Eswatini' },
  { id: 'ET', name: 'ET - Ethiopia' },
  { id: 'FJ', name: 'FJ - Fiji' },
  { id: 'FI', name: 'FI - Finland' },
  { id: 'FR', name: 'FR - France' },
  { id: 'GA', name: 'GA - Gabon' },
  { id: 'GM', name: 'GM - Gambia' },
  { id: 'GE', name: 'GE - Georgia' },
  { id: 'DE', name: 'DE - Germany' },
  { id: 'GH', name: 'GH - Ghana' },
  { id: 'GR', name: 'GR - Greece' },
  { id: 'GD', name: 'GD - Grenada' },
  { id: 'GU', name: 'GU - Guam' },
  { id: 'GT', name: 'GT - Guatemala' },
  { id: 'GN', name: 'GN - Guinea' },
  { id: 'GW', name: 'GW - Guinea-Bissau' },
  { id: 'GY', name: 'GY - Guyana' },
  { id: 'HT', name: 'HT - Haiti' },
  { id: 'HN', name: 'HN - Honduras' },
  { id: 'HK', name: 'HK - Hong Kong' },
  { id: 'HU', name: 'HU - Hungary' },
  { id: 'IS', name: 'IS - Iceland' },
  { id: 'IN', name: 'IN - India' },
  { id: 'ID', name: 'ID - Indonesia' },
  { id: 'IR', name: 'IR - Iran' },
  { id: 'IQ', name: 'IQ - Iraq' },
  { id: 'IE', name: 'IE - Ireland' },
  { id: 'IL', name: 'IL - Israel' },
  { id: 'IT', name: 'IT - Italy' },
  { id: 'JM', name: 'JM - Jamaica' },
  { id: 'JP', name: 'JP - Japan' },
  { id: 'JO', name: 'JO - Jordan' },
  { id: 'KZ', name: 'KZ - Kazakhstan' },
  { id: 'KE', name: 'KE - Kenya' },
  { id: 'KI', name: 'KI - Kiribati' },
  { id: 'KP', name: 'KP - Korea (North)' },
  { id: 'KR', name: 'KR - Korea (South)' },
  { id: 'KW', name: 'KW - Kuwait' },
  { id: 'KG', name: 'KG - Kyrgyzstan' },
  { id: 'LA', name: 'LA - Laos' },
  { id: 'LV', name: 'LV - Latvia' },
  { id: 'LB', name: 'LB - Lebanon' },
  { id: 'LS', name: 'LS - Lesotho' },
  { id: 'LR', name: 'LR - Liberia' },
  { id: 'LY', name: 'LY - Libya' },
  { id: 'LI', name: 'LI - Liechtenstein' },
  { id: 'LT', name: 'LT - Lithuania' },
  { id: 'LU', name: 'LU - Luxembourg' },
  { id: 'MO', name: 'MO - Macau' },
  { id: 'MK', name: 'MK - Macedonia' },
  { id: 'MG', name: 'MG - Madagascar' },
  { id: 'MW', name: 'MW - Malawi' },
  { id: 'MY', name: 'MY - Malaysia' },
  { id: 'MV', name: 'MV - Maldives' },
  { id: 'ML', name: 'ML - Mali' },
  { id: 'MT', name: 'MT - Malta' },
  { id: 'MH', name: 'MH - Marshall Islands' },
  { id: 'MR', name: 'MR - Mauritania' },
  { id: 'MU', name: 'MU - Mauritius' },
  { id: 'MX', name: 'MX - Mexico' },
  { id: 'FM', name: 'FM - Micronesia' },
  { id: 'MD', name: 'MD - Moldova' },
  { id: 'MC', name: 'MC - Monaco' },
  { id: 'MN', name: 'MN - Mongolia' },
  { id: 'ME', name: 'ME - Montenegro' },
  { id: 'MA', name: 'MA - Morocco' },
  { id: 'MZ', name: 'MZ - Mozambique' },
  { id: 'MM', name: 'MM - Myanmar' },
  { id: 'NA', name: 'NA - Namibia' },
  { id: 'NR', name: 'NR - Nauru' },
  { id: 'NP', name: 'NP - Nepal' },
  { id: 'NL', name: 'NL - Netherlands' },
  { id: 'NZ', name: 'NZ - New Zealand' },
  { id: 'NI', name: 'NI - Nicaragua' },
  { id: 'NE', name: 'NE - Niger' },
  { id: 'NG', name: 'NG - Nigeria' },
  { id: 'NO', name: 'NO - Norway' },
  { id: 'OM', name: 'OM - Oman' },
  { id: 'PK', name: 'PK - Pakistan' },
  { id: 'PW', name: 'PW - Palau' },
  { id: 'PA', name: 'PA - Panama' },
  { id: 'PG', name: 'PG - Papua New Guinea' },
  { id: 'PY', name: 'PY - Paraguay' },
  { id: 'PE', name: 'PE - Peru' },
  { id: 'PH', name: 'PH - Philippines' },
  { id: 'PL', name: 'PL - Poland' },
  { id: 'PT', name: 'PT - Portugal' },
  { id: 'PR', name: 'PR - Puerto Rico' },
  { id: 'QA', name: 'QA - Qatar' },
  { id: 'RO', name: 'RO - Romania' },
  { id: 'RU', name: 'RU - Russia' },
  { id: 'RW', name: 'RW - Rwanda' },
  { id: 'KN', name: 'KN - Saint Kitts and Nevis' },
  { id: 'LC', name: 'LC - Saint Lucia' },
  { id: 'VC', name: 'VC - Saint Vincent and the Grenadines' },
  { id: 'WS', name: 'WS - Samoa' },
  { id: 'SM', name: 'SM - San Marino' },
  { id: 'ST', name: 'ST - Sao Tome and Principe' },
  { id: 'SA', name: 'SA - Saudi Arabia' },
  { id: 'SN', name: 'SN - Senegal' },
  { id: 'RS', name: 'RS - Serbia' },
  { id: 'SC', name: 'SC - Seychelles' },
  { id: 'SL', name: 'SL - Sierra Leone' },
  { id: 'SG', name: 'SG - Singapore' },
  { id: 'SK', name: 'SK - Slovakia' },
  { id: 'SI', name: 'SI - Slovenia' },
  { id: 'SB', name: 'SB - Solomon Islands' },
  { id: 'SO', name: 'SO - Somalia' },
  { id: 'ZA', name: 'ZA - South Africa' },
  { id: 'SS', name: 'SS - South Sudan' },
  { id: 'ES', name: 'ES - Spain' },
  { id: 'LK', name: 'LK - Sri Lanka' },
  { id: 'SD', name: 'SD - Sudan' },
  { id: 'SR', name: 'SR - Suriname' },
  { id: 'SE', name: 'SE - Sweden' },
  { id: 'CH', name: 'CH - Switzerland' },
  { id: 'SY', name: 'SY - Syria' },
  { id: 'TW', name: 'TW - Taiwan' },
  { id: 'TJ', name: 'TJ - Tajikistan' },
  { id: 'TZ', name: 'TZ - Tanzania' },
  { id: 'TH', name: 'TH - Thailand' },
  { id: 'TL', name: 'TL - Timor-Leste' },
  { id: 'TG', name: 'TG - Togo' },
  { id: 'TO', name: 'TO - Tonga' },
  { id: 'TT', name: 'TT - Trinidad and Tobago' },
  { id: 'TN', name: 'TN - Tunisia' },
  { id: 'TR', name: 'TR - Turkey' },
  { id: 'TM', name: 'TM - Turkmenistan' },
  { id: 'TV', name: 'TV - Tuvalu' },
  { id: 'UG', name: 'UG - Uganda' },
  { id: 'UA', name: 'UA - Ukraine' },
  { id: 'AE', name: 'AE - United Arab Emirates' },
  { id: 'GB', name: 'GB - United Kingdom' },
  { id: 'US', name: 'US - United States' },
  { id: 'UY', name: 'UY - Uruguay' },
  { id: 'UZ', name: 'UZ - Uzbekistan' },
  { id: 'VU', name: 'VU - Vanuatu' },
  { id: 'VE', name: 'VE - Venezuela' },
  { id: 'VN', name: 'VN - Vietnam' },
  { id: 'YE', name: 'YE - Yemen' },
  { id: 'ZM', name: 'ZM - Zambia' },
  { id: 'ZW', name: 'ZW - Zimbabwe' },
];
