import Box from '@mui/material/Box';
import type { FC } from 'react';
import type { Control, DeepRequired, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormInput, FormSectionHeader } from '@components/FormElements';
import type { User } from '@hooks/api/users';
import { useRenderConfig } from '@hooks/ui';

import { UserFormAdvancedSettings } from './UserFormAdvancedSettings';
import { UserLanguageSelect } from './UserLanguageSelect';

interface UserFormMasterDataProps {
  register: UseFormRegister<User>;
  formErrors: FieldErrorsImpl<DeepRequired<User>>;
  user?: User;
  disabled?: boolean;
  control: Control<User, object>;
  advancedSettingsProps?: {
    onChangeAdvancedSettingsEnabled: (isItemEnabled: boolean) => void;
    onChangePassword: (newPassword: string | undefined, copiedToClipboard: boolean) => void;
    onChangeSetOwnPassword: (isOwnPasswordSet: boolean) => void;
    password?: string;
    settings: {
      isItemEnabled: boolean;
      isOwnPasswordSet: boolean;
    };
  };
}

export const UserFormMasterData: FC<UserFormMasterDataProps> = ({
  control,
  formErrors,
  register,
  user,
  disabled = false,
  advancedSettingsProps,
}) => {
  const { t } = useTranslation();
  const { isItemVisible } = useRenderConfig();
  const { id } = useParams();

  const hasAdvancedSettings =
    isItemVisible('user.create.components.advancedSettings') && !!advancedSettingsProps;

  const isEdit = !!id;

  return (
    <Box marginBottom={hasAdvancedSettings ? 0 : 2}>
      <FormSectionHeader title={t('common.masterData')} />
      <Box display="flex" flexDirection="column">
        <FormInput
          defaultValue={user?.name}
          disabled={disabled}
          error={formErrors.name}
          formFieldName="name"
          isRequired
          label={t('entities.user.fields.name.label')}
          register={register}
        />

        <FormInput
          defaultValue={user?.email}
          disabled={isEdit}
          error={formErrors.email}
          formFieldName="email"
          isRequired
          label={t('entities.user.fields.email.label')}
          register={register}
        />

        <UserLanguageSelect
          control={control}
          controllerName={'userLanguage'}
          defaultValue={user?.userLanguage}
          error={formErrors?.userLanguage}
        />

        <FormInput
          defaultValue={user?.phoneNumber}
          disabled={disabled}
          formFieldName="phoneNumber"
          helperText={t('entities.user.fields.phoneNumber.placeholder')}
          label={t('entities.user.fields.phoneNumber.label')}
          register={register}
        />
        <FormInput
          defaultValue={user?.mobileNumber}
          disabled={disabled}
          formFieldName="mobileNumber"
          helperText={t('entities.user.fields.mobileNumber.placeholder')}
          label={t('entities.user.fields.mobileNumber.label')}
          register={register}
        />
        <FormInput
          defaultValue={user?.workPosition}
          disabled={disabled}
          formFieldName="workPosition"
          helperText={t('entities.user.fields.workPosition.placeholder')}
          label={t('entities.user.fields.workPosition.label')}
          register={register}
        />

        {hasAdvancedSettings && (
          <Box marginBottom={(theme) => theme.spacing(4)}>
            <UserFormAdvancedSettings
              formErrors={formErrors}
              onChangeAdvancedSettingsEnabled={
                advancedSettingsProps.onChangeAdvancedSettingsEnabled
              }
              onChangePassword={advancedSettingsProps.onChangePassword}
              onChangeSetOwnPassword={advancedSettingsProps.onChangeSetOwnPassword}
              password={advancedSettingsProps.password}
              register={register}
              settings={advancedSettingsProps.settings}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
