import { Stack, Typography } from '@mui/material';
import type { TFunction } from 'i18next';
import type { ReactNode } from 'react';

import { ERROR_CODES, type ErrorBackend, type ErrorLocalised } from './types';

// localise errors from backend by error code
export const getLocalisedErrorMessages = (
  t: TFunction,
  errorJSON: ErrorBackend[],
): ErrorLocalised[] =>
  errorJSON.map((error: ErrorBackend) => {
    if (ERROR_CODES.includes(error.code))
      return {
        message: `${t(
          `pages.projects.create.uploadApiExport.messages.validationFailed.${error.code}.message`,
        )} - ${t('pages.projects.create.uploadApiExport.messages.validationFailed.details', {
          row: error?.line,
        })}`,
        details: `${t('pages.projects.create.uploadApiExport.messages.validationFailed.details', {
          row: error?.line,
        })}: ${error.details}`,
      };

    return {
      message: `${t(
        `pages.projects.create.uploadApiExport.messages.validationFailed.unknownCode.message`,
      )} - ${t('pages.projects.create.uploadApiExport.messages.validationFailed.details', {
        row: error?.line,
      })}`,
      details: `${t('pages.projects.create.uploadApiExport.messages.validationFailed.details', {
        row: error?.line,
      })}: ${error.details}`,
    };
  });

// format localised error codes for rendering within <ProjectMappingErrorAlert ... />
export const getFormattedErrorMessages = (fileErrorsLocalised: ErrorLocalised[]) => {
  return fileErrorsLocalised.reduce(
    (cur: ReactNode, acc: ErrorLocalised) => (
      <Stack>
        <Typography component="span">{cur}</Typography>
        <Typography component="span">{acc.message}</Typography>
      </Stack>
    ),
    '',
  );
};

// consolidate all rows per error code into one row
export const getConsolidatedErrorMessages = (errors: ErrorBackend[]): ErrorBackend[] =>
  errors.reduce((acc: ErrorBackend[], current: ErrorBackend) => {
    const existing = acc.find((item) => item.code === current.code);

    if (existing) {
      existing.line = `${existing.line}, ${current.line}`;
    } else {
      const newItem: ErrorBackend = { ...current, line: current.line.toString() };

      acc.push(newItem);
    }

    return acc;
  }, []);

export const getFormattedErrorDetails = (fileErrorsLocalised: ErrorLocalised[]) => {
  return fileErrorsLocalised.reduce(
    (cur: ReactNode, acc: ErrorLocalised) => (
      <Stack>
        <Typography component="span">{cur}</Typography>
        <Typography component="span">{acc.details}</Typography>
      </Stack>
    ),
    '',
  );
};
