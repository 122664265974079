import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

import { useEnvironment } from '@hooks/useEnvironment';

import { FetchError } from './customErrors';

export interface PaginatedResponse<T> {
  content: T[];
  pageable: {
    pageNumber: number;
    pageSize: number;
    totalPages: number;
    totalElements: number;
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    offset: number;
    numberOfElements: number;
    first: boolean;
    last: boolean;
  };
}

export const useJsonFetch = <T>(
  withBearer = true,
): ((url: string, fetchInit: RequestInit, skipContentType?: boolean) => Promise<T>) => {
  const { getAccessTokenSilently } = useAuth0();
  const { serviceApiUrl } = useEnvironment();

  // trim serviceApiUrl to remove trailing slash
  const apiUrl = serviceApiUrl.replace(/\/$/, '');

  const handler = useCallback(
    async (url: string, fetchInit: RequestInit, skipContentType: boolean = false) => {
      // trim serviceEndpoint to remove leading slash
      const endpoint = url.replace(/^\//, '');

      let headers: RequestInit['headers'] = {
        ...fetchInit?.headers,
        Accept: 'application/json',
      };

      if (!skipContentType) {
        headers = {
          ...headers,
          'Content-Type': 'application/json',
        };
      }

      if (withBearer) {
        headers = {
          ...headers,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        };
      }

      const response = await fetch(`${apiUrl}/${endpoint}`, {
        ...fetchInit,
        headers,
      });

      // If the response is not ok, throw an error
      if (!response.ok) {
        const errorText = await response.text();

        throw new FetchError(errorText, response.status);
      }

      // If the response is no json, return the response as text
      if (response.headers.get('Content-Type') !== 'application/json') {
        return response.text();
      }

      // Happy path, return the data as JSON
      return response.json();
    },
    [apiUrl, getAccessTokenSilently, withBearer],
  );

  return handler;
};
