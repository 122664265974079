import {
  CircleInformationOutline,
  CopyOutline,
  EyeOutline,
  EyeSlashedOutline,
} from '@deepup/icons';
import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { customAlphabet } from 'nanoid';
import { useState, type MouseEvent } from 'react';
import type {
  DeepRequired,
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  UseFormRegister,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput } from '@components/FormElements';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

interface PasswordGeneratorProps<T extends FieldValues> {
  register: UseFormRegister<T>;
  onChange: (newPassword: string, copiedToClipboard: boolean) => void;
  password?: string;
  formFieldName: string;
  error?: Merge<FieldError, FieldErrorsImpl<DeepRequired<unknown>>> | undefined;
}

export const PasswordGenerator = <T extends FieldValues>({
  register,
  onChange,
  password,
  formFieldName,
  error,
}: PasswordGeneratorProps<T>) => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const containerWidth = isDesktop ? '50%' : '100%';

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleCopyToClipboard = () => {
    if (password) {
      navigator.clipboard.writeText(password);
      onChange(password, true);
      showMessage({
        message: t('pages.user.form.messages.successCopyToClipboard'),
        type: 'success',
      });
    }
  };

  const handleGeneratePassword = () => {
    const generatePassword = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz!@#$%^&*', 24);

    onChange(generatePassword(), false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Stack width={containerWidth}>
      <Box alignItems="center" display="flex" marginY={2}>
        <Box flexShrink={0}>
          <CircleInformationOutline height={16} width={16} />
        </Box>
        <Box mx={1} />
        <Typography variant="body2">{t('pages.user.form.info.customPassword')}</Typography>
      </Box>
      <Box display="flex" mb={2}>
        <Button color="primary" onClick={handleGeneratePassword} variant="contained">
          {t('pages.user.form.custom.generatePassword')}
        </Button>
      </Box>
      {password && (
        <Stack flexDirection="row" gap={2}>
          <Typography>{t('entities.user.fields.password.label')}:</Typography>
          <FormInput<T>
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ marginRight: 2 }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <EyeSlashedOutline height={24} width={24} />
                    ) : (
                      <EyeOutline height={24} width={24} />
                    )}
                  </IconButton>
                  <IconButton
                    aria-label="copy password to clipboard"
                    edge="end"
                    onClick={handleCopyToClipboard}
                  >
                    <CopyOutline height={24} width={24} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            disabled
            error={error}
            formFieldName={formFieldName}
            fullWidth
            register={register}
            sx={{
              '.MuiInputBase-root::before': {
                borderBottomWidth: 0,
              },
            }}
            type={showPassword ? 'text' : 'password'}
            variant="standard"
          />
        </Stack>
      )}
    </Stack>
  );
};
