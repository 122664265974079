import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const SingleSignOn = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">{t('pages.privacyPolicy.sections.singleSignOn.title')}</Typography>
      <Box my={1} />
      <Typography>
        <Trans i18nKey={'pages.privacyPolicy.sections.singleSignOn.content.prelude'}>
          Als &quot;Single-Sign-On&quot; oder &quot;Single-Sign-On-Anmeldung bzw.
          &quot;-Authentifizierung&quot; werden Verfahren bezeichnet, die es Nutzern erlauben, sich
          mit Hilfe eines Nutzerkontos bei einem Anbieter von Single-Sign-On-Verfahren (z.B. einem
          sozialen Netzwerk), auch bei unserem Onlineangebot, anzumelden. Voraussetzung der
          Single-Sign-On-Authentifizierung ist, dass die Nutzer bei dem jeweiligen
          Single-Sign-On-Anbieter registriert sind und die erforderlichen Zugangsdaten in dem dafür
          vorgesehenen Onlineformular eingeben, bzw. schon bei dem Single-Sign-On-Anbieter
          angemeldet sind und die Single-Sign-On-Anmeldung via Schaltfläche bestätigen. <br />
          Die Authentifizierung erfolgt direkt bei dem jeweiligen Single-Sign-On-Anbieter. Im Rahmen
          einer solchen Authentifizierung erhalten wir eine Nutzer-ID mit der Information, dass der
          Nutzer unter dieser Nutzer-ID beim jeweiligen Single-Sign-On-Anbieter eingeloggt ist und
          eine für uns für andere Zwecke nicht weiter nutzbare ID (sog &quot;User Handle&quot;). Ob
          uns zusätzliche Daten übermittelt werden, hängt allein von dem genutzten
          Single-Sign-On-Verfahren ab, von den gewählten Datenfreigaben im Rahmen der
          Authentifizierung und zudem davon, welche Daten Nutzer in den Privatsphäre- oder sonstigen
          Einstellungen des Nutzerkontos beim Single-Sign-On-Anbieter freigegeben haben. Es können
          je nach Single-Sign-On-Anbieter und der Wahl der Nutzer verschiedene Daten sein, in der
          Regel sind es die E-Mail-Adresse und der Benutzername. Das im Rahmen des
          Single-Sign-On-Verfahrens eingegebene Passwort bei dem Single-Sign-On-Anbieter ist für uns
          weder einsehbar, noch wird es von uns gespeichert. <br />
          Die Nutzer werden gebeten, zu beachten, dass deren bei uns gespeicherte Angaben
          automatisch mit ihrem Nutzerkonto beim Single-Sign-On-Anbieter abgeglichen werden können,
          dies jedoch nicht immer möglich ist oder tatsächlich erfolgt. Ändern sich z.B. die
          E-Mail-Adressen der Nutzer, müssen sie diese manuell in ihrem Nutzerkonto bei uns ändern.{' '}
          <br />
          Die Single-Sign-On-Anmeldung können wir, sofern mit den Nutzern vereinbart, im Rahmen der
          oder vor der Vertragserfüllung einsetzen, soweit die Nutzer darum gebeten wurden, im
          Rahmen einer Einwilligung verarbeiten und setzen sie ansonsten auf Grundlage der
          berechtigten Interessen unsererseits und der Interessen der Nutzer an einem effektiven und
          sicheren Anmeldesystem ein. <br />
          Sollten Nutzer sich einmal entscheiden, die Verknüpfung ihres Nutzerkontos beim
          Single-Sign-On-Anbieter nicht mehr für das Single-Sign-On-Verfahren nutzen zu wollen,
          müssen sie diese Verbindung innerhalb ihres Nutzerkontos beim Single-Sign-On-Anbieter
          aufheben. Möchten Nutzer deren Daten bei uns löschen, müssen sie ihre Registrierung bei
          uns kündigen.
        </Trans>
      </Typography>
      <ul>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.singleSignOn.content.list.general.processedTypes'
              }
            >
              <Typography component="span" fontWeight="bold">
                Verarbeitete Datenarten:
              </Typography>{' '}
              Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern);
              Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.singleSignOn.content.list.general.dataSubjects'
              }
            >
              <Typography component="span" fontWeight="bold">
                Betroffene Personen:
              </Typography>{' '}
              Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.singleSignOn.content.list.general.purposesOfProcessing'
              }
            >
              <Typography component="span" fontWeight="bold">
                Zwecke der Verarbeitung:
              </Typography>{' '}
              Erbringung vertraglicher Leistungen und Kundenservice; Sicherheitsmaßnahmen;
              Anmeldeverfahren.
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={'pages.privacyPolicy.sections.singleSignOn.content.list.general.legalBasis'}
            >
              <Typography component="span" fontWeight="bold">
                Rechtsgrundlagen:
              </Typography>{' '}
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </Trans>
          </Typography>
        </li>
      </ul>
      <Typography component="span" fontWeight="bold">
        {t('pages.privacyPolicy.common.furtherNotes')}
      </Typography>
      <ul>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.singleSignOn.content.list.further.authenticationService'
              }
            >
              <Typography component="span" fontWeight="bold">
                Authentifizierungsdienst:
              </Typography>{' '}
              Die Registrierung und Authentifizierung der Nutzer, inklusive Speicherung der
              Bestandsdaten und Passwörter erfolgt über einen Authentifizierungsdienstleister,
              welcher sich auf die Datenverarbeitung zu diesem Zweck spezialisiert hat. Die
              Datenverarbeitung des Dienstes erfolgt ausschließlich in der EU entsprechend den
              geltenden Richtlinien und wurde gewählt um die maximale Sicherheit der Nutzerdaten zu
              gewährleisten;{' '}
              <Typography component="span" fontWeight="bold">
                Rechtsgrundlagen:
              </Typography>{' '}
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);
            </Trans>
          </Typography>
        </li>
      </ul>
    </Box>
  );
};
