import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { type FC, useEffect } from 'react';
import { useForm, useFormState, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BottomFormNavigation } from '@components/BottomFormNavigation';
import { useOrganizationsApi } from '@hooks/api/organizations';
import type { User } from '@hooks/api/users';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

import { useFormUserState } from '../useFormUserState';

import { UserFormConnections, UserFormMasterData } from './UserFormSections';
import { internalUserFormSchema } from './schema';

interface UserFormProps {
  onSubmit: (data: FieldValues) => void;
}

// Container component for managing the whole form state.
export const DeepupUserCreateForm: FC<UserFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { showMessage } = useSnackbarMessage();
  const { data, error } = useOrganizationsApi({ isDeepUpOrg: true });
  const { user } = useFormUserState();

  const userFormSchemaWithTranslations = internalUserFormSchema({
    error: {
      name: t('entities.user.fields.name.error'),
      onlySpaces: t('entities.user.fields.name.errorOnlySpaces'),
      email: t('entities.user.fields.email.error'),
      invalidEmail: t('entities.user.fields.invalidEmail.error'),
      organization: t('entities.user.fields.organization.error'),
      userLanguage: t('entities.user.fields.userLanguage.error'),
    },
  });

  const { register, handleSubmit, control, setValue } = useForm<User>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(userFormSchemaWithTranslations),
  });
  const { errors: formErrors } = useFormState({ control });

  useEffect(() => {
    // in edit mode user email is not editable, therefore it is not part of the form and we need to set it manually so form can be submitted
    if (user?.email) setValue('email', user.email);
  }, [setValue, user?.email]);

  const handleSubmitTry = (data: FieldValues) => {
    onSubmit(data);
  };

  const handleClickCancel = () => {
    navigate('/users');
  };

  if (error) return <div>failed to load</div>;
  if (!data) return <div>loading...</div>;

  const formErrorHandler = (errors?: FieldValues) => {
    if (errors) showMessage({ message: t('common.missingData'), type: 'error' });
  };

  const { content: organizations } = data;

  return (
    <form onSubmit={handleSubmit(handleSubmitTry, formErrorHandler)}>
      <Box display="flex" flexDirection="column">
        <UserFormMasterData
          control={control}
          formErrors={formErrors}
          register={register}
          user={user}
        />
        {(!user || !!user.organizationId) && (
          <UserFormConnections
            control={control}
            disabled={!!user?.organizationId}
            formErrors={formErrors}
            hasOrgaAdminOption={false}
            organizations={organizations}
            register={register}
          />
        )}
        <Box my={4} />
        <BottomFormNavigation hasNext nextType="submit" onGoBack={handleClickCancel} />
      </Box>
    </form>
  );
};
