import useSWR from 'swr';

import { type PaginatedResponse, useJsonFetch } from '@hooks/api/useJsonFetch';

import type { Group } from './types';

interface UseGroupsApiOptions {
  isDeepUpOrg?: boolean;
}

export const useGroupsApi = (options?: UseGroupsApiOptions) => {
  let endpoint = `/groups?size=1000`;

  if (options?.isDeepUpOrg !== undefined) {
    endpoint += `&isDeepupOrg=${options.isDeepUpOrg}`;
  }

  const jsonFetch = useJsonFetch<PaginatedResponse<Group>>();
  const response = useSWR(endpoint, jsonFetch);

  return response;
};
