import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const LegalBasesProcessing = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">
        {t('pages.privacyPolicy.sections.legalBasesProcessing.title')}
      </Typography>
      <Box my={1} />
      <Typography>
        {t('pages.privacyPolicy.sections.legalBasesProcessing.content.prelude')}
      </Typography>
      <ul>
        <li>
          <Typography>
            <Trans
              i18nKey={'pages.privacyPolicy.sections.legalBasesProcessing.content.list.consent'}
            >
              <Typography component="span" fontWeight="bold">
                Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)
              </Typography>{' '}
              - Die betroffene Person hat ihre Einwilligung in die Verarbeitung der sie betreffenden
              personenbezogenen Daten für einen spezifischen Zweck oder mehrere bestimmte Zwecke
              gegeben.
            </Trans>
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={'pages.privacyPolicy.sections.legalBasesProcessing.content.list.performance'}
            >
              <Typography component="span" fontWeight="bold">
                Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO)
              </Typography>{' '}
              - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die
              betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich,
              die auf Anfrage der betroffenen Person erfolgen.
            </Trans>
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={'pages.privacyPolicy.sections.legalBasesProcessing.content.list.compliance'}
            >
              <Typography component="span" fontWeight="bold">
                Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO)
              </Typography>{' '}
              - Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der
              der Verantwortliche unterliegt.
            </Trans>
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={'pages.privacyPolicy.sections.legalBasesProcessing.content.list.legitimate'}
            >
              <Typography component="span" fontWeight="bold">
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
              </Typography>{' '}
              - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen
              oder eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und
              Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten
              erfordern, überwiegen.
            </Trans>
          </Typography>
        </li>
      </ul>
      <Typography>
        {t('pages.privacyPolicy.sections.legalBasesProcessing.content.epilogue')}
      </Typography>
    </Box>
  );
};
