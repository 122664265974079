import { ChevronsDown, ChevronsUp } from '@deepup/icons';
import { Switch } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { useState, type ChangeEvent } from 'react';
import type { DeepRequired, FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PasswordGenerator } from './PasswordGenerator';

interface UserFormAdvancedSettingsProps<T extends FieldValues> {
  password?: string;
  onChangeAdvancedSettingsEnabled: (isItemEnabled: boolean) => void;
  onChangeSetOwnPassword: (isSet: boolean) => void;
  onChangePassword: (newPassword: string | undefined, copiedToClipboard: boolean) => void;
  register: UseFormRegister<T>;
  formErrors: FieldErrorsImpl<DeepRequired<T>>;
  settings: {
    isItemEnabled: boolean;
    isOwnPasswordSet: boolean;
  };
}

export const UserFormAdvancedSettings = <T extends FieldValues>({
  register,
  onChangeAdvancedSettingsEnabled,
  onChangeSetOwnPassword,
  onChangePassword,
  password,
  formErrors,
  settings,
}: UserFormAdvancedSettingsProps<T>) => {
  const { t } = useTranslation();

  const [advancedSettings, setAdvancedSettings] = useState<{
    isOpen: boolean;
    setOwnPassword: boolean;
  }>({
    isOpen: false,
    setOwnPassword: false,
  });

  const handleToggleAdvancedSettings = () => {
    onChangePassword(undefined, false);
    onChangeAdvancedSettingsEnabled(!settings.isItemEnabled);
    setAdvancedSettings((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  const handleToggleSetOwnPassword = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeSetOwnPassword(event.target.checked);
  };

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        onClick={handleToggleAdvancedSettings}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Typography>{t('pages.user.form.header.advancedSettings')}</Typography>
        {advancedSettings.isOpen ? (
          <ChevronsUp height={24} width={24} />
        ) : (
          <ChevronsDown height={24} width={24} />
        )}
      </Box>
      {advancedSettings.isOpen && (
        <Box display="flex" flexDirection="column">
          <Box my={1}>
            <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Switch checked={settings.isOwnPasswordSet} onChange={handleToggleSetOwnPassword} />
              <Typography>{t('pages.user.form.custom.useOwnPassword')}</Typography>
            </FormControl>
          </Box>
        </Box>
      )}
      {settings.isItemEnabled && settings.isOwnPasswordSet && (
        <PasswordGenerator
          error={formErrors?.password}
          formFieldName="password"
          onChange={onChangePassword}
          password={password}
          register={register}
        />
      )}
    </>
  );
};
