import { useCallback } from 'react';

import { useJsonFetch } from '@hooks/api/useJsonFetch';
import type { User } from '@hooks/api/users';

export const useUserDeleteApi = () => {
  const jsonFetch = useJsonFetch<User>();

  const fetcher = useCallback(
    (id: string) =>
      jsonFetch(`/users/${id}?connection=auth0`, {
        method: 'DELETE',
      }),
    [jsonFetch],
  );

  return fetcher;
};
