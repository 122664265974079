import { Button, CircularProgress, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface DeleteDialogProps {
  title: string;
  message: string;
  open: boolean;
  onClick: (hasConfirmed: boolean) => void;
  isDeletingActive?: boolean;
}

export const DeleteDialog: FC<DeleteDialogProps> = ({
  open,
  onClick,
  title,
  message,
  isDeletingActive,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleCancel = () => {
    onClick(false);
  };

  const handleConfirm = () => {
    onClick(true);
  };

  const handleCancelCallback = () => {
    if (isDeletingActive) return;
    handleCancel();
  };

  return (
    <Dialog maxWidth="xs" onClose={handleCancelCallback} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions sx={{ padding: theme.spacing(3), gap: theme.spacing(2) }}>
        <Button
          color="secondary"
          data-testid="cancel-delete"
          disabled={isDeletingActive}
          onClick={handleCancelCallback}
          variant="text"
        >
          {t('common.cancel')}
        </Button>
        <Button
          color="error"
          data-testid="confirm-delete"
          disabled={isDeletingActive}
          onClick={handleConfirm}
          startIcon={
            isDeletingActive ? (
              <CircularProgress color="secondary" size={theme.spacing(2)} />
            ) : undefined
          }
        >
          {isDeletingActive ? t('common.deleting') : t('common.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
