import { useAuth0 } from '@auth0/auth0-react';

export const useDownloadFile = () => {
  const { getAccessTokenSilently } = useAuth0();

  const downloadFile = async (url: string) =>
    fetch(url, {
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    })
      .then((res) => {
        if (res.status != 200) {
          throw new Error('Bad server response');
        }

        return res.blob();
      })
      .then((data) => {
        const url = window.URL.createObjectURL(data);
        const anchor = document.createElement('a');

        anchor.href = url;
        anchor.download = 'qgis-config.json';
        anchor.click();

        window.URL.revokeObjectURL(url);

        return true;
      })
      .catch((err) => {
        console.error(err);

        return false;
      });

  return { downloadFile };
};
