import { useTheme } from '@mui/material/styles';
import {
  DataGrid,
  type GridFilterModel,
  type GridPaginationModel,
  type GridRowParams,
  type GridSortModel,
} from '@mui/x-data-grid';
import { useState, type FC, useCallback, useMemo } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DeleteDialog } from '@components/DeleteDialog';
import { useUserDeleteApi, type UserWithOrganization } from '@hooks/api/users';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';
import { useRenderConfig } from '@hooks/ui';
import { UserStatus } from '@hooks/usePlatformPermissions';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { getLocaleTextForDataGrid } from '@utils/getLocaleTextForDataGrid';
import { hasOpenOrgInvitations } from '@utils/user';

import { getUserTableColumns } from './UserManagementTableColumns';

interface UserManagementTableProps {
  users: UserWithOrganization[];
  isLoading: boolean;
  page: number;
  size: number;
  onPageChange: (page: number) => void;
  onSizeChange: (pageSize: number) => void;
  onSortModelChange: (model: GridSortModel) => void;
  sortModel: GridSortModel;
  rowCount: number;
  nameOrEmail: string;
  organization: string;
}

export const UserManagementTable: FC<UserManagementTableProps> = ({
  users,
  isLoading,
  page,
  size,
  sortModel,
  rowCount,
  onSizeChange,
  onPageChange,
  onSortModelChange,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { showMessage } = useSnackbarMessage();
  const deleteUser = useUserDeleteApi();
  const { isItemVisible } = useRenderConfig();
  const pageSizeOptions = [25, 50, 100];

  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [deleteUserId, setDeleteUserId] = useState<string | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [deletingActive, setDeletingActive] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: page,
    pageSize: size,
  });

  const handlePaginationModelChange = useCallback(
    (newModel: GridPaginationModel) => {
      setPaginationModel(newModel);
      onPageChange(newModel.page);
      onSizeChange(newModel.pageSize);
    },
    [onPageChange, onSizeChange],
  );

  const handleFilterModelChange = useCallback(setFilterModel, [setFilterModel]);

  const handleSortModelChange = useCallback(onSortModelChange, [onSortModelChange]);

  const handleRowClick = (params: GridRowParams<UserWithOrganization>) => {
    const userOfRow = users.find((user) => user.id === params.row.id);

    if (userOfRow) {
      navigate(`/users/${userOfRow.id}/edit`, {
        state: {
          user: userOfRow,
        },
      });
    } else {
      showMessage({ message: t('common.noItemFound'), type: 'error' });
    }
  };

  const enableUserInteractionAfterDeletion = () => {
    setDeletingActive(false);
    setConfirmDialogOpen(false);
  };

  const handleConfirmedDelete = async (confirmed: boolean) => {
    if (confirmed && deleteUserId) {
      try {
        setDeletingActive(true);
        const userName = users.find((user) => user.id === deleteUserId)?.name;

        await deleteUser(deleteUserId);
        setDeleteUserId(null);
        revalidateCacheForEntity(`/users`);
        revalidateCacheForEntity('/organizations');

        enableUserInteractionAfterDeletion();
        showMessage({
          message: t('pages.user.edit.messages.deleteSuccess', { userName }),
          type: 'success',
        });
      } catch (error) {
        console.error(error);
        showMessage({ message: (error as Error).toString(), type: 'error' });
        enableUserInteractionAfterDeletion();
      }
    } else {
      enableUserInteractionAfterDeletion();
    }
  };

  const columns = useMemo(
    () =>
      getUserTableColumns(
        t,
        isLoading,
        navigate,
        theme,
        isItemVisible,
        (user: UserWithOrganization) => {
          return hasOpenOrgInvitations(user.organizationRelations) ||
            user.status != UserStatus.verified
            ? { color: theme.palette.text.disabled }
            : { color: theme.palette.text.primary };
        },
        setDeleteUserId,
        setConfirmDialogOpen,
      ),
    [t, isLoading, navigate, theme, isItemVisible],
  );

  const rows = users.map((user) => ({ ...user }));

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        columns={columns}
        data-testid="table-user"
        disableColumnMenu
        filterMode="server"
        filterModel={filterModel}
        loading={isLoading}
        localeText={getLocaleTextForDataGrid(i18n.language)}
        onFilterModelChange={handleFilterModelChange}
        onPaginationModelChange={handlePaginationModelChange}
        onRowClick={handleRowClick}
        onSortModelChange={handleSortModelChange}
        pageSizeOptions={pageSizeOptions}
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={rowCount}
        rows={rows}
        sortModel={sortModel}
        sortingMode="server"
        sortingOrder={['asc', 'desc']}
      />
      <DeleteDialog
        isDeletingActive={deletingActive}
        message={t('pages.user.edit.components.dialog.content')}
        onClick={handleConfirmedDelete}
        open={confirmDialogOpen}
        title={t('pages.user.edit.components.dialog.title')}
      />
    </div>
  );
};

export default memo(UserManagementTable);
