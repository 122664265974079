import { MenuHamburger } from '@deepup/icons';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Drawer, IconButton, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useRenderConfig } from '@hooks/ui';

import { NavigationSidebarItem } from './NavigationSidebarItem';
import { getNavigationItems } from './config';

export const MobileNavigationSidebar = ({ isMobile }: { isMobile: boolean }) => {
  const { t } = useTranslation();
  const { isRouteEnabled } = useRenderConfig();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const navigationSidebarItems = getNavigationItems(t, pathname);

  return (
    <>
      <Drawer
        PaperProps={{
          sx: (theme) => ({
            marginTop: isMobile ? theme.spacing(7) : theme.spacing(10),
            width: open ? theme.spacing(32) : theme.spacing(7),
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }),
        }}
        open={open}
        variant="permanent"
      >
        {open ? (
          <Box display="flex" justifyContent="center" padding={2}>
            <Box alignItems="center" display="flex" gap={4}>
              <MenuHamburger height={theme.spacing(3)} width={theme.spacing(3)} />
              <Typography variant="h6">Menu</Typography>
            </Box>
            <IconButton onClick={() => setOpen(false)} sx={{ marginLeft: 'auto' }}>
              <KeyboardArrowLeft />
            </IconButton>
          </Box>
        ) : (
          <IconButton onClick={() => setOpen(true)}>
            <KeyboardArrowRight />
          </IconButton>
        )}
        <List>
          {navigationSidebarItems
            .filter(({ sidebarItem }) => isRouteEnabled(sidebarItem))
            .map(({ label, icon, to, disabled, selected, sidebarItem, iconSelected }) => (
              <NavigationSidebarItem
                data-testid={`sidebar-item-${sidebarItem}`}
                disabled={disabled}
                icon={icon}
                iconSelected={iconSelected}
                key={`${label}_${to}`}
                label={open ? label : ''}
                selected={selected}
                to={to}
              />
            ))}
        </List>
      </Drawer>
    </>
  );
};
