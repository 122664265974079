import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import type { FC } from 'react';
import { Controller, useFormState, type Control } from 'react-hook-form';
import { Trans } from 'react-i18next';

import type { UserRegisterFormProps } from './types';

interface RegisterFormConsentProps {
  control: Control<UserRegisterFormProps, object>;
}

export const RegisterFormConsent: FC<RegisterFormConsentProps> = ({ control }) => {
  const { errors: formErrors } = useFormState({ control });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <FormControl
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: isMobile ? '100%' : '65%',
        alignSelf: 'flex-end',
      }}
    >
      <FormLabel />
      <Box display="flex" flex="1 0 0" flexDirection="column" overflow="hidden">
        <Box alignItems="center" display="flex" marginLeft={isMobile ? 0 : 1}>
          <Controller
            control={control}
            defaultValue={false}
            name="consent"
            render={({ field: props }) => (
              <Checkbox
                {...props}
                checked={props.value}
                color={formErrors.consent ? 'error' : 'primary'}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            )}
          />
          <Typography>
            <Trans i18nKey="entities.user.fields.consent.label">
              Ich stimme den{' '}
              <Link href="/privacy-policy" target="_blank">
                Datenschutzbestimmungen
              </Link>{' '}
              zu
            </Trans>
          </Typography>
        </Box>
        <Box mx={2} my={1}>
          {formErrors.consent && (
            <Typography color="error">{formErrors.consent.message}</Typography>
          )}
        </Box>
      </Box>
    </FormControl>
  );
};
