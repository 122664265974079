import {
  ApiFilled,
  ApiOutline,
  BriefcaseFilled,
  BriefcaseOutline,
  FolderFilled,
  FolderOutline,
  PersonCrowdFilled,
  PersonCrowdOutline,
  PersonIdFilled,
  PersonIdOutline,
  ShieldPersonFilled,
  ShieldPersonOutline,
} from '@deepup/icons';
import type { TFunction } from 'i18next';

import type { RenderConfig } from '@hooks/ui/useRenderConfig/types';
import { isBasePathname } from '@utils/pathname';

import type { NavigationSidebarItemProps } from './NavigationSidebarItem';

export const getNavigationItems = (t: TFunction, pathname: string) => {
  const navigationSidebarItems: (NavigationSidebarItemProps & {
    sidebarItem: keyof RenderConfig['sidebar'];
  })[] = [
    {
      label: t('pages.organization.overview.title'),
      icon: BriefcaseOutline,
      iconSelected: BriefcaseFilled,
      to: '/organizations',
      sidebarItem: 'organizations',
      selected: isBasePathname(pathname, 'organizations'),
    },
    {
      label: t('pages.projects.overview.title'),
      icon: FolderOutline,
      iconSelected: FolderFilled,
      to: '/projects',
      sidebarItem: 'projects',
      selected: isBasePathname(pathname, 'projects'),
    },
    {
      label: t('pages.user.overview.title'),
      icon: PersonIdOutline,
      iconSelected: PersonIdFilled,
      to: '/users',
      sidebarItem: 'user',
      selected: isBasePathname(pathname, 'user'),
    },
    {
      label: t('pages.groupsPermissions.overview.title'),
      icon: ShieldPersonOutline,
      iconSelected: ShieldPersonFilled,
      to: '/groups',
      sidebarItem: 'groups',
      selected: isBasePathname(pathname, 'groups'),
    },
    {
      label: t('pages.groupsPermissions.deepup.title'),
      icon: PersonCrowdOutline,
      iconSelected: PersonCrowdFilled,
      to: '/deepup-groups',
      sidebarItem: 'deepupGroups',
      selected: isBasePathname(pathname, 'deepup-groups'),
    },
    {
      label: t('pages.apiAccesses.overview.title'),
      icon: ApiOutline,
      iconSelected: ApiFilled,
      to: '/api-accesses',
      sidebarItem: 'apiAccesses',
      selected: isBasePathname(pathname, 'api-accesses'),
    },
  ];

  return navigationSidebarItems;
};
