import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyTableInfo } from '@components/PageEntityTable/EmptyTableInfo';
import { type OrganizationWithProjectsAndUser } from '@hooks/api/organizations';
import { useOrganizationsPaginatedApi } from '@hooks/api/organizations/useOrganizationsPaginatedApi';
import { useOrganizationsUrlSync } from '@hooks/api/organizations/useOrganizationsUrlSync';
import { useProjectsPaginatedApi } from '@hooks/api/projects/useProjectsPaginatedApi';
import useDebounce from '@hooks/api/useDebounce';
import { useUsersPaginatedApi } from '@hooks/api/users/useUsersPaginatedApi';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { OverviewPageLayout } from '@pages/common';

import { OrganizationsTable } from './OrganizationsTable';

export const OrganizationsOverview: FC = () => {
  const { t } = useTranslation();
  const { isItemVisible } = useRenderConfig();

  const {
    page,
    size,
    sortModel,
    name,
    handlePageChange,
    handleSizeChange,
    handleSortModelChange,
    handleNameChange,
  } = useOrganizationsUrlSync();

  const debouncedName = useDebounce(name);

  const {
    data: organizationsData,
    error: organizationsDataError,
    isValidating: isValidatingOrganizations,
  } = useOrganizationsPaginatedApi({
    page,
    size,
    sortModel,
    name: debouncedName,
  });

  const {
    data: userData,
    error: userDataError,
    isValidating: isValidatingUsers,
  } = useUsersPaginatedApi({
    page,
    size,
  });

  const {
    data: projectsData,
    error: projectsDataError,
    isValidating: isValidatingProjects,
  } = useProjectsPaginatedApi({
    page,
    size,
  });

  const hasError = organizationsDataError || userDataError || projectsDataError;
  const isLoadingData =
    !organizationsData ||
    !userData ||
    !projectsData ||
    isValidatingOrganizations ||
    isValidatingUsers ||
    isValidatingProjects;

  const visibleOrganizations: OrganizationWithProjectsAndUser[] =
    organizationsData?.content.map((organization) => ({
      ...organization,
      user: userData?.content.filter((user) => user.organizationId === organization.id) || [],
      projects:
        projectsData?.content.filter((project) => project.organizationId === organization.id) || [],
    })) || [];

  if (hasError) return <div>failed to load</div>;

  const rowCount = organizationsData?.totalElements || 0;
  const hasCreateButton = isItemVisible('organizations.overview.components.btnCreate');
  const hasNoOrganizations = organizationsData?.content.length === 0;
  const isResultEmpty = (visibleOrganizations ?? []).length === 0;

  return (
    <OverviewPageLayout
      createRoute={hasCreateButton ? '/organizations/create' : ''}
      createTitle={t('pages.organization.overview.components.btnCreate.label')}
      title={t('pages.organization.overview.title')}
    >
      <Box display="flex" mb={4}>
        <Box flexBasis="50%">
          <TextField
            data-testid="search-field"
            fullWidth
            onChange={(event) => {
              const searchString = event.currentTarget.value;

              handleNameChange(searchString);
            }}
            placeholder={t('pages.organization.overview.components.filter.organization')}
            type="search"
            value={name}
          />
        </Box>
      </Box>
      {!isLoadingData && (isResultEmpty || hasNoOrganizations) ? (
        <EmptyTableInfo info={t('pages.organization.overview.components.table.emptyInfo')} />
      ) : (
        <OrganizationsTable
          isLoading={isLoadingData}
          name={debouncedName}
          onPageChange={handlePageChange}
          onSizeChange={handleSizeChange}
          onSortModelChange={handleSortModelChange}
          organizations={visibleOrganizations}
          page={page}
          rowCount={rowCount}
          size={size}
          sortModel={sortModel}
        />
      )}
    </OverviewPageLayout>
  );
};
