import { useCallback } from 'react';

import { useJsonFetch } from '@hooks/api/useJsonFetch';

export const useProjectApiExportDelete = () => {
  const jsonFetch = useJsonFetch();

  const fetcher = useCallback(
    (projectId: string) => {
      return jsonFetch(
        `/projects/${projectId}/project-export-mapping`,
        {
          method: 'DELETE',
        },
        true,
      );
    },
    [jsonFetch],
  );

  return fetcher;
};
