import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonDownloadCsvTemplateFile } from './ButtonDownloadCsvTemplateFile';
import { LatestUploadedFile } from './LatestUploadedFile';
import type { ProjectMappingApiExportProps } from './ProjectMappingApiExport';
import { type ProjectMappingError, ProjectMappingErrorAlert } from './ProjectMappingErrorAlert';
import { UploadDropZone } from './UploadDropZone';

type UploadScreenProps = {
  file?: File;
  error?: ProjectMappingError;
  onChange: (file?: File) => void;
  onError: (error: ProjectMappingError) => void;
  onDelete: () => void;
} & Pick<ProjectMappingApiExportProps, 'disabled'>;

export const UploadScreen = ({
  file,
  onChange,
  onDelete,
  error,
  onError,
  disabled,
}: UploadScreenProps) => {
  const { t } = useTranslation();

  const hasUploadedFile = !!file;

  return (
    <Stack gap={2} mb={4}>
      <Stack gap={2} mb={1} mt={1}>
        <Typography>{t('pages.projects.create.uploadApiExport.description')}</Typography>
        <ButtonDownloadCsvTemplateFile disabled={disabled} />
      </Stack>
      <ProjectMappingErrorAlert error={error} />
      {!hasUploadedFile ? (
        <UploadDropZone
          onChange={onChange}
          onDragUploadError={onError}
          onFileCountUploadError={onError}
        />
      ) : (
        <LatestUploadedFile
          file={file}
          onDelete={onDelete}
          title={t('pages.projects.create.uploadApiExport.lblSelectedFile')}
        />
      )}
    </Stack>
  );
};
