import { Autocomplete, TextField } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { ProjectGroupBackofficeRole } from '@hooks/api/projects/types';

interface BackofficeRoleSelectProps {
  selectedRole?: ProjectGroupBackofficeRole;
  onChange: (newValue: ProjectGroupBackofficeRole) => void;
}

export const BackofficeRoleSelect: FC<BackofficeRoleSelectProps> = ({ onChange, selectedRole }) => {
  const { t } = useTranslation();

  const selectedRoleName = selectedRole === 'EDITOR' ? t('common.editor') : t('common.admin');
  const defaultValue = selectedRole ? { id: selectedRole, name: selectedRoleName } : undefined;

  const options = [
    {
      id: 'EDITOR',
      name: t('common.editor'),
    },
    {
      id: 'DIRECT_ADMIN',
      name: t('common.admin'),
    },
  ];

  return (
    <Autocomplete
      defaultValue={defaultValue}
      getOptionLabel={(p) => p.name}
      onChange={(_, value) => {
        if (value?.id) {
          const transformedValue = value.id === '-1' ? null : value.id;

          onChange(transformedValue as ProjectGroupBackofficeRole);
        }
      }}
      options={options}
      placeholder={t('common.pleaseChoose')}
      renderInput={(params) => <TextField {...params} placeholder={t('common.pleaseChoose')} />}
    />
  );
};
