import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { type FC, useState, type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { FormSectionHeader } from '@components/FormElements';
import type { Project } from '@hooks/api/projects';

import { AllProjectsForApiAccessTable } from './AllProjectsForApiAccessTable';

export interface AllProjectsForApiAccessTableContainerProps {
  onAddProject: (updatedProjectList: Project[]) => void;
  projects: Project[];
  selectedProjects: Project[];
  isLoading: boolean;
}

export const AllProjectsForApiAccessTableContainer: FC<
  AllProjectsForApiAccessTableContainerProps
> = ({ onAddProject, selectedProjects, ...otherProps }) => {
  const { t } = useTranslation();

  const [searchName, setSearchName] = useState<string>('');

  const handleChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    const searchString = event.currentTarget.value;

    if (searchString.length > 1 || searchString.length === 0) {
      setSearchName(searchString);
    }
  };

  const handleAddProject = (projects: Project[]) => {
    onAddProject([...selectedProjects, ...projects]);
  };

  return (
    <>
      <FormSectionHeader
        id="all-projects-section"
        title={t('pages.apiAccesses.form.projects.sections.allProjects')}
      />
      <Box display="flex" mb={4}>
        <Box flexBasis="50%">
          <TextField
            fullWidth
            onChange={handleChangeSearchText}
            placeholder={t('pages.apiAccesses.form.projects.components.search')}
            type="search"
          />
        </Box>
      </Box>
      <AllProjectsForApiAccessTable
        onAddProject={handleAddProject}
        searchString={searchName}
        selectedProjects={selectedProjects}
        {...otherProps}
      />
    </>
  );
};
