import { ListItem, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import type { FC, JSX, SVGProps } from 'react';
import { Link } from 'react-router-dom';

export type NavigationSidebarItemProps = {
  label: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  iconSelected: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  to: string;
  disabled?: boolean;
  selected: boolean;
};

export const NavigationSidebarItem: FC<NavigationSidebarItemProps> = ({
  disabled,
  selected,
  label,
  to,
  icon,
  iconSelected,
  ...rest
}) => {
  const IconComponent = selected ? iconSelected : icon;
  const theme = useTheme();

  return (
    <ListItem disablePadding key={label} {...rest}>
      <Box color={theme.palette.text.primary} component={Link} to={disabled ? '#' : to}>
        <ListItemButton disabled={disabled} selected={selected}>
          <ListItemIcon>
            <IconComponent
              fill={selected ? 'currentColor' : theme.palette.grey[600]}
              height={theme.spacing(3)}
              width={theme.spacing(3)}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              fontWeight={
                selected ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular
              }
            >
              {label}
            </Typography>
          </ListItemText>
        </ListItemButton>
      </Box>
    </ListItem>
  );
};
