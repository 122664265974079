import {
  Autocomplete,
  FormControl,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { Controller, type Control, type DeepRequired, type FieldErrorsImpl } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormSectionHeader } from '@components/FormElements';
import type { Project } from '@hooks/api/projects';
import { useUsersApi } from '@hooks/api/users';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';

interface ProjectsFormCustomerContactPersonProps {
  formErrors: FieldErrorsImpl<DeepRequired<Project>>;
  project?: Project;
  control: Control<Partial<Project>>;
}

export const ProjectsFormCustomerContactPerson = ({
  project,
  formErrors,
  control,
}: ProjectsFormCustomerContactPersonProps) => {
  const { t } = useTranslation();
  const { isItemEnabled } = useRenderConfig();
  const theme = useTheme();
  const { data: usersPaginated } = useUsersApi();
  const users = usersPaginated?.content;

  const isFormEnabled = isItemEnabled('projects.edit.form.mode');

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const inputWidth = isDesktop ? '50%' : '100%';

  const usersWithConnectionUserId = users?.map((user) => ({
    ...user,
    connectionUserId: `${user.connection}|${user.id}`,
  }));

  const currentContactPerson = usersWithConnectionUserId?.find(
    (user) => user.connectionUserId === project?.customerContactPerson?.userId,
  );

  return (
    <Box mb={2}>
      <FormSectionHeader title={t('pages.projects.form.header.customerContactPerson')} />
      <Stack width={inputWidth}>
        <FormControl disabled={!isFormEnabled}>
          <Controller
            control={control}
            defaultValue={currentContactPerson?.connectionUserId}
            name={'customerContactPerson.userId'}
            render={({ field: { onChange } }) => (
              <Autocomplete
                defaultValue={currentContactPerson}
                disabled={!isFormEnabled}
                fullWidth
                getOptionLabel={(p) => p.name}
                key={currentContactPerson?.connectionUserId}
                onChange={(_, value) => {
                  onChange(value?.connectionUserId);
                }}
                options={usersWithConnectionUserId ?? []}
                renderInput={(params) => (
                  <TextField
                    label={t('entities.projects.fields.customerContactPerson.label')}
                    {...params}
                    error={!!formErrors.networkDesign?.state}
                    helperText={
                      <Box component="span">
                        <Typography component="span" variant="body2">
                          {formErrors.customerContactPerson?.message?.toString()}
                        </Typography>
                      </Box>
                    }
                    placeholder={t('common.pleaseChoose')}
                  />
                )}
              />
            )}
          />
        </FormControl>
      </Stack>
    </Box>
  );
};
