import { CircleInformationOutline, WarningFilled } from '@deepup/icons';
import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  useMediaQuery,
  useTheme,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import type { Path, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { DeleteDialog } from '@components/DeleteDialog';
import { useProjectApiExportDelete, useProjectApiExport, type Project } from '@hooks/api/projects';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

import type { ProjectsFormProps } from '../../ProjectsForm';

import { ButtonDownloadCsvTemplateFile } from './ButtonDownloadCsvTemplateFile';
import { ButtonOpenUploadDialog } from './ButtonOpenUploadDialog';
import { LatestUploadedData } from './LatestUploadedData';
import { LatestUploadedFile } from './LatestUploadedFile';
import { ProjectMappingUploadDialog } from './ProjectMappingUploadDialog';

export type ProjectMappingApiExportProps = {
  register: UseFormRegister<Partial<Project>>;
  project?: Project;
  disabled: boolean;
} & Pick<ProjectsFormProps, 'onChangeApiExportMappingFile'>;

export const ProjectMappingApiExport = ({
  register,
  project,
  onChangeApiExportMappingFile,
  disabled,
}: ProjectMappingApiExportProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const containerWidth = isDesktop ? '50%' : '100%';
  const iconSize = theme.spacing(3);
  const { id } = useParams();
  const { data: apiExportMapping } = useProjectApiExport(id);
  const deleteLatestUploadData = useProjectApiExportDelete();
  const { showMessage } = useSnackbarMessage();

  const [hasAssets, setHasAssets] = useState<boolean>(project?.apiExport ?? false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [showConfirmDeleteMappingFile, setShowConfirmDeleteMappingFile] = useState<boolean>(false);
  const [showConfirmDeleteLatestUploadData, setShowConfirmDeleteLatestUploadData] =
    useState<boolean>(false);

  useEffect(() => {
    return () => onChangeApiExportMappingFile?.(undefined);
  }, [onChangeApiExportMappingFile]);

  const handleCancelUploadDialog = () => {
    setShowUploadDialog(false);
  };

  const handleOpenUploadDialog = () => {
    setShowUploadDialog(true);
  };

  const handleSubmitUploadDialog = (uploadedFile: File) => {
    setFile(uploadedFile);
    setShowUploadDialog(false);
    onChangeApiExportMappingFile?.(uploadedFile);
  };

  const handleCancelDeleteDialog = () => {
    setShowConfirmDeleteMappingFile(false);
    setShowConfirmDeleteLatestUploadData(false);
  };

  const handleRemoveMappingFile = () => {
    setShowConfirmDeleteMappingFile(true);
  };

  const handleConfirmedRemoveMappingFile = () => {
    setFile(undefined);
    onChangeApiExportMappingFile?.(undefined);
    setShowConfirmDeleteMappingFile(false);
  };

  const handleRemoveLatestUpload = () => {
    setShowConfirmDeleteLatestUploadData(true);
  };

  const handleConfirmedRemoveLatestUpload = async () => {
    if (!id) return false;

    try {
      await deleteLatestUploadData(id);

      showMessage({
        message: t(
          'pages.projects.create.uploadApiExport.messages.successDeleteUploadedData.message',
        ),
        type: 'success',
      });

      mutate(`/projects/${id}/project-export-mapping`);
      setShowConfirmDeleteLatestUploadData(false);
    } catch (error) {
      showMessage({
        message: t(
          'pages.projects.create.uploadApiExport.messages.errorDeleteUploadedData.message',
        ),
        type: 'error',
      });
    }
  };

  const noUploadedDataGivenWhenEditing =
    hasAssets && apiExportMapping?.projectExportMapping.length === 0;
  const noFileUploadingWhenCreating = hasAssets && !apiExportMapping && !file;

  useEffect(() => {
    if (project) setHasAssets(project.apiExport);
  }, [project]);

  return (
    <Stack mb={2}>
      <Stack alignItems="center" direction="row">
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              {...register('apiExport' as Path<Project>)}
              checked={hasAssets}
              onChange={(_, checked) => setHasAssets(checked)}
            />
          }
          label={t('pages.projects.create.uploadApiExport.toggleDescription')}
        />
        <Tooltip arrow title={t('pages.projects.create.uploadApiExport.toggleDescriptionTooltip')}>
          <Box alignItems="center" display="flex" sx={{ cursor: 'pointer' }}>
            <CircleInformationOutline height={iconSize} width={iconSize} />
          </Box>
        </Tooltip>
      </Stack>
      {(noUploadedDataGivenWhenEditing || noFileUploadingWhenCreating) && (
        <Stack width={containerWidth}>
          <Alert
            icon={<WarningFilled height={24} width={24} />}
            severity="warning"
            variant="outlined"
          >
            <AlertTitle>
              {t('pages.projects.create.uploadApiExport.messages.warningNoDataUploaded.title')}
            </AlertTitle>
            {t('pages.projects.create.uploadApiExport.messages.warningNoDataUploaded.message')}
          </Alert>
        </Stack>
      )}

      {hasAssets && (
        <Stack gap={2} width={containerWidth}>
          <LatestUploadedData
            apiExportMapping={apiExportMapping}
            onDelete={handleRemoveLatestUpload}
          />
          <LatestUploadedFile
            file={file}
            onDelete={handleRemoveMappingFile}
            title={t('pages.projects.create.uploadApiExport.lblSelectedFile')}
          />

          <Stack direction="row" flexWrap="wrap" gap={2} mt={2}>
            <ButtonDownloadCsvTemplateFile disabled={disabled} />
            <ButtonOpenUploadDialog
              disabled={disabled}
              file={file}
              onClick={handleOpenUploadDialog}
            />
          </Stack>

          <ProjectMappingUploadDialog
            disabled={disabled}
            file={file}
            onCancel={handleCancelUploadDialog}
            onSubmit={handleSubmitUploadDialog}
            open={showUploadDialog}
          />
        </Stack>
      )}
      <DeleteDialog
        message={t('pages.projects.create.uploadApiExport.deleteDialog.deleteMappingFile.content')}
        onClick={(hasConfirmed) =>
          hasConfirmed ? handleConfirmedRemoveMappingFile() : handleCancelDeleteDialog()
        }
        open={showConfirmDeleteMappingFile}
        title={t('pages.projects.create.uploadApiExport.deleteDialog.deleteMappingFile.title')}
      />
      <DeleteDialog
        message={t('pages.projects.create.uploadApiExport.deleteDialog.deleteLatestUpload.content')}
        onClick={(hasConfirmed) =>
          hasConfirmed ? handleConfirmedRemoveLatestUpload() : handleCancelDeleteDialog()
        }
        open={showConfirmDeleteLatestUploadData}
        title={t('pages.projects.create.uploadApiExport.deleteDialog.deleteLatestUpload.title')}
      />
    </Stack>
  );
};
