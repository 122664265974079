import { ArrowBoxOut, DocumentArrowFilled, XOutline } from '@deepup/icons';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useEnvironment } from '@hooks/useEnvironment';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { useDownloadFile } from '@hooks/utils/useDownloadFile';

interface QgisDialogProps {
  open: boolean;
  onClose: () => void;
}

export const QgisDialog: FC<QgisDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { downloadFile } = useDownloadFile();
  const { showMessage } = useSnackbarMessage();
  const theme = useTheme();
  const { serviceApiUrl } = useEnvironment();

  const handleDownloadConfig = async () => {
    const fetchUrl = `${serviceApiUrl}/qgis-setup`;

    const success = await downloadFile(fetchUrl);

    if (!success) {
      showMessage({
        message: t('pages.apiAccesses.overview.components.qgisDialog.errorDownloading'),
        type: 'error',
      });

      return false;
    }

    showMessage({
      message: t('pages.apiAccesses.overview.components.qgisDialog.successDownloading'),
      type: 'success',
    });

    return true;
  };

  return (
    <Dialog
      PaperProps={{
        elevation: 0,
      }}
      onClose={onClose}
      open={open}
    >
      <DialogTitle>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Typography fontWeight="bold" sx={(theme) => ({ fontSize: theme.spacing(2.5) })}>
            {t('pages.apiAccesses.overview.components.qgisDialog.title')}
          </Typography>
          <Box onClick={onClose} sx={{ cursor: 'pointer' }}>
            <XOutline style={{ zoom: 1.5 }} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography>{t('pages.apiAccesses.overview.components.qgisDialog.body1')}</Typography>
        <br />
        <Typography>{t('pages.apiAccesses.overview.components.qgisDialog.body2')}</Typography>
      </DialogContent>
      <DialogActions
        sx={(theme) => ({ padding: theme.spacing(2.5), justifyContent: 'space-between' })}
      >
        <Button
          color="primary"
          data-testid="open-article"
          endIcon={<ArrowBoxOut fill={theme.palette.primary.main} />}
          onClick={() =>
            window.open(
              'https://deepup.freshdesk.com/en/support/solutions/articles/103000250589-use-api-with-wfs-in-qgis-with-oauth2-authentication',
            )
          }
          sx={(theme) => ({
            minWidth: theme.spacing(16),
          })}
          variant="outlined"
        >
          {t('pages.apiAccesses.overview.components.qgisDialog.btnTutorial')}
        </Button>
        <Button
          color="primary"
          data-testid="download-qgis-config"
          endIcon={<DocumentArrowFilled fill={theme.palette.primary.contrastText} />}
          onClick={handleDownloadConfig}
          sx={(theme) => ({
            minWidth: theme.spacing(16),
          })}
          variant="contained"
        >
          {t('pages.apiAccesses.overview.components.qgisDialog.btnDownloadConfig')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
