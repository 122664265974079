import type { FC } from 'react';

import type { ProjectGroupBackofficeRole, ProjectPermission } from '@hooks/api/projects';

import { ProjectsInGroupTable, type PermissionTypes } from './ProjectsInGroupTable';

export interface ProjectsInGroupTableContainerProps {
  entities: ProjectPermission[];
  onChangeProjectPermissions: (updatedProjectsInGroup: ProjectPermission[]) => void;
  selectedProjects: ProjectPermission[];
  isLoading: boolean;
  isDeepUpInternal: boolean;
}

export const ProjectsInGroupTableContainer: FC<ProjectsInGroupTableContainerProps> = ({
  onChangeProjectPermissions,
  entities,
  selectedProjects,
  isLoading,
  isDeepUpInternal,
}) => {
  const handleDeleteProject = (projectId: string) => {
    const allProjectsWithoutRequestedOne = selectedProjects.filter(
      (projectPermission) => projectPermission.projectId !== projectId,
    );

    onChangeProjectPermissions(allProjectsWithoutRequestedOne);
  };

  const handleDeleteAllProjects = () => {
    onChangeProjectPermissions([]);
  };

  const handleClickHeaderSelect = (columnKey: PermissionTypes[], isChecked: boolean) => {
    const updatedProjects = selectedProjects.map((projectPermission) => {
      const updatedPermission = {
        ...projectPermission,
      };

      columnKey.forEach((permission) => {
        const selectAllPermission = permission as
          | 'hasMonitoring'
          | 'hasDocumentation'
          | 'hasInvoiceTool';

        if (permission === 'hasMonitoring' && isChecked === false) {
          updatedPermission.hasDocumentation = false;
        }

        updatedPermission[selectAllPermission] = isChecked;
      });

      return updatedPermission;
    });

    onChangeProjectPermissions(updatedProjects);
  };

  const handleChangeProjectPermission = (
    entity: ProjectPermission,
    permission: PermissionTypes,
    newValue: boolean | ProjectGroupBackofficeRole,
  ) => {
    const updatedProjects = selectedProjects.map((projectPermission) => {
      if (projectPermission?.projectId === entity.projectId) {
        if (permission === 'hasMonitoring' && newValue === false) {
          return {
            ...projectPermission,
            hasDocumentation: false,
            [permission]: newValue,
          };
        }

        return {
          ...projectPermission,
          [permission]: newValue,
        };
      }

      return projectPermission;
    });

    onChangeProjectPermissions(updatedProjects);
  };

  return (
    <ProjectsInGroupTable
      entities={entities}
      isDeepUpInternal={isDeepUpInternal}
      isLoading={isLoading}
      onChangeProjectPermission={handleChangeProjectPermission}
      onClickHeaderSelectAll={handleClickHeaderSelect}
      onDeleteAllProjects={handleDeleteAllProjects}
      onDeleteProject={handleDeleteProject}
    />
  );
};
