import { useCallback } from 'react';
import type { FieldValues } from 'react-hook-form';

import { useJsonFetch } from '@hooks/api/useJsonFetch';
import { buildBody } from '@hooks/api/utils';

import type { Group } from './types';

export const useGroupsCreateApi = () => {
  const jsonFetch = useJsonFetch<Group>();

  const fetcher = useCallback(
    (newGroup: FieldValues) =>
      jsonFetch('/groups', {
        method: 'POST',

        body: buildBody(newGroup),
      }),
    [jsonFetch],
  );

  return fetcher;
};
