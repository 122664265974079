import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import type { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { PageHeader } from '@components/PageHeader';
import { WizardProgressBar } from '@components/WizardProgressBar';
import { DetailPageLayout } from '@pages/common';

import type { GroupsFormModeProps } from './types';
import { useFormGroupState } from './useFormGroupState';

export const GroupsFormEdit: FC<GroupsFormModeProps> = ({
  STEPS,
  MAX_STEPS,
  currentStep,
  handleCancel,
  handleGoBack,
  handleGoNext,
  handleNavigateByTab,
}) => {
  const { group } = useFormGroupState();

  return (
    <DetailPageLayout
      title={
        <Box alignItems="center" display="flex" flex={1} justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <PageHeader>{group?.name}</PageHeader>
          </Box>
          <Box flexBasis={(theme) => theme.spacing(32)}>
            <WizardProgressBar maxSteps={MAX_STEPS} step={currentStep + 1} />
          </Box>
        </Box>
      }
    >
      <Box>
        <Tabs aria-label="groups-create-tabs" onChange={() => ({})} value={currentStep}>
          {STEPS.map(({ path, label, icon }, index) => (
            <Tab
              aria-controls={`groups-create-tabpanel-${path}`}
              icon={icon}
              iconPosition="start"
              id={`groups-create-tab-${index}`}
              key={path}
              label={label}
              onClick={handleNavigateByTab(index)}
            />
          ))}
        </Tabs>
      </Box>
      <Box my={2} />
      <Outlet context={{ handleCancel, handleGoBack, handleGoNext }} />
    </DetailPageLayout>
  );
};
