import useSWR from 'swr';

import { useJsonFetch } from '@hooks/api/useJsonFetch';

import { type ProjectMappingApiExport } from './types';

export const useProjectApiExport = (projectId?: string) => {
  const jsonFetch = useJsonFetch<ProjectMappingApiExport>();

  return useSWR(projectId ? `/projects/${projectId}/project-export-mapping` : null, jsonFetch);
};
