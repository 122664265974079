import useSWR from 'swr';

import type { Organization } from '@hooks/api/organizations';
import { type PaginatedResponse, useJsonFetch } from '@hooks/api/useJsonFetch';

interface UseOrganizationsApiOptions {
  isDeepUpOrg?: boolean;
}

export const useOrganizationsApi = (options?: UseOrganizationsApiOptions) => {
  let endpoint = `/organizations?size=1000`;

  if (options?.isDeepUpOrg !== undefined) {
    endpoint += `&isDeepUpOrg=${options.isDeepUpOrg}`;
  }

  const jsonFetch = useJsonFetch<PaginatedResponse<Organization>>();
  const response = useSWR(endpoint, jsonFetch);

  return response;
};
