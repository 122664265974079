import type { ProjectNetworkDesign } from '@hooks/api/projects';

export const patchNetworkDesign = (networkDesign?: ProjectNetworkDesign) => {
  if (!networkDesign) {
    return undefined;
  }

  if (!networkDesign.format && !networkDesign.state) {
    return undefined;
  }

  return {
    state: networkDesign.state,
    format: networkDesign.format,
  } satisfies ProjectNetworkDesign;
};
