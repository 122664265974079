import { ShieldPersonOutline, PersonIdOutline, DocumentTextOutline } from '@deepup/icons';
import { type ReactNode, type FC, useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import type { Group } from '@hooks/api/groups';
import { useOrganizationsApi } from '@hooks/api/organizations';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';

import { StepPath, type GroupsFormModeProps, type NavigationOptions } from './types';
import { useFormGroupState } from './useFormGroupState';

interface GroupsFormProps {
  isDeepUpInternal?: boolean;
  children: (options: GroupsFormModeProps) => ReactNode;
}

export const GroupsForm: FC<GroupsFormProps> = ({ isDeepUpInternal = false, children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { group } = useFormGroupState();
  const organizations = useOrganizationsApi();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const STEPS = useMemo(
    () => [
      {
        path: StepPath.overview,
        label: t('common.overview'),
        icon: <DocumentTextOutline height={24} width={24} />,
      },
      {
        path: StepPath.users,
        label: t('pages.user.overview.title'),
        icon: <PersonIdOutline height={24} width={24} />,
      },
      {
        path: StepPath.projects,
        label: t('common.permissions'),
        icon: <ShieldPersonOutline height={24} width={24} />,
      },
    ],
    [t],
  );

  const MAX_STEPS = STEPS.length;

  const handleRequestNavigate = useCallback(
    ({ state, mode }: NavigationOptions) => {
      const nextStep = mode === 'next' ? currentStep + 1 : currentStep - 1;
      const isInRange = mode === 'next' ? nextStep < MAX_STEPS : nextStep >= 0;

      if (isInRange) {
        const nextPath = STEPS[nextStep].path;
        const nextRoute = `/${isDeepUpInternal ? 'deepup-groups' : 'groups'}/${
          id ?? 'create'
        }/${nextPath}`;

        setCurrentStep(nextStep);
        navigate(nextRoute, {
          state: { ...state },
        });
      } else {
        revalidateCacheForEntity('/groups');
        navigate(`/${isDeepUpInternal ? 'deepup-groups' : 'groups'}`);
      }
    },
    [currentStep, MAX_STEPS, STEPS, isDeepUpInternal, id, navigate],
  );

  const handleNavigateByTab = useCallback(
    (index: number) => () => {
      setCurrentStep(index);
      navigate(`${STEPS[index].path}`, { state: { group } });
    },
    [STEPS, group, navigate],
  );

  const handleGoBack = () => {
    handleRequestNavigate({ state: { group }, mode: 'prev' });
  };

  const handleGoNext = (response?: Group) => {
    const latestStateOfGroup = response ?? group;

    handleRequestNavigate({
      state: { group: latestStateOfGroup },
      mode: 'next',
    });
  };

  const handleCancel = () => {
    navigate(`/${isDeepUpInternal ? 'deepup-groups' : 'groups'}`);
  };

  useEffect(() => {
    const initialCalledPath = pathname.split('/').slice(-1)[0];

    const stepIndex = STEPS.findIndex((step) => step.path === initialCalledPath);

    if (stepIndex !== -1) {
      setCurrentStep(stepIndex);
    } else {
      handleNavigateByTab(0)();
    }
  }, [STEPS, handleNavigateByTab, pathname]);

  useEffect(() => {
    const organizationsArray = organizations.data?.content;
    const groupOrganizationId = group?.organizationId;

    if (!organizationsArray || !groupOrganizationId || !id) return;

    const organizationForGroup = organizationsArray?.find((org) => org.id === groupOrganizationId);

    const isLocationInternalGroup = location.pathname.includes('deepup-groups');

    if (organizationForGroup?.isDeepUpOrg && !isLocationInternalGroup) {
      navigate(`/deepup-groups/${id}/user`);

      return;
    }

    if (!organizationForGroup?.isDeepUpOrg && isLocationInternalGroup) {
      navigate(`/groups/${id}/user`);

      return;
    }
  }, [group, organizations, id, location.pathname, navigate]);

  return (
    <>
      {children({
        STEPS,
        MAX_STEPS,
        currentStep,
        handleNavigateByTab,
        handleCancel,
        handleGoBack,
        handleGoNext,
      })}
    </>
  );
};
