import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const Monitoring = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">{t('pages.privacyPolicy.sections.monitoring.title')}</Typography>
      <Box my={1} />
      <Typography>
        <Trans i18nKey={'pages.privacyPolicy.sections.monitoring.content.prelude'}>
          Die Webanalyse (auch als &quot;Reichweitenmessung&quot; bezeichnet) dient der Auswertung
          der Besucherströme unseres Onlineangebotes und kann Verhalten, Interessen oder
          demographische Informationen zu den Besuchern, wie z.B. das Alter oder das Geschlecht, als
          pseudonyme Werte umfassen. Mit Hilfe der Reichweitenanalyse können wir z.B. erkennen, zu
          welcher Zeit unser Onlineangebot oder dessen Funktionen oder Inhalte am häufigsten genutzt
          werden oder zur Wiederverwendung einladen. Ebenso können wir nachvollziehen, welche
          Bereiche der Optimierung bedürfen. <br />
          Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B. unterschiedliche
          Versionen unseres Onlineangebotes oder seiner Bestandteile zu testen und optimieren.{' '}
          <br />
          Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken Profile, d.h. zu einem
          Nutzungsvorgang zusammengefasste Daten angelegt und Informationen in einem Browser, bzw.
          in einem Endgerät gespeichert und aus diesem ausgelesen werden. Zu den erhobenen Angaben
          gehören insbesondere besuchte Webseiten und dort genutzte Elemente sowie technische
          Angaben, wie der verwendete Browser, das verwendete Computersystem sowie Angaben zu
          Nutzungszeiten. Sofern Nutzer in die Erhebung ihrer Standortdaten uns gegenüber oder
          gegenüber den Anbietern der von uns eingesetzten Dienste einverstanden erklärt haben,
          können auch Standortdaten verarbeitet werden. Es werden ebenfalls die IP-Adressen der
          Nutzer gespeichert. Jedoch nutzen wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung
          durch Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die im Rahmen von
          Webanalyse, A/B-Testings und Optimierung keine Klardaten der Nutzer (wie z.B.
          E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir als auch die
          Anbieter der eingesetzten Software kennen nicht die tatsächliche Identität der Nutzer,
          sondern nur den für Zwecke der jeweiligen Verfahren in deren Profilen gespeicherten
          Angaben.
        </Trans>
      </Typography>
      <ul>
        <li>
          <Typography>
            <Trans i18nKey={'pages.privacyPolicy.sections.monitoring.content.list.processedTypes'}>
              <Typography component="span" fontWeight="bold">
                Verarbeitete Datenarten:
              </Typography>{' '}
              Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans i18nKey={'pages.privacyPolicy.sections.monitoring.content.list.dataSubjects'}>
              <Typography component="span" fontWeight="bold">
                Betroffene Personen:
              </Typography>{' '}
              Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={'pages.privacyPolicy.sections.monitoring.content.list.purposesOfProcessing'}
            >
              <Typography component="span" fontWeight="bold">
                Zwecke der Verarbeitung:
              </Typography>{' '}
              Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher);
              Profile mit nutzerbezogenen Informationen (Erstellen von Nutzerprofilen); Tracking
              (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies); Bereitstellung
              unseres Onlineangebotes und Nutzerfreundlichkeit.
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={'pages.privacyPolicy.sections.monitoring.content.list.securityMeasures'}
            >
              <Typography component="span" fontWeight="bold">
                Sicherheitsmaßnahmen:
              </Typography>{' '}
              IP-Masking (Pseudonymisierung der IP-Adresse).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans i18nKey={'pages.privacyPolicy.sections.monitoring.content.list.legalBasis'}>
              <Typography component="span" fontWeight="bold">
                Rechtsgrundlagen:
              </Typography>{' '}
              Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
            </Trans>
          </Typography>
        </li>
      </ul>
    </Box>
  );
};
