import { CircleInformationOutline, EyeOutline, EyeSlashedOutline } from '@deepup/icons';
import { useDeepUpTheme } from '@deepup/mui-theme-deepup';
import { Button, FormControl, InputAdornment, InputLabel, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { RegisterFormConsent } from './RegisterFormConsent';
import type { UserRegisterFormResponsive } from './UserRegisterForm';

export const UserRegisterFormMobile: FC<UserRegisterFormResponsive> = ({
  register,
  control,
  formErrors,
  showPassword,
  onClickShowPassword,
  onMouseDownPassword,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const name = searchParams.get('name');
  const { theme } = useDeepUpTheme();

  return (
    <Box display="flex" flexDirection="column" paddingX={2} paddingY={7}>
      <Box alignItems="center" display="flex" height={(theme) => theme.spacing(12)}>
        <Typography
          fontWeight="bold"
          sx={(theme) => ({ fontSize: theme.spacing(2.5), lineHeight: theme.spacing(4) })}
        >
          {t('pages.register.create.title')}:
        </Typography>
      </Box>
      <form onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column">
          <TextField
            defaultValue={name}
            error={!!formErrors?.name?.message}
            helperText={<Typography>{formErrors?.name?.message}</Typography>}
            label={t('entities.user.fields.name.label')}
            placeholder={t('entities.user.fields.name.label')}
            required
            {...register('name')}
          />
          <Box my={2} />
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password" required>
              {t('entities.user.fields.password.label')}
            </InputLabel>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={onClickShowPassword}
                      onMouseDown={onMouseDownPassword}
                    >
                      {showPassword ? (
                        <EyeSlashedOutline height={24} width={24} />
                      ) : (
                        <EyeOutline height={24} width={24} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="outlined-adornment-password"
              label={t('entities.user.fields.password.label')}
              placeholder={t('entities.user.fields.password.placeholder')}
              type={showPassword ? 'text' : 'password'}
              {...register('password')}
              error={false}
              helperText={<Typography>{formErrors?.password?.message}</Typography>}
            />
          </FormControl>
          <Box my={1} />
          <Box display="flex">
            <CircleInformationOutline fill={theme.palette.text.disabled} height={24} width={24} />
            <Box mx={1} />
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.disabled,
              })}
              variant="body1"
            >
              <Trans i18nKey={'pages.register.create.components.info.passwordStrength'}>
                Mind. 10 Zeichen, 1 Großbuchstabe, <br /> 1 Sonderzeichen (!@#$%^&*=-_)
              </Trans>
            </Typography>
          </Box>
          <Box my={2} />
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password-repeat" required>
              {t('pages.register.create.components.fields.confirmPassword.label')}
            </InputLabel>
            <TextField
              id="outlined-adornment-password-repeat"
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={onClickShowPassword}
                      onMouseDown={onMouseDownPassword}
                    >
                      {showPassword ? (
                        <EyeSlashedOutline height={24} width={24} />
                      ) : (
                        <EyeOutline height={24} width={24} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label={t('pages.register.create.components.fields.confirmPassword.label')}
              placeholder={t('pages.register.create.components.fields.confirmPassword.placeholder')}
              type={showPassword ? 'text' : 'password'}
              {...register('confirmPassword')}
              error={!!formErrors?.confirmPassword?.message}
              helperText={<Typography>{formErrors?.confirmPassword?.message}</Typography>}
            />
          </FormControl>
          <Box my={2} />
          <RegisterFormConsent control={control} />
          <Box mx={1} />
          <Box marginTop={4} />
          <Button type="submit" variant="contained">
            {t('common.register')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
