import { useCallback } from 'react';

import type { Group } from '@hooks/api/groups/types';
import { useJsonFetch } from '@hooks/api/useJsonFetch';
import { buildBody } from '@hooks/api/utils';

export const useGroupsUpdateApi = () => {
  const jsonFetch = useJsonFetch<Group>();

  const fetcher = useCallback(
    ({ id, name, version }: Group) =>
      jsonFetch(`/groups/${id}`, {
        method: 'PATCH',

        body: buildBody({ name, version }),
      }),
    [jsonFetch],
  );

  return fetcher;
};
