import {
  DataGrid,
  type GridColDef,
  type GridPaginationModel,
  type GridRowParams,
  type GridSortModel,
} from '@mui/x-data-grid';
import { useCallback } from 'react';

import type { UserOfOrganization } from '../types';

interface OrganizationUserTableProps {
  isLoading: boolean;
  users: UserOfOrganization[];
  columns: GridColDef[];
  onRowClick: (user: UserOfOrganization) => () => void;
  onPaginationModelChange: (newModel: GridPaginationModel) => void;
  onSortModelChange: (model: GridSortModel) => void;
  sortModel: GridSortModel;
  totalRows: number;
}

export const OrganizationUserTable: React.FC<OrganizationUserTableProps> = ({
  users,
  columns,
  isLoading,
  onRowClick,
  onPaginationModelChange,
  onSortModelChange,
  sortModel,
  totalRows,
}) => {
  const handleOnRowClick = useCallback(
    ({ row: user }: GridRowParams<UserOfOrganization>) => {
      onRowClick(user);
    },
    [onRowClick],
  );

  return (
    <DataGrid<UserOfOrganization>
      columns={columns}
      disableColumnMenu
      initialState={{
        sorting: {
          sortModel,
        },
      }}
      loading={isLoading}
      onPaginationModelChange={onPaginationModelChange}
      onRowClick={handleOnRowClick}
      onSortModelChange={onSortModelChange}
      paginationMode="server"
      rowCount={totalRows}
      rows={users}
      sortingMode="server"
      sortingOrder={['asc', 'desc']}
    />
  );
};
