import { useCallback, useMemo } from 'react';

import { PlatformUserType, usePlatformPermissions } from '@hooks/usePlatformPermissions';

import { renderConfig } from './renderConfig';
import type { RecursivePartial, RenderConfig } from './types';

export const useRenderConfig = () => {
  const {
    permissions: { platformUserType },
  } = usePlatformPermissions();

  const userTypeRenderConfig = useMemo(() => renderConfig[platformUserType], [platformUserType]);

  const isRouteEnabled = useCallback(
    (route: keyof RenderConfig['sidebar']) => {
      const sidebarKey = route as keyof RecursivePartial<RenderConfig['sidebar']>;

      return (
        userTypeRenderConfig?.sidebar?.[sidebarKey] === undefined ||
        userTypeRenderConfig?.sidebar?.[sidebarKey] === true
      );
    },
    [userTypeRenderConfig],
  );

  // TODO: The response comes (at least locally on my machine) significantly later after the application
  // has rendered and the initial redirect on `/` was done.
  const getDefaultRouteForUserType = useCallback(
    () => (platformUserType === PlatformUserType.platformAdmin ? 'organizations' : 'projects'),
    [platformUserType],
  );

  const getValue = useCallback(
    (key: string) => {
      const nestedKeyAsArray = key.split('.');
      let nestedKeyValue = userTypeRenderConfig;

      if (nestedKeyAsArray.length > 0)
        while (nestedKeyAsArray.length > 0) {
          const currentKey = nestedKeyAsArray.shift() as keyof RecursivePartial<RenderConfig>;

          nestedKeyValue = nestedKeyValue?.[currentKey] as RecursivePartial<RenderConfig>;
        }

      return nestedKeyValue;
    },
    [userTypeRenderConfig],
  );

  const isItemVisible = useCallback(
    (key: string): boolean => getValue(key) !== ('hidden' as RecursivePartial<RenderConfig>),
    [getValue],
  );

  const isItemEnabled = useCallback(
    (key: string): boolean => getValue(key) !== ('disabled' as RecursivePartial<RenderConfig>),
    [getValue],
  );

  return {
    isRouteEnabled,
    getDefaultRouteForUserType,
    isItemVisible,
    isItemEnabled,
  };
};
