import type { GridSortModel } from '@mui/x-data-grid';

import { useApiWithOptions } from '../useApiWithOptions';
import { convertSortModelToString } from '../utils/convertSortModelToString';

import type { Project } from './types';

interface ProjectQueryOptions {
  page: number;
  size: number;
  sortModel?: GridSortModel;
  name?: string;
  organizationId?: string;
  networkProviderId?: string;
  generalContractorId?: string;
  customerManager?: string;
  customerContactPerson?: string;
  contactPerson?: string;
  serviceProviderId?: string;
  networkDesignState?: string;
  networkDesignFormat?: string;
  activityState?: string;
  pipeDirection?: string;
}

const constructQueryStringForProject = ({
  page,
  size,
  sortModel,
  name,
  organizationId,
  networkProviderId,
  generalContractorId,
  customerManager,
  customerContactPerson,
  contactPerson,
  serviceProviderId,
  networkDesignState,
  networkDesignFormat,
  activityState,
  pipeDirection,
}: ProjectQueryOptions): string => {
  const sortParam = convertSortModelToString(sortModel);
  const queryParams = {
    page: page.toString(),
    size: size.toString(),
    ...(name && { name }),
    ...(networkProviderId && { networkProviderId }),
    ...(generalContractorId && { generalContractorId }),
    ...(sortParam && { sort: sortParam }),
    ...(organizationId && { organizationId }),
    ...(customerManager && { customerManager }),
    ...(customerContactPerson && { customerContactPerson }),
    ...(contactPerson && { contactPerson }),
    ...(serviceProviderId && { serviceProviderId }),
    ...(networkDesignState && { networkDesign_state: networkDesignState }),
    ...(networkDesignFormat && { networkDesign_format: networkDesignFormat }),
    ...(activityState && { activityState }),
    ...(pipeDirection !== undefined && pipeDirection !== '' && { pipeDirection }),
  };

  const searchParams = new URLSearchParams(queryParams);

  return `?${searchParams.toString()}`;
};

export function useProjectsPaginatedApi(queryOptions: ProjectQueryOptions) {
  return useApiWithOptions<Project>({
    context: 'projects',
    queryString: constructQueryStringForProject(queryOptions),
  });
}
