import { object, string } from 'yup';

import { defaultSelectItem } from '@pages/common/constants';

export const schemaCreateStepOverview = (error: {
  name: string;
  organization: string;
  onlySpaces: string;
}) =>
  object({
    name: string().required(error.name).matches(/\S/, error.onlySpaces),
    organizationId: string()
      .notOneOf([defaultSelectItem.id], error.organization)
      .required(error.organization),
  });
