import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { type BaseSyntheticEvent, type FC, useState, useEffect, type MouseEvent } from 'react';
import {
  type Control,
  type DeepRequired,
  type FieldErrorsImpl,
  useForm,
  type UseFormRegister,
  useFormState,
  type FieldValues,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useUserRegisterApi } from '@hooks/api/users';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

import { externalUserRegistrationSchema } from '../schema';

import { UserRegisterFormDesktop } from './UserRegisterFormDesktop';
import { UserRegisterFormMobile } from './UserRegisterFormMobile';
import type { UserRegisterFormProps } from './types';

export interface UserRegisterFormResponsive {
  onSubmit: (event?: BaseSyntheticEvent<object> | undefined) => Promise<void>;
  formErrors: FieldErrorsImpl<DeepRequired<UserRegisterFormProps>>;
  onClickShowPassword: () => void;
  onMouseDownPassword: (event: MouseEvent<HTMLButtonElement>) => void;
  showPassword: boolean;
  register: UseFormRegister<UserRegisterFormProps>;
  control: Control<UserRegisterFormProps, object>;
}

export const UserRegisterForm: FC = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.only('xs'));

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();

  const { showMessage } = useSnackbarMessage();

  const token = searchParams.get('token'),
    email = searchParams.get('email');

  const registerUser = useUserRegisterApi();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const externalUserRegistrationSchemaWithTranslations = externalUserRegistrationSchema({
    error: {
      name: t('entities.user.fields.name.error'),
      email: t('entities.user.fields.email.error'),
      password: {
        required: t('entities.user.fields.password.error'),
        confirm: t('entities.user.fields.password.errorConfirm'),
        strength: t('entities.user.fields.password.errorStrength'),
      },
      consent: t('entities.user.fields.consent.error'),
    },
  });
  const { control, register, handleSubmit, setValue, setError } = useForm({
    resolver: yupResolver(externalUserRegistrationSchemaWithTranslations),
  });
  const { errors: formErrors } = useFormState({ control });

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleOnSubmit = async (data: FieldValues) => {
    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', {
        type: 'required',
        message: t('entities.user.fields.password.errorConfirm'),
      });

      return;
    }

    try {
      const response = await registerUser({
        email: data.email,
        name: data.name,
        password: data.password,
        token,
      });

      showMessage({ message: t('common.successfullyRegistered'), type: 'success' });
      navigate('/users/register/success', { state: response });
    } catch (error) {
      console.warn('Error:', error);
      showMessage({ message: `${error}`, type: 'error' });
    }
  };

  useEffect(() => {
    if (email) setValue('email', email);
  }, [email, setValue]);

  return isMobileScreen ? (
    <UserRegisterFormMobile
      control={control}
      formErrors={formErrors}
      onClickShowPassword={handleClickShowPassword}
      onMouseDownPassword={handleMouseDownPassword}
      onSubmit={handleSubmit(handleOnSubmit)}
      register={register}
      showPassword={showPassword}
    />
  ) : (
    <UserRegisterFormDesktop
      control={control}
      formErrors={formErrors}
      onClickShowPassword={handleClickShowPassword}
      onMouseDownPassword={handleMouseDownPassword}
      onSubmit={handleSubmit(handleOnSubmit)}
      register={register}
      showPassword={showPassword}
    />
  );
};
