import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { PageHeader } from '@components/PageHeader';
import { WizardProgressBar } from '@components/WizardProgressBar';
import { DetailPageLayout } from '@pages/common';

import type { ApiAccessesFormModeProps } from './types';

export const ApiAccessesFormCreate: FC<ApiAccessesFormModeProps> = ({
  STEPS,
  MAX_STEPS,
  currentStep,
  handleNavigateByTab,
  handleCancel,
  handleGoBack,
  handleGoNext,
}) => {
  const { t } = useTranslation();

  return (
    <DetailPageLayout
      title={
        <Box alignItems="center" display="flex" flex={1} justifyContent="space-between">
          <PageHeader>{t('pages.apiAccesses.create.title')}</PageHeader>
          <Box flexBasis={(theme) => theme.spacing(32)}>
            <WizardProgressBar maxSteps={MAX_STEPS} step={currentStep + 1} />
          </Box>
        </Box>
      }
    >
      <Box>
        <Tabs aria-label="api-accesses-create-tabs" onChange={() => ({})} value={currentStep}>
          {STEPS.map(({ path, label, icon }, index) => (
            <Tab
              aria-controls={`api-accesses-create-tabpanel-${path}`}
              disabled={index !== currentStep}
              icon={icon}
              iconPosition="start"
              id={`api-accesses-create-tab-${index}`}
              key={path}
              label={label}
              onClick={handleNavigateByTab(index)}
            />
          ))}
        </Tabs>
      </Box>
      <Box my={2} />
      <Outlet context={{ handleCancel, handleGoBack, handleGoNext }} />
    </DetailPageLayout>
  );
};
