import { useCallback } from 'react';

import { useJsonFetch } from '@hooks/api/useJsonFetch';

export const useProjectApiExportUpload = () => {
  const jsonFetch = useJsonFetch();

  const fetcher = useCallback(
    (file: File, projectId: string) => {
      const formData = new FormData();

      formData.append('file', file);

      return jsonFetch(
        `/projects/${projectId}/project-export-mapping`,
        {
          method: 'POST',
          body: formData,
        },
        true,
      );
    },
    [jsonFetch],
  );

  return fetcher;
};
