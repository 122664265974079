import { CircleQuestionmarkOutline, Hourglass } from '@deepup/icons';
import { useDeepUpTheme } from '@deepup/mui-theme-deepup';
import { Box, Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import {
  type GridColDef,
  type GridPaginationModel,
  type GridRenderCellParams,
  type GridSortModel,
  type GridValidRowModel,
} from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { renderCellContent } from '@components/TableCell/TableCell';
import { UserContextMenu } from '@components/UserContextMenu';
import { useUserUpdateApi, useUsersPaginatedApi } from '@hooks/api/users';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';
import { useIsLoggedInUser } from '@hooks/useIsLoggedInUser';
import { PlatformUserType, usePlatformPermissions } from '@hooks/usePlatformPermissions';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { OrgaAdminInfo } from '@pages/common';
import { hasOpenOrgInvitations } from '@utils/user';

import type { UserOfOrganization } from '../types';
import { useFormOrganizationState } from '../useFormOrganizationState';

import { OrganizationUserTable } from './OrganizationUserTable';

interface OrganizationUserTableContainerProps {
  onRequestDelete: (user: UserOfOrganization) => () => void;
}

export const OrganizationUserTableContainer: React.FC<OrganizationUserTableContainerProps> = ({
  onRequestDelete,
}) => {
  const navigate = useNavigate();
  const { theme } = useDeepUpTheme();
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();
  const { id: editingOrgaId } = useParams();
  const { isPlatformAdmin, isAdminOfOrg } = usePlatformPermissions();
  const { isLoggedInUser } = useIsLoggedInUser();
  const isAdminOfEditingOrg = isAdminOfOrg(editingOrgaId);

  const { organization } = useFormOrganizationState();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'name', sort: 'asc' }]);

  const {
    data: userData,
    error: userError,
    isLoading,
    isValidating,
  } = useUsersPaginatedApi({
    page,
    size,
    sortModel,
    organizationId: editingOrgaId,
  });

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPage(newModel.page);
    setSize(newModel.pageSize);
  };

  const handleOnRowClick = useCallback(
    (user: UserOfOrganization) => () => {
      navigate(`/users/${user.id}/edit`, { state: { user } });
    },
    [navigate],
  );

  const updateUser = useUserUpdateApi();

  const togglePlatformUserType = useCallback(
    (user: UserOfOrganization) =>
      async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        const { checked: hasSelectedOrgaAdmin } = event.target as HTMLInputElement;

        try {
          const response = await updateUser({
            ...user,
            platformUserType: hasSelectedOrgaAdmin
              ? PlatformUserType.organizationAdmin
              : PlatformUserType.organizationMember,
          });

          showMessage({
            message: t('pages.user.edit.messages.updateSuccess', { userName: response.name }),
            type: 'success',
          });

          revalidateCacheForEntity('/users');
        } catch (error) {
          showMessage({ message: (error as Error).toString(), type: 'error' });
        }

        return false;
      },
    [showMessage, t, updateUser],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        flex: 1,
        headerName: t('entities.organization.fields.name.label'),
        field: 'name',
        renderCell: (params: GridRenderCellParams<GridValidRowModel, UserOfOrganization>) => {
          const { row: user } = params;

          if (user?.organizationRelations && hasOpenOrgInvitations(user?.organizationRelations)) {
            return (
              <Box alignItems="center" display="flex">
                <Tooltip arrow title={t('common.waitingForRegistration')}>
                  <div>
                    <Hourglass fill={theme.palette.text.disabled} height={24} width={24} />
                  </div>
                </Tooltip>{' '}
                <Typography
                  sx={(theme) => ({
                    color: hasOpenOrgInvitations(user.organizationRelations)
                      ? theme.palette.text.disabled
                      : theme.palette.text.primary,
                  })}
                >
                  {user?.name}
                </Typography>
              </Box>
            );
          }

          return renderCellContent({
            entityType: 'users-of-organization',
            params,
            isLoading,
            columnField: 'name',
          });
        },
      },
      {
        flex: 1,
        headerName: t('entities.user.fields.email.label'),
        field: 'email',
        renderCell: (params) =>
          renderCellContent({
            entityType: 'users-of-organization',
            params,
            isLoading,
            columnField: 'email',
          }),
      },
      {
        flex: 1,
        headerName: t('entities.user.fields.workPosition.label'),
        field: 'workPosition',
        renderCell: (params) =>
          renderCellContent({
            entityType: 'users-of-organization',
            params,
            isLoading,
            columnField: 'workPosition',
          }),
      },
      {
        flex: 1,
        field: 'isAdmin',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => (
          <Stack alignItems="center" flexDirection="row" gap={1} justifyContent="center">
            {t('entities.user.fields.isOrganizationAdmin.label')}
            <Tooltip arrow title={<OrgaAdminInfo />}>
              <Stack alignItems="center">
                <CircleQuestionmarkOutline height={24} width={24} />
              </Stack>
            </Tooltip>
          </Stack>
        ),
        renderCell: ({ row: user }: GridRenderCellParams<UserOfOrganization>) => {
          const isOrgaAdmin = user?.platformUserType === PlatformUserType.organizationAdmin;
          const canEditAsOrgAdmin = isAdminOfEditingOrg && !isLoggedInUser(user);
          const canEditAdminOption = isPlatformAdmin || canEditAsOrgAdmin;

          const isExternalUser = user?.platformUserType === PlatformUserType.external;

          return (
            <Typography
              sx={({ palette }) => ({
                color:
                  user && hasOpenOrgInvitations(user.organizationRelations)
                    ? palette.text.disabled
                    : palette.text.primary,
              })}
            >
              <Checkbox
                defaultChecked={isOrgaAdmin}
                disabled={!canEditAdminOption || isExternalUser}
                onClick={togglePlatformUserType(user)}
              />
            </Typography>
          );
        },
        sortable: false,
      },
      {
        field: 'context-menu',
        align: 'right',
        headerName: '',
        renderCell: ({ row: user }: GridRenderCellParams<UserOfOrganization>) => {
          return (
            <Typography
              component="div"
              sx={({ palette }) => ({
                color:
                  user && hasOpenOrgInvitations(user.organizationRelations)
                    ? palette.text.disabled
                    : palette.text.primary,
              })}
            >
              <UserContextMenu
                hasEdit
                hasResendInvitation={hasOpenOrgInvitations(user.organizationRelations)}
                onClickDelete={onRequestDelete(user)}
                onClickEdit={handleOnRowClick(user)}
                user={user}
              />
            </Typography>
          );
        },
        sortable: false,
      },
    ],
    [
      handleOnRowClick,
      isAdminOfEditingOrg,
      isLoading,
      isLoggedInUser,
      isPlatformAdmin,
      onRequestDelete,
      t,
      theme.palette.text.disabled,
      togglePlatformUserType,
    ],
  );

  if (userError) return <Box>Error loading data</Box>;

  return (
    <OrganizationUserTable
      columns={columns}
      isLoading={isLoading || isValidating}
      onPaginationModelChange={handlePaginationModelChange}
      onRowClick={handleOnRowClick}
      onSortModelChange={setSortModel}
      sortModel={sortModel}
      totalRows={organization.usersCount}
      users={userData ? userData.content : []}
    />
  );
};
