import type { FieldValues } from 'react-hook-form';

export const trimFields = (fields: FieldValues): FieldValues => {
  if (Array.isArray(fields)) {
    return fields.map((field) => trimFields(field));
  }

  const newFields: FieldValues = { ...fields };

  Object.entries(newFields).forEach(([key, value]) => {
    if (typeof value === 'string') {
      newFields[key] = value.trim().replace(/\s+/g, ' ');
    }
    if (typeof value === 'object' && value !== null) {
      newFields[key] = trimFields(value);
    }
  });

  return newFields;
};
