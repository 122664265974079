import { Box, TextField } from '@mui/material';
import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EmptyTableInfo } from '@components/PageEntityTable/EmptyTableInfo';
import { useApiAccessesPaginatedApi } from '@hooks/api/apiAccesses/useApiAccessesPaginatedApi';
import { useApiAccessesUrlSync } from '@hooks/api/apiAccesses/useApiAccessesUrlSync';
import useDebounce from '@hooks/api/useDebounce';
import { OverviewPageLayout } from '@pages/common';

import { ApiAccessesOverviewTable } from './ApiAccessesOverviewTable';
import { QgisDialog } from './QgisDialog';

export const ApiAccessesOverview: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    page,
    size,
    sortModel,
    name,
    handlePageChange,
    handleSizeChange,
    handleSortModelChange,
    handleNameChange,
  } = useApiAccessesUrlSync();

  const debouncedName = useDebounce(name);
  const [isQgisDialogOpen, setIsQgisDialogOpen] = useState<boolean>(false);

  const {
    data: apiAccessesData,
    isValidating: isValidatingApiAccesses,
    error: apiAccessesDataError,
  } = useApiAccessesPaginatedApi({
    page,
    size,
    sortModel,
    name: debouncedName,
  });

  const handleClickEdit = (id: string) => {
    navigate(`/api-accesses/${id}`);
  };

  const toggleDialog = (isOpen: boolean) => () => {
    setIsQgisDialogOpen(isOpen);
  };

  const hasError = !!apiAccessesDataError;
  const rowCount = apiAccessesData?.totalElements || 0;
  const isLoading = isValidatingApiAccesses;

  return (
    <>
      <OverviewPageLayout
        createRoute="/api-accesses/create"
        createTitle={t('pages.apiAccesses.overview.components.btnCreate')}
        secondaryAction={{
          label: t('pages.apiAccesses.overview.components.secondaryAction'),
          onClick: toggleDialog(true),
        }}
        title={t('pages.apiAccesses.overview.title')}
      >
        <>
          <Box display="flex" mb={4}>
            <Box flexBasis="50%">
              <TextField
                fullWidth
                onChange={(event) => {
                  const searchString = event.currentTarget.value;

                  handleNameChange(searchString);
                }}
                placeholder={t('pages.apiAccesses.overview.components.filter.apiAccess')}
                type="search"
                value={name}
              />
            </Box>
          </Box>
          {hasError ? (
            <Box my={2}>{t('common.failedToLoad')}</Box>
          ) : !isLoading && rowCount === 0 ? (
            <EmptyTableInfo info={t('pages.apiAccesses.overview.components.table.emptyInfo')} />
          ) : (
            <ApiAccessesOverviewTable
              entities={apiAccessesData?.content ?? []}
              isLoading={isLoading}
              name={debouncedName}
              onEdit={handleClickEdit}
              onPageChange={handlePageChange}
              onSizeChange={handleSizeChange}
              onSortModelChange={handleSortModelChange}
              page={page}
              rowCount={rowCount}
              size={size}
              sortModel={sortModel}
            />
          )}
        </>

        <QgisDialog onClose={toggleDialog(false)} open={isQgisDialogOpen} />
      </OverviewPageLayout>
    </>
  );
};
