import useSWR from 'swr';

import { type PaginatedResponse, useJsonFetch } from '@hooks/api/useJsonFetch';
import type { User } from '@hooks/api/users';

export const useUsersApi = () => {
  const jsonFetch = useJsonFetch<PaginatedResponse<User>>();
  const response = useSWR('/users?size=2000', jsonFetch);

  return response;
};
