import { Autocomplete, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { type FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyTableInfo } from '@components/PageEntityTable/EmptyTableInfo';
import { useOrganizationsApiTotalCount } from '@hooks/api/organizations/useOrganizationsApiTotalCount';
import { useOrganizationsPaginatedApi } from '@hooks/api/organizations/useOrganizationsPaginatedApi';
import useDebounce from '@hooks/api/useDebounce';
import { useUsersPaginatedApi } from '@hooks/api/users/useUsersPaginatedApi';
import { useUsersUrlSync } from '@hooks/api/users/useUsersUrlSync';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { OverviewPageLayout } from '@pages/common';

import { UserManagementTable } from './UserManagementTable';

export const UserManagementOverview: FC = () => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();

  const { isItemVisible } = useRenderConfig();

  const {
    page,
    size,
    sortModel,
    nameOrEmail,
    organizationId,
    handlePageChange,
    handleSizeChange,
    handleSortModelChange,
    handleNameOrEmailChange,
    handleOrganizationChange,
  } = useUsersUrlSync();

  const debouncedNameOrEmail = useDebounce(nameOrEmail);
  const totalOrganizationCount = useOrganizationsApiTotalCount() || 0;

  const {
    data: organizationsData,
    error: organizationsError,
    isValidating: isValidatingOrganizations,
  } = useOrganizationsPaginatedApi({
    page,
    size: totalOrganizationCount,
  });

  const {
    data: usersData,
    error: usersError,
    isValidating: isValidatingUsers,
  } = useUsersPaginatedApi({
    page,
    size,
    sortModel,
    nameOrEmail: debouncedNameOrEmail,
    organizationId,
  });

  const usersWithOrganizations = useMemo(() => {
    return (
      usersData?.content.map((user) => {
        const organization = organizationsData?.content.find(
          (org) => org.id === user.organizationId,
        );

        return { ...user, organizationName: organization?.name };
      }) || []
    );
  }, [usersData, organizationsData]);

  useEffect(() => {
    if (organizationsError) {
      console.error('Failed to fetch organizations:', organizationsError);
      showMessage({ message: t('common.noItemFound'), type: 'error' });
    }
  }, [t, showMessage, organizationsError]);

  useEffect(() => {
    if (usersError) {
      console.error('Failed to fetch users:', usersError);
      showMessage({ message: t('common.noItemFound'), type: 'error' });
    }
  }, [t, showMessage, usersError]);

  const rowCount = usersData?.totalElements;
  const isTableEmpty = rowCount === 0;

  const hasError = usersError || organizationsError;
  const isLoadingData =
    !usersData || !organizationsData || isValidatingOrganizations || isValidatingUsers;

  const hasCreateButton = isItemVisible('user.overview.components.btnCreate');
  const hasNoUsers = usersData?.content.length === 0;

  if (hasError) return <div>failed to load</div>;

  return (
    <OverviewPageLayout
      createRoute={hasCreateButton ? '/users/create' : ''}
      createTitle={t('pages.user.overview.components.btnCreate')}
      title={t('pages.user.overview.title')}
    >
      <Box display="flex" gap={2} mb={4}>
        <Box flexBasis="50%">
          <TextField
            data-testid="search-field"
            fullWidth
            onChange={(event) => {
              const searchString = event.currentTarget.value;

              handleNameOrEmailChange(searchString);
            }}
            placeholder={t('pages.user.overview.components.filter.name')}
            type="search"
            value={nameOrEmail}
          />
        </Box>
        <Box flexBasis="25%">
          <Autocomplete
            getOptionLabel={(p) => p.name}
            id="user-table-filter-by-organization"
            onChange={(_, value): void => {
              handleOrganizationChange(String(value?.id ?? ''));
            }}
            options={organizationsData?.content ?? []}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('pages.user.overview.components.filter.organization')}
              />
            )}
            value={organizationsData?.content.find((org) => org.id === organizationId) || null}
          />
        </Box>
      </Box>
      {!isLoadingData && (isTableEmpty || hasNoUsers) ? (
        <EmptyTableInfo info={t('pages.user.overview.components.table.emptyInfo')} />
      ) : (
        <UserManagementTable
          isLoading={isLoadingData}
          nameOrEmail={debouncedNameOrEmail}
          onPageChange={handlePageChange}
          onSizeChange={handleSizeChange}
          onSortModelChange={handleSortModelChange}
          organization={organizationId}
          page={page}
          rowCount={rowCount || 0}
          size={size}
          sortModel={sortModel}
          users={usersWithOrganizations}
        />
      )}
    </OverviewPageLayout>
  );
};
