import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const SecurityPrecautions = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">
        {t('pages.privacyPolicy.sections.securityPrecautions.title')}
      </Typography>
      <Box my={1} />
      <Typography>
        <Trans i18nKey={'pages.privacyPolicy.sections.securityPrecautions.content'}>
          Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der
          Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke
          der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des
          Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische
          und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu
          gewährleisten.
          <br />
          Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und
          Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den
          Daten als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung
          der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die
          eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die
          Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz
          personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software
          sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und
          durch datenschutzfreundliche Voreinstellungen.
          <br />
          TLS-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu
          schützen, nutzen wir eine TLS-Verschlüsselung. Sie erkennen derart verschlüsselte
          Verbindungen an dem Präfix https:// in der Adresszeile Ihres Browsers.
        </Trans>
      </Typography>
    </Box>
  );
};
