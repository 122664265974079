import { FolderOutline, KeyOutline } from '@deepup/icons';
import { type ReactNode, type FC, useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useApiAccessProjectsApi, type ApiAccess } from '@hooks/api/apiAccesses';

import { StepPath, type ApiAccessesFormModeProps, type NavigationOptions } from './types';
import { useFormApiAccessesState } from './useFormApiAccessesState';

interface ApiAccessesFormProps {
  isCreate?: boolean;
  children: (options: ApiAccessesFormModeProps) => ReactNode;
}

export const ApiAccessesForm: FC<ApiAccessesFormProps> = ({ isCreate, children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { apiAccess } = useFormApiAccessesState();
  const { data: apiAccessProjectsData } = useApiAccessProjectsApi(id);

  const [currentStep, setCurrentStep] = useState<number>(0);

  const STEPS = useMemo(
    () => [
      {
        path: StepPath.overview,
        label: t('pages.apiAccesses.form.overview.sections.accessData'),
        icon: <KeyOutline height={24} width={24} />,
      },
      {
        path: StepPath.projects,
        label: `${t('common.projects')}${
          !isCreate ? ` (${apiAccessProjectsData?.projectIds.length || 0})` : ''
        }`,
        icon: <FolderOutline height={24} width={24} />,
      },
    ],
    [apiAccessProjectsData?.projectIds.length, isCreate, t],
  );

  const MAX_STEPS = STEPS.length;

  const handleRequestNavigate = useCallback(
    ({ state, mode }: NavigationOptions) => {
      const nextStep = mode === 'next' ? currentStep + 1 : currentStep - 1;
      const isInRange = mode === 'next' ? nextStep < MAX_STEPS : nextStep >= 0;

      if (isInRange) {
        const nextPath = STEPS[nextStep].path;
        const nextRoute = `/api-accesses/${id ?? 'create'}/${nextPath}`;

        setCurrentStep(nextStep);
        navigate(nextRoute, {
          state: { ...state },
        });
      } else {
        if (isCreate) {
          navigate(`/api-accesses/${apiAccess?.clientId}/overview`);
          setCurrentStep(0);
        }
      }
    },
    [currentStep, MAX_STEPS, STEPS, id, navigate, isCreate, apiAccess?.clientId],
  );

  const handleNavigateByTab = useCallback(
    (index: number) => () => {
      setCurrentStep(index);
      navigate(`${STEPS[index].path}`, { state: { apiAccess } });
    },
    [STEPS, apiAccess, navigate],
  );

  const handleGoBack = () => {
    handleRequestNavigate({ state: { apiAccess }, mode: 'prev' });
  };

  const handleGoNext = (response?: ApiAccess) => {
    const latestStateOfApiAccess = response ?? apiAccess;

    handleRequestNavigate({
      state: { apiAccess: latestStateOfApiAccess },
      mode: 'next',
    });
  };

  const handleCancel = () => {
    navigate('/api-accesses');
  };

  useEffect(() => {
    const initialCalledPath = pathname.split('/').slice(-1)[0];
    const existingPaths = STEPS.map(({ path }) => path);

    if (!existingPaths.includes(initialCalledPath as StepPath)) {
      handleNavigateByTab(0)();
    }
  }, [STEPS, handleNavigateByTab, pathname]);

  return (
    <>
      {children({
        STEPS,
        MAX_STEPS,
        currentStep,
        handleNavigateByTab,
        handleCancel,
        handleGoBack,
        handleGoNext,
      })}
    </>
  );
};
