import { CircleInformationOutline, EyeOutline, EyeSlashedOutline } from '@deepup/icons';
import { Button, InputAdornment, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { FormInput } from '@components/FormElements';

import { RegisterFormConsent } from './RegisterFormConsent';
import type { UserRegisterFormResponsive } from './UserRegisterForm';

export const UserRegisterFormDesktop: FC<UserRegisterFormResponsive> = ({
  onSubmit,
  formErrors,
  register,
  control,
  onClickShowPassword,
  onMouseDownPassword,
  showPassword,
}) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email'),
    name = searchParams.get('name');

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" py={20}>
      <Typography fontWeight="bold" variant="h4">
        {t('pages.register.create.title')}
      </Typography>
      <Box marginTop={5} />
      <form onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column" width="100%">
          <FormInput
            disabled
            error={formErrors.email}
            formFieldName="email"
            fullWidth
            isRequired
            label={`${t('entities.user.fields.email.label')}`}
            register={register}
            value={email ?? ''}
          />
          <FormInput
            defaultValue={name}
            error={formErrors.name}
            formFieldName="name"
            fullWidth
            isRequired
            label={t('entities.user.fields.name.label')}
            register={register}
          />
          <FormInput
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ marginRight: 2 }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={onClickShowPassword}
                    onMouseDown={onMouseDownPassword}
                  >
                    {showPassword ? (
                      <EyeSlashedOutline height={24} width={24} />
                    ) : (
                      <EyeOutline height={24} width={24} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={formErrors.password}
            formFieldName="password"
            fullWidth
            helperText={
              <Stack gap={1}>
                {t('entities.user.fields.password.placeholder')}
                <Stack alignItems="center" flexDirection="row" gap={1}>
                  <CircleInformationOutline height={16} width={16} />
                  <Typography variant="body2">
                    {t('pages.register.create.components.info.passwordStrength')}
                  </Typography>
                </Stack>
              </Stack>
            }
            isRequired
            label={t('entities.user.fields.password.label')}
            register={register}
            type={showPassword ? 'text' : 'password'}
          />
          <FormInput
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ marginRight: 2 }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={onClickShowPassword}
                    onMouseDown={onMouseDownPassword}
                  >
                    {showPassword ? (
                      <EyeSlashedOutline height={24} width={24} />
                    ) : (
                      <EyeOutline height={24} width={24} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={formErrors.confirmPassword}
            formFieldName="confirmPassword"
            fullWidth
            helperText={t('pages.register.create.components.fields.confirmPassword.placeholder')}
            isRequired
            label={t('pages.register.create.components.fields.confirmPassword.label')}
            register={register}
            type={showPassword ? 'text' : 'password'}
          />

          <RegisterFormConsent control={control} />
          <Box mx={1} />
          <Box marginTop={4} />
          <Box alignSelf="flex-end">
            <Button type="submit" variant="contained">
              {t('common.register')}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
