import { Backdrop, Box, CircularProgress, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface LoadingBackdropProps {
  open: boolean;
}

export const LoadingBackdrop: React.FC<LoadingBackdropProps> = ({ open }) => {
  const { t } = useTranslation();

  return (
    <Backdrop open={open} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}>
      <Paper
        sx={(theme) => ({
          width: theme.spacing(44),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingY: theme.spacing(3),
          paddingX: theme.spacing(2.5),
        })}
      >
        <CircularProgress color="inherit" />
        <Box component="span" sx={(theme) => ({ paddingY: theme.spacing(1) })} />
        <Typography component="span" sx={{ textAlign: 'center' }}>
          {t('pages.user.edit.messages.waitForSending')}
        </Typography>
      </Paper>
    </Backdrop>
  );
};
