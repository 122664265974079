import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const OverviewProcessing = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">
        {t('pages.privacyPolicy.sections.overviewProcessing.title')}
      </Typography>
      <Box my={1} />
      <Typography>
        {t('pages.privacyPolicy.sections.overviewProcessing.content.prelude')}
      </Typography>
      <Box my={1} />
      <Typography component="span" fontWeight="bold">
        {t('pages.privacyPolicy.sections.overviewProcessing.content.list.processedData.title')}
      </Typography>
      <ul>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processedData.inventory',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processedData.location',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processedData.contact',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processedData.content',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processedData.contract',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t('pages.privacyPolicy.sections.overviewProcessing.content.list.processedData.usage')}
          </Typography>
        </li>
        <li>
          <Typography>
            {t('pages.privacyPolicy.sections.overviewProcessing.content.list.processedData.meta')}
          </Typography>
        </li>
      </ul>
      <Typography component="span" fontWeight="bold">
        {t('pages.privacyPolicy.sections.overviewProcessing.content.list.dataSubjects.title')}
      </Typography>
      <ul>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.dataSubjects.customers',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.dataSubjects.communicationPartners',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t('pages.privacyPolicy.sections.overviewProcessing.content.list.dataSubjects.users')}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.dataSubjects.businessPartner',
            )}
          </Typography>
        </li>
      </ul>
      <Typography component="span" fontWeight="bold">
        {t('pages.privacyPolicy.sections.overviewProcessing.content.list.processing.title')}
      </Typography>
      <ul>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processing.provisionCustomerSupport',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processing.contactRequests',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processing.securityMeasures',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processing.webAnalytics',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t('pages.privacyPolicy.sections.overviewProcessing.content.list.processing.targeting')}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processing.officeProcedures',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processing.managingInquries',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processing.profilesUserRelated',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processing.authentication',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processing.provisionOnlineServices',
            )}
          </Typography>
        </li>
        <li>
          <Typography>
            {t(
              'pages.privacyPolicy.sections.overviewProcessing.content.list.processing.informationInfrastructure',
            )}
          </Typography>
        </li>
      </ul>
    </Box>
  );
};
