import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const Registration = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">{t('pages.privacyPolicy.sections.registration.title')}</Typography>
      <Box my={1} />
      <Typography>
        <Trans i18nKey={'pages.privacyPolicy.sections.registration.content.prelude'}>
          Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden den Nutzern die
          erforderlichen Pflichtangaben mitgeteilt und zu Zwecken der Bereitstellung des
          Nutzerkontos auf Grundlage vertraglicher Pflichterfüllung verarbeitet. Zu den
          verarbeiteten Daten gehören insbesondere die Login-Informationen (Nutzername, Passwort
          sowie eine E-Mail-Adresse).
          <br />
          Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen sowie der
          Nutzung des Nutzerkontos speichern wir die IP-Adresse und den Zeitpunkt der jeweiligen
          Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen als
          auch jener der Nutzer an einem Schutz vor Missbrauch und sonstiger unbefugter Nutzung.
          Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, es sei denn, sie ist
          zur Verfolgung unserer Ansprüche erforderlich oder es besteht eine gesetzliche
          Verpflichtung hierzu.
          <br /> Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant sind, wie z.B.
          technische Änderungen, per E-Mail informiert werden.
        </Trans>
      </Typography>
      <ul>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.registration.content.list.general.processedTypes'
              }
            >
              <Typography component="span" fontWeight="bold">
                Verarbeitete Datenarten:
              </Typography>{' '}
              Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern);
              Inhaltsdaten (z.B. Eingaben in Onlineformularen); Meta-/Kommunikationsdaten (z.B.
              Geräte-Informationen, IP-Adressen).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.registration.content.list.general.dataSubjects'
              }
            >
              <Typography component="span" fontWeight="bold">
                Betroffene Personen:
              </Typography>{' '}
              Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.registration.content.list.general.purposesOfProcessing'
              }
            >
              <Typography component="span" fontWeight="bold">
                Zwecke der Verarbeitung:
              </Typography>{' '}
              Erbringung vertraglicher Leistungen und Kundenservice; Sicherheitsmaßnahmen;
              Verwaltung und Beantwortung von Anfragen; Bereitstellung unseres Onlineangebotes und
              Nutzerfreundlichkeit.
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={'pages.privacyPolicy.sections.registration.content.list.general.legalBasis'}
            >
              <Typography component="span" fontWeight="bold">
                Rechtsgrundlagen:
              </Typography>{' '}
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO);
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </Trans>
          </Typography>
        </li>
      </ul>
      <Typography component="span" fontWeight="bold">
        {t('pages.privacyPolicy.common.furtherNotes')}
      </Typography>
      <ul>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.registration.content.list.further.registrationRealName'
              }
            >
              <Typography component="span" fontWeight="bold">
                Registrierung mit Klarnamen:
              </Typography>{' '}
              Aufgrund der Natur unserer Community bitten wir die Nutzer unser Angebot nur unter
              Verwendung von Klarnamen zu nutzen. D.h. die Nutzung von Pseudonymen ist nicht
              zulässig;{' '}
              <Typography component="span" fontWeight="bold">
                Rechtsgrundlagen:
              </Typography>{' '}
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.registration.content.list.further.settingVisibilityProfiles'
              }
            >
              <Typography component="span" fontWeight="bold">
                Einstellung der Sichtbarkeit von Profilen:
              </Typography>{' '}
              Die Nutzer können mittels Einstellungen bestimmen, in welchem Umfang ihre Profile für
              die Öffentlichkeit oder nur für bestimmte Personengruppen sichtbar, bzw. zugänglich
              sind;{' '}
              <Typography component="span" fontWeight="bold">
                Rechtsgrundlagen:
              </Typography>{' '}
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.registration.content.list.further.noObligation'
              }
            >
              <Typography component="span" fontWeight="bold">
                Keine Aufbewahrungspflicht für Daten:
              </Typography>{' '}
              Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung vor dem Vertragsende zu
              sichern. Wir sind berechtigt, sämtliche während der Vertragsdauer gespeicherte Daten
              des Nutzers unwiederbringlich zu löschen;{' '}
              <Typography component="span" fontWeight="bold">
                Rechtsgrundlagen:
              </Typography>{' '}
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </Trans>
          </Typography>
        </li>
      </ul>
    </Box>
  );
};
