import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import type { ReactNode, FC } from 'react';

import { PageHeader } from '@components/PageHeader';

export interface DetailPageLayoutProps {
  title: ReactNode;
  children: ReactNode;
}

export const DetailPageLayout: FC<DetailPageLayoutProps> = ({ children, title }) => {
  return (
    <Stack>
      <Box component="header">
        {typeof title === 'string' ? (
          <PageHeader>{title}</PageHeader>
        ) : (
          <Box alignItems="center" display="flex">
            {title}
          </Box>
        )}
      </Box>
      <Box my={1} />
      {children}
    </Stack>
  );
};
