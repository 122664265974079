import { Skeleton } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid';

import { generateTableTestId } from '@utils/tables';

const SkeletonLoader: React.FC = () => {
  return <Skeleton height="32px" width="100%" />;
};

export const renderCellContent = ({
  entityType,
  params,
  isLoading,
  columnField,
  renderContent,
  key = 'name',
}: {
  entityType: string;
  params: GridRenderCellParams;
  isLoading: boolean;
  columnField: string;
  key?: string;
  renderContent?: (value: string) => React.ReactNode;
}): React.ReactNode => {
  if (isLoading) {
    return <SkeletonLoader />;
  }

  const content = renderContent ? renderContent(params.value) : params.value;

  return (
    <div data-testid={generateTableTestId(entityType, columnField, params.row[key])}>{content}</div>
  );
};
