import { useCallback } from 'react';

import { useJsonFetch } from '@hooks/api/useJsonFetch';

import type { UserPermission } from './types';

export const useGroupsUserApi = () => {
  const jsonFetch = useJsonFetch<UserPermission[]>();

  const fetcher = useCallback(
    (id: string) =>
      jsonFetch(`/groups/${id}/users`, {
        method: 'GET',
      }),
    [jsonFetch],
  );

  return fetcher;
};
