import { CircleQuestionmarkOutline } from '@deepup/icons';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import type { FC, ReactNode } from 'react';

type FormHeaderProps = TypographyProps & {
  title: string;
  tooltip?: ReactNode;
};

export const FormSectionHeader: FC<FormHeaderProps> = ({ title, tooltip, ...typographyProps }) => {
  return (
    <Box alignItems="center" display="flex" flexDirection="row" mb={2}>
      <Typography
        fontSize={(theme) => theme.spacing(2.25)}
        {...typographyProps}
        sx={(theme) => ({
          fontWeight: theme.typography.fontWeightBold,
        })}
      >
        {title}
      </Typography>
      {tooltip && (
        <Tooltip arrow title={tooltip}>
          <Box alignItems="center" display="flex" ml={1}>
            <CircleQuestionmarkOutline height={24} width={24} />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export const FormSectionSubHeader: FC<FormHeaderProps> = (props) => {
  return <FormSectionHeader fontSize={16} {...props} />;
};
