import { ListItemIcon, MenuItem } from '@mui/material';

export type ContextMenuItemProps = {
  onClick?: (event: React.MouseEvent<HTMLLIElement>) => void;
  icon: React.ReactNode;
  label: string;
  'data-testid': string;
};

export const ContextMenuItem = ({
  onClick,
  icon,
  label,
  'data-testid': dataTestId,
}: ContextMenuItemProps) => {
  return (
    <MenuItem data-testid={dataTestId} onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      {label}
    </MenuItem>
  );
};
