import type { FC } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { FetchError } from '@hooks/api/customErrors';
import { useOrganizationCreateApi } from '@hooks/api/organizations';
import { useHandleFetchError } from '@hooks/api/useHandleFetchError';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { DetailPageLayout } from '@pages/common';

import { OrganizationForm } from '../OrganizationForm';

export const OrganizationsCreate: FC = () => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();
  const navigate = useNavigate();
  const createOrganisation = useOrganizationCreateApi();
  const handleFetchError = useHandleFetchError();

  const handleGoBackToOrganizations = () => {
    navigate('/organizations');
  };

  const handleSubmitForm = async (data: FieldValues) => {
    try {
      const response = await createOrganisation(data);

      revalidateCacheForEntity('/organizations');
      showMessage({
        message: t('pages.organization.create.messages.success', {
          orgaName: response.name,
        }),
        type: 'success',
      });
      handleGoBackToOrganizations();
    } catch (error) {
      handleFetchError(error as FetchError, 'organization');
    }
  };

  return (
    <DetailPageLayout title={t('pages.organization.create.title')}>
      <OrganizationForm onSubmit={handleSubmitForm} />
    </DetailPageLayout>
  );
};
