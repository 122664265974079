import { CircleInformationOutline } from '@deepup/icons';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type {
  UseFormRegister,
  FieldErrorsImpl,
  DeepRequired,
  Control,
  FieldValues,
  FieldError,
  Path,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormSectionHeader, FormInput } from '@components/FormElements';
import type { User } from '@hooks/api/users';

import { UserLanguageSelect } from './UserLanguageSelect';

interface UserFormAccountProps<T extends FieldValues> {
  register: UseFormRegister<T>;
  formErrors: FieldErrorsImpl<DeepRequired<T>>;
  user?: User;
  disabled: boolean;
  control: Control<T>;
  userLanguageControlName: Path<T>;
}

export const UserFormAccount = <T extends FieldValues>({
  formErrors,
  register,
  user,
  disabled,
  control,
  userLanguageControlName,
}: UserFormAccountProps<T>) => {
  const { t } = useTranslation();

  return (
    <Box marginBottom={3}>
      <FormSectionHeader title={t('pages.user.form.header.account')} />
      <Box display="flex" flexDirection="column">
        <FormInput
          defaultValue={user?.email}
          disabled={disabled}
          error={formErrors.email as FieldError}
          formFieldName="email"
          helperText={
            !user && (
              <Stack gap={1}>
                <Stack alignItems="center" flexDirection="row" gap={1}>
                  <CircleInformationOutline height={24} width={24} />
                  <Typography variant="body2">
                    {t('pages.user.form.info.autoEmailOnRegister')}
                  </Typography>
                </Stack>
              </Stack>
            )
          }
          isRequired
          label={t('entities.user.fields.email.label')}
          register={register}
        />
      </Box>
      <Box mb={2} />
      <UserLanguageSelect
        control={control}
        controllerName={userLanguageControlName}
        error={formErrors.userLanguage}
      />
    </Box>
  );
};
