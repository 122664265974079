import Box from '@mui/system/Box';
import { type FC, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormSectionHeader, getSelectValue } from '@components/FormElements';
import { useUserCreateApi } from '@hooks/api/users';
import type { UserType } from '@hooks/api/users/types';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';
import { useRenderConfig } from '@hooks/ui';
import { PlatformUserType } from '@hooks/usePlatformPermissions';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { DetailPageLayout } from '@pages/common';

import { UserForm, ExternalUserCreateForm, DeepupUserCreateForm } from '../UserForm';

import { UserTypeHeaderTooltip } from './UserTypeHeaderTooltip';
import { UserTypeSelect } from './UserTypeSelect';

export const UserManagementCreate: FC = () => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();
  const navigate = useNavigate();
  const createUser = useUserCreateApi();

  const { isItemVisible } = useRenderConfig();
  const canCreateExternalUser = isItemVisible('user.create.components.externalUserCreate');
  const canCreateDeepupUser = isItemVisible('user.create.components.deepupUserCreate');

  const [userType, setUserType] = useState<UserType>();

  const handleCreateUser = async (data: FieldValues, userType: UserType) => {
    try {
      const response = await createUser(data, userType);

      revalidateCacheForEntity('/users');

      showMessage({
        message: t('pages.user.create.messages.createSuccess', {
          userName: response.name,
        }),
        type: 'success',
      });
      navigate('/users');
    } catch (error) {
      showMessage({ message: (error as Error).toString(), type: 'error' });
    }
  };

  const handleSubmitInternalUserForm = async (data: FieldValues) => {
    const { isAdmin, ...userFormData } = data;
    const organizationId = getSelectValue(data.organizationId);
    let platformUserType = PlatformUserType.organizationMember;

    if (platformUserType === PlatformUserType.organizationMember && isAdmin)
      platformUserType = PlatformUserType.organizationAdmin;

    const verifiedData = {
      ...userFormData,
      platformUserType,
      organizationId,
    };

    await handleCreateUser(verifiedData, 'internal');
  };

  const handleSubmitExternalUserForm = async (data: FieldValues) => {
    await handleCreateUser(data, 'external');
  };

  const handleSubmitDeepupUserForm = async (data: FieldValues) => {
    const verifiedData = {
      ...data,
      platformUserType: PlatformUserType.organizationMember,
    };

    await handleCreateUser(verifiedData, 'deepup');
  };

  return (
    <DetailPageLayout title={t('pages.user.overview.components.btnCreate')}>
      <Box mb={2}>
        <FormSectionHeader
          title={t('pages.user.form.header.userType')}
          tooltip={
            <UserTypeHeaderTooltip
              canCreateDeepupUser={canCreateDeepupUser}
              canCreateExternalUser={canCreateExternalUser}
            />
          }
        />
        <UserTypeSelect
          canCreateDeepupUser={canCreateDeepupUser}
          canCreateExternalUser={canCreateExternalUser}
          defaultLabel={t('common.pleaseChoose')}
          label={t('pages.user.form.header.userType')}
          onChange={setUserType}
          selectedUserType={userType}
        />
      </Box>
      {userType === 'internal' && <UserForm onSubmit={handleSubmitInternalUserForm} />}
      {userType === 'external' && (
        <ExternalUserCreateForm onSubmit={handleSubmitExternalUserForm} />
      )}
      {userType === 'deepup' && <DeepupUserCreateForm onSubmit={handleSubmitDeepupUserForm} />}
    </DetailPageLayout>
  );
};
