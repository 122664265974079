import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const SpecialNotesApplications = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">
        {t('pages.privacyPolicy.sections.specialNotesApplications.title')}
      </Typography>
      <Box my={1} />
      <Typography>
        <Trans i18nKey={'pages.privacyPolicy.sections.specialNotesApplications.content.prelude'}>
          Wir verarbeiten die Daten der Nutzer unserer Applikation, soweit diese erforderlich sind,
          um den Nutzern die Applikation sowie deren Funktionalitäten bereitstellen, deren
          Sicherheit überwachen und sie weiterentwickeln zu können. Wir können ferner Nutzer unter
          Beachtung der gesetzlichen Vorgaben kontaktieren, sofern die Kommunikation zu Zwecken der
          Administration oder Nutzung der Applikation erforderlich ist. Im Übrigen verweisen wir im
          Hinblick auf die Verarbeitung der Daten der Nutzer auf die Datenschutzhinweise in dieser
          Datenschutzerklärung.
          <br />
          <Typography component="span" fontWeight="bold">
            Rechtsgrundlagen:
          </Typography>{' '}
          Die Verarbeitung von Daten, die für die Bereitstellung der Funktionalitäten der
          Applikation erforderlich ist, dient der Erfüllung von vertraglichen Pflichten. Dies gilt
          auch, wenn die Bereitstellung der Funktionen eine Berechtigung der Nutzer (z. B. Freigaben
          von Gerätefunktionen) voraussetzt. Sofern die Verarbeitung von Daten für die
          Bereitstellung der Funktionalitäten der Applikation nicht erforderlich ist, aber der
          Sicherheit der Applikation oder unseren betriebswirtschaftlichen Interessen dient (z. B.
          Erhebung von Daten zu Zwecken der Optimierung der Applikation oder Sicherheitszwecken),
          erfolgt sie auf Grundlage unserer berechtigten Interessen. Sofern Nutzer ausdrücklich
          deren Einwilligung in die Verarbeitung ihrer Daten gebeten werden, erfolgt die
          Verarbeitung der von der Einwilligung umfassten Daten auf Grundlage der Einwilligung.
        </Trans>
      </Typography>
      <ul>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.specialNotesApplications.content.list.general.processedTypes'
              }
            >
              <Typography component="span" fontWeight="bold">
                Verarbeitete Datenarten:
              </Typography>{' '}
              Bestandsdaten (z.B. Namen, Adressen); Meta-/Kommunikationsdaten (z.B.
              Geräte-Informationen, IP-Adressen); Standortdaten (Angaben zur geografischen Position
              eines Gerätes oder einer Person).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.specialNotesApplications.content.list.general.purposesOfProcessing'
              }
            >
              <Typography component="span" fontWeight="bold">
                Zwecke der Verarbeitung:
              </Typography>{' '}
              Erbringung vertraglicher Leistungen und Kundenservice.
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.specialNotesApplications.content.list.general.legalBasis'
              }
            >
              <Typography component="span" fontWeight="bold">
                Rechtsgrundlagen:
              </Typography>{' '}
              Einwilligung (Art. 6 Abs. 1 S. 1 lit. A) DSGVO); Vertragserfüllung und vorvertragliche
              Anfragen (Art. 6 Abs. 1 S. 1 lit. B) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S.
              1 lit. F) DSGVO).
            </Trans>
          </Typography>
        </li>
      </ul>

      <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
        {t('pages.privacyPolicy.common.furtherNotes')}
      </Typography>
      <ul>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.specialNotesApplications.content.list.further.storageUniqueIdentifier'
              }
            >
              <Typography component="span" fontWeight="bold">
                Speicherung eines pseudonymen Identifikators:
              </Typography>{' '}
              Damit wir die Applikation bereitstellen und ihre Funktionsfähigkeit sicherstellen
              können, verwenden wir einen pseudonymen Identifikator. Der die Identifikation ist ein
              mathematischer Wert (d. h. es werden keine Klardaten, wie z. B. Namen verwendet), der
              einem Gerät und/ oder der auf ihm installierten Installation der Applikation
              zugeordnet ist. Dieser Identifikator wird bei der Installation dieser Anwendung
              generiert, bleibt zwischen dem Start der Applikation sowie ihrer Aktualisierungen
              gespeichert und wird gelöscht, wenn Nutzer die Applikation von dem Gerät entfernen.
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.specialNotesApplications.content.list.further.processingLocationData'
              }
            >
              <Typography component="span" fontWeight="bold">
                Verarbeitung von Standortdaten:
              </Typography>{' '}
              Im Rahmen der Nutzung unserer Applikation werden die von dem verwendeten Gerät
              erhobenen oder sonst durch die Nutzer eingegebenen Standortdaten verarbeitet. Die
              Nutzung der Standortdaten setzt eine Berechtigung der Nutzer, die jederzeit widerrufen
              werden kann, voraus. Die Nutzung der Standortdaten dient jeweils nur der
              Bereitstellung der jeweiligen Funktionalität unserer Applikation, entsprechend ihrer
              Beschreibung gegenüber den Nutzern, bzw. ihrer typischen und erwartbaren
              Funktionsweise.
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.specialNotesApplications.content.list.further.locationHistory'
              }
            >
              <Typography component="span" fontWeight="bold">
                Kein Standortverlauf und keine Bewegungsprofile:
              </Typography>{' '}
              Die Standortdaten werden lediglich punktuell eingesetzt und nicht zur Bildung eines
              Standortverlaufs oder eines Bewegungsprofils der verwendeten Geräte, bzw. ihrer Nutzer
              verarbeitet.
            </Trans>
          </Typography>
        </li>
      </ul>
    </Box>
  );
};
