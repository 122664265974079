import Box from '@mui/material/Box';
import { type FC, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';

import { BottomFormNavigation } from '@components/BottomFormNavigation/BottomFormNavigation';
import { FormSectionHeader } from '@components/FormElements';
import { useGroupsProjectsApi, useGroupsProjectsUpdateApi } from '@hooks/api/groups';
import { useProjectsApi, type ProjectPermission } from '@hooks/api/projects';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

import type { OutletContext } from '../types';
import { useFormGroupState } from '../useFormGroupState';

import { AllProjectsTableContainer } from './AllProjectsTable';
import { ProjectsInGroupTableContainer } from './ProjectsInGroupTable';

interface GroupsFormPermissionsProps {
  isDeepUpInternal?: boolean;
  isEdit?: boolean;
}

export const GroupsFormPermissions: FC<GroupsFormPermissionsProps> = ({
  isDeepUpInternal = false,
  isEdit = false,
}) => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();
  const { isItemEnabled } = useRenderConfig();
  const isFormEnabled = isItemEnabled('groups.edit.form.mode');
  const { data: projectsData, error: projectsError } = useProjectsApi();
  const { id } = useParams();
  const getGroupProjects = useGroupsProjectsApi();
  const updateGroupProjects = useGroupsProjectsUpdateApi();
  const { handleGoBack, handleGoNext } = useOutletContext<OutletContext>();
  const { group } = useFormGroupState();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedProjects, setSelectedProjects] = useState<ProjectPermission[]>([]);
  const [error, setError] = useState<string>();

  const handleSubmitAndNext = async () => {
    if (selectedProjects.length === 0) {
      setError(t('pages.groupsPermissions.create.messages.errorAddOneProject'));

      return;
    }

    const unconfiguredProjectPermission = selectedProjects.find(
      (projectPermission) =>
        !projectPermission.hasDocumentation &&
        !projectPermission.hasMonitoring &&
        !projectPermission.hasInvoiceTool &&
        !projectPermission.backofficeRole,
    );

    if (unconfiguredProjectPermission) {
      setError(t('pages.groupsPermissions.create.messages.errorAddOnePermission'));
      window.scrollTo({
        left: 0,
        top: 0,
      });

      return;
    }

    if (group?.id) {
      try {
        await updateGroupProjects({ id: group.id, projectPermissions: selectedProjects });
        showMessage({
          message: t(
            isEdit
              ? 'pages.groupsPermissions.edit.messages.successUpdateProjects'
              : 'pages.groupsPermissions.create.messages.successSaveProjects',
          ),
          type: 'success',
        });

        showMessage({
          message: t(
            isEdit
              ? 'pages.groupsPermissions.edit.messages.successUpdateGroup'
              : 'pages.groupsPermissions.create.messages.successCreateGroup',
            {
              nameGroup: group?.name,
            },
          ),
          type: 'success',
        });

        handleGoNext(group);
      } catch (error) {
        console.warn('response of updateGroupProjects', error);
        showMessage({
          message: t('pages.groupsPermissions.create.messages.errorSaveProjects', {
            errorMessage: error,
          }),
          type: 'error',
        });
      }
    }
  };

  const handleProjectsUpdate = (updatedProjectsList: ProjectPermission[]) => {
    setSelectedProjects(updatedProjectsList);
  };

  const selectableProjects = useMemo(() => {
    if (!projectsData?.content) return [];

    if (isDeepUpInternal) return projectsData?.content;

    return projectsData?.content.filter(
      (project) => project.organizationId === group?.organizationId,
    );
  }, [projectsData?.content, group, isDeepUpInternal]);

  useEffect(() => {
    setError(undefined);
  }, [selectedProjects]);

  useEffect(() => {
    if (error) {
      showMessage({ message: error, type: 'error' });
      setError(undefined);
    }
  }, [error, showMessage]);

  useEffect(() => {
    const initProjectsOfGroup = async () => {
      if (group?.id) {
        const response = await getGroupProjects(group.id);

        response.sort((a, b) =>
          a.project?.name < b.project?.name ? -1 : a.project?.name > b.project?.name ? 1 : 0,
        );

        setSelectedProjects(response);
      }
      setIsLoading(false);
    };

    initProjectsOfGroup();
  }, [id, group, getGroupProjects]);

  if (!projectsData) return <Box>Waiting for data...</Box>;
  if (projectsError) return <Box>Error while fetching organizations data</Box>;

  return (
    <Box display="flex" flexDirection="column">
      <Box marginBottom={4}>
        <FormSectionHeader
          title={t('pages.groupsPermissions.create.components.header.projectsInGroup')}
        />
        <Box display="flex" flexDirection="column">
          <ProjectsInGroupTableContainer
            entities={selectedProjects}
            isDeepUpInternal={isDeepUpInternal}
            isLoading={isLoading}
            onChangeProjectPermissions={handleProjectsUpdate}
            selectedProjects={selectedProjects}
          />
        </Box>
      </Box>
      <BottomFormNavigation
        backTitle={t('common.goBack')}
        hasNext={isFormEnabled}
        nextTitle={`${t('common.save')} & ${t('common.finish')}`}
        onGoBack={handleGoBack}
        onGoNext={handleSubmitAndNext}
      />
      <Box marginBottom={4}>
        <AllProjectsTableContainer
          isLoading={isLoading}
          onAddProject={handleProjectsUpdate}
          projects={selectableProjects}
          selectedProjects={selectedProjects}
        />
      </Box>
    </Box>
  );
};
