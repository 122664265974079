import { CheckmarkCircleOutline } from '@deepup/icons';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import type { UserRegistrationResponse } from '@hooks/api/users/types';
import { useEnvironment } from '@hooks/useEnvironment';

export const UserRegisterSuccess: FC = () => {
  const { t } = useTranslation();
  const { cssUrl, cockpitUrl } = useEnvironment();
  const { state } = useLocation();
  const { hasDocumentation, hasMonitoring, isOrganizationAdmin, isGroupAdmin } = (state ?? {
    hasDocumentation: false,
    hasMonitoring: false,
    isOrganizationAdmin: false,
    isGroupAdmin: false,
  }) as UserRegistrationResponse;

  const openExternalLink = (url: string) => () => {
    window.open(url, '_blank');
  };

  const openOrganizationManagement = (path = '') => openExternalLink(location.host + path);

  return (
    <Box>
      <Box alignItems="center" display="flex" flexDirection="column">
        <Typography fontWeight="bold" variant="h4">
          {t('pages.register.success.title')}
        </Typography>
        <Box marginTop={8} />
        <Box alignItems="center" display="flex" justifyContent="center">
          <CheckmarkCircleOutline fill="primary" height={24} spacing={12} width={24} />
        </Box>
        <Box marginTop={8} />
        <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
          {hasDocumentation && (
            <Button onClick={openExternalLink(cssUrl)} variant="contained">
              {t('pages.register.success.components.btnHasDocumentation')}
            </Button>
          )}
          {hasMonitoring && (
            <Button onClick={openExternalLink(cockpitUrl)} variant="contained">
              {t('pages.register.success.components.btnHasMonitoring')}
            </Button>
          )}
          {isOrganizationAdmin && (
            <Button onClick={openOrganizationManagement()} variant="contained">
              {t('pages.register.success.components.btnIsOrganizationAdmin')}
            </Button>
          )}
          {!isOrganizationAdmin && isGroupAdmin && (
            <Button onClick={openOrganizationManagement('/groups')} variant="contained">
              {t('pages.register.success.components.btnIsGroupAdmin')}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
