import { object, string, boolean } from 'yup';

export const internalUserFormSchema = ({
  error,
  /* super weird i know, it's because when creating an external user we use the schema below. but when editing an external user we want to offer
  more properties to edit on the external user, similar to an internal user. problem here is that when creating an external user,
  the orga id in the form will not be set as orga id in the user entity but as an organizational reference. then when editing an external user
  with the schema of an internal user, the orga id will always be missing and cannot be set within the form as this is not allowed */
  isExternal = false,
}: {
  error: {
    name: string;
    onlySpaces: string;
    email: string;
    invalidEmail: string;
    organization: string;
    userLanguage: string;
  };
  isExternal?: boolean;
}) =>
  object({
    id: string(),
    connection: string(),
    status: string(),
    version: string(),
    name: string().required(error.name).matches(/\S/, error.onlySpaces),
    platformUserType: string(),
    password: string(),
    email: string().email(error.invalidEmail).required(error.email),
    organizationId: isExternal
      ? string().notOneOf(['-1'], error.organization)
      : string().required(error.organization).notOneOf(['-1'], error.organization),
    mobileNumber: string(),
    phoneNumber: string(),
    workPosition: string(),
    isAdmin: boolean(),
    isInvoiceToolAdmin: boolean(),
    userLanguage: string().required(error.userLanguage).notOneOf(['-1'], error.userLanguage),
  });

export const externalUserFormSchema = ({
  error,
}: {
  error: { email: string; invalidEmail: string; organization: string; userLanguage: string };
}) =>
  object({
    email: string().email(error.invalidEmail).required(error.email),
    organizationId: string().required(error.organization).notOneOf(['-1'], error.organization),
    password: string(),
    userLanguage: string().required(error.userLanguage).notOneOf(['-1'], error.userLanguage),
  });
