import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const ElectronicCommunications = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">
        {t('pages.privacyPolicy.sections.electronicCommunications.title')}
      </Typography>
      <Box my={1} />
      <Typography>
        <Trans i18nKey={'pages.privacyPolicy.sections.electronicCommunications.content.prelude'}>
          Wir versenden E-Mails und weitere elektronische Benachrichtigungen nur mit der
          Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis. Die Protokollierung des
          Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen zu Zwecken des
          Nachweises seines ordnungsgemäßen Ablaufs. Soweit wir einen Dienstleister mit dem Versand
          von E-Mails beauftragen, erfolgt dies auf Grundlage unserer berechtigten Interessen an
          einem effizienten und sicheren Versandsystem. <br />
          <Typography component="span" fontWeight="bold">
            Inhalte:
          </Typography>{' '}
          <br />
          Einladungs-Emails zu Gruppen, Projekten oder Organisationen. Die Emails dienen
          ausschließlich dazu, den Nutzer operativ über neugewonnene Zugriffsrechte auf der DeepUp
          Platform zu informieren oder zu diesem Zwecke ihr Einverständnis einzuholen.
        </Trans>
      </Typography>
      <ul>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.electronicCommunications.content.list.general.processedTypes'
              }
            >
              <Typography component="span" fontWeight="bold">
                Verarbeitete Datenarten:
              </Typography>{' '}
              Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern);
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen); Nutzungsdaten
              (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.electronicCommunications.content.list.general.dataSubjects'
              }
            >
              <Typography component="span" fontWeight="bold">
                Betroffene Personen:
              </Typography>{' '}
              Kommunikationspartner.
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.electronicCommunications.content.list.general.purposesOfProcessing'
              }
            >
              <Typography component="span" fontWeight="bold">
                Zwecke der Verarbeitung:
              </Typography>{' '}
              Informationsfluss (z.B. per E-Mail).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.electronicCommunications.content.list.general.legalBasis'
              }
            >
              <Typography component="span" fontWeight="bold">
                Rechtsgrundlagen:
              </Typography>{' '}
              Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1
              S. 1 lit. f) DSGVO).
            </Trans>
          </Typography>
        </li>
      </ul>
      <Typography component="span" fontWeight="bold">
        {t('pages.privacyPolicy.common.furtherNotes')}
      </Typography>
      <ul>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.electronicCommunications.content.list.further.measurement'
              }
            >
              <Typography component="span" fontWeight="bold">
                Messung von Öffnungs- und Klickraten:
              </Typography>{' '}
              Die Emails enthalten einen sogenannte &quot;web-beacon&quot;, d.h., eine pixelgroße
              Datei, die beim Öffnen der Email von unserem Versanddienstleister abgerufen wird. Im
              Rahmen dieses Abrufs werden zunächst technische Informationen, wie Informationen zum
              Browser und Ihrem System, als auch Ihre IP-Adresse und der Zeitpunkt des Abrufs,
              erhoben. Diese Informationen werden zur technischen Verbesserung unseres
              Email-Versands anhand der technischen Daten oder der Zielgruppen und ihres
              Leseverhaltens auf Basis ihrer Abruforte (die mit Hilfe der IP-Adresse bestimmbar
              sind) oder der Zugriffszeiten genutzt. Diese Analyse beinhaltet ebenfalls die
              Feststellung, ob die Email geöffnet werden, wann sie geöffnet werden und welche Links
              geklickt werden. Die Messung der Öffnungsraten und der Klickraten sowie Speicherung
              der Messergebnisse sowie deren weitere Verarbeitung erfolgen auf Grundlage einer
              Einwilligung der Nutzer. Ein getrennter Widerruf der Erfolgsmessung ist leider nicht
              möglich, in diesem Fall muss dem gesamten Email-Verkehr und damit Nutzung der
              Applikation im allgemeinen widersprochen und das Nutzerkonto aufgehoben werden. In
              diesem Fall werden die gespeicherten Profilinformationen gelöscht;
              <Typography component="span" fontWeight="bold">
                Rechtsgrundlagen:
              </Typography>{' '}
              Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.electronicCommunications.content.list.further.emailProvider'
              }
            >
              <Typography component="span" fontWeight="bold">
                E-Mail-Dienstleister:
              </Typography>{' '}
              Zum Versand von Emails nutzen wir einen Cloud-Dienstleister, der sich auf die
              Verarbeitung derartiger Daten spezialisiert hat. Die Verarbeitung des technischen
              Dienstleisters erfolgt ausschließlich auf EU-Servern entsprechend geltendem Gesetz /
              DSGVO und dient ausschließlich dem sicheren und skalierbaren Versand von Emails;{' '}
              <Typography component="span" fontWeight="bold">
                Rechtsgrundlagen:
              </Typography>{' '}
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);
            </Trans>
          </Typography>
        </li>
      </ul>
    </Box>
  );
};
