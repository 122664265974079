import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import type { FC } from 'react';

interface EmptyTableInfoProps {
  info: string;
}

export const EmptyTableInfo: FC<EmptyTableInfoProps> = ({ info }) => {
  return (
    <Box data-testid={'empty-table-info'} display="flex" justifyContent="center" my={2}>
      <Box alignItems="center" display="flex" flexDirection="column" position="relative">
        <Typography fontWeight="bold">{info}</Typography>
        <Box my={1} />
        <Box alignItems="center" display="flex" justifyContent="center">
          <img alt="Empty List Placeholder" src="/empty-list.png" width="220px" />
        </Box>
      </Box>
    </Box>
  );
};
