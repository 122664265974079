import useSWR from 'swr';

import { useJsonFetch } from '@hooks/api/useJsonFetch';

import type { Project } from '../projects';

type ProjectsApiResponse = {
  isOrganizationWide: boolean;
  projectIds: Project[];
};

export const useApiAccessProjectsApi = (clientId?: string) => {
  const jsonFetch = useJsonFetch<ProjectsApiResponse>();
  const response = useSWR(clientId ? `/api-access/${clientId}/projects` : null, jsonFetch);

  return response;
};
