import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

export const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <Box alignItems="center" display="flex" justifyContent="center" width="100%">
      {t('pages.pageNotFound.title')}
    </Box>
  );
};
