import { useSnackbar, type VariantType } from 'notistack';
import { useCallback } from 'react';

export const useSnackbarMessage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showMessage = useCallback(
    ({ message, type }: { message: string; type: VariantType }) => {
      enqueueSnackbar(message, {
        autoHideDuration: 5000,
        variant: type,
      });
    },
    [enqueueSnackbar],
  );

  return {
    showMessage,
  };
};
