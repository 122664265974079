import { useCallback } from 'react';
import type { FieldValues } from 'react-hook-form';

import type { Organization } from '@hooks/api/organizations';
import { useJsonFetch } from '@hooks/api/useJsonFetch';
import { buildBody } from '@hooks/api/utils';

export const useOrganizationCreateApi = () => {
  const jsonFetch = useJsonFetch<Organization>();

  const fetcher = useCallback(
    (newOrganization: FieldValues) =>
      jsonFetch('/organizations', {
        method: 'POST',

        body: buildBody(newOrganization),
      }),
    [jsonFetch],
  );

  return fetcher;
};
