import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Controller = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">{t('pages.privacyPolicy.sections.controller.title')}</Typography>
      <Box my={1} />
      <Typography>{t('pages.privacyPolicy.sections.controller.content.owner')}</Typography>
      <Typography>DeepUp GmbH</Typography>
      <Typography>Am Bonner Bogen 6</Typography>
      <Typography>53227 Bonn</Typography>
      <Typography>{t('pages.privacyPolicy.sections.controller.content.emailAddress')}</Typography>
      <Typography component="a" href="mailto:meet@deepup.de">
        meet@deepup.de
      </Typography>
    </Box>
  );
};
