import useSWR from 'swr';

import { useJsonFetch } from '@hooks/api/useJsonFetch';

import type { ApiAccess } from './types';

export const useApiAccessApi = (id: string) => {
  const jsonFetch = useJsonFetch<ApiAccess>();
  const response = useSWR(id ? `/api-access/${id}` : null, jsonFetch);

  return response;
};
