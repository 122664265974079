import { useCallback } from 'react';

import { useJsonFetch } from '@hooks/api/useJsonFetch';

import type { ProjectPermission } from '../projects';

export const useGroupsProjectsApi = () => {
  const jsonFetch = useJsonFetch<ProjectPermission[]>();

  const fetcher = useCallback(
    (id: string) =>
      jsonFetch(`/groups/${id}/projects`, {
        method: 'GET',
      }),
    [jsonFetch],
  );

  return fetcher;
};
