import Box from '@mui/system/Box';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface UserTypeHeaderTooltipProps {
  canCreateDeepupUser?: boolean;
  canCreateExternalUser?: boolean;
}

export const UserTypeHeaderTooltip: FC<UserTypeHeaderTooltipProps> = ({
  canCreateDeepupUser,
  canCreateExternalUser,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box>
        <b>{t('pages.user.form.custom.internalUser')}</b>
        <br />
        {t('pages.user.form.info.userType.internal')}
      </Box>
      {canCreateExternalUser && (
        <Box mt={1}>
          <b>{t('pages.user.form.custom.externalUser')}</b>
          <br />
          {t('pages.user.form.info.userType.external')}
        </Box>
      )}
      {canCreateDeepupUser && (
        <Box mt={1}>
          <b>{t('pages.user.form.custom.deepupUser')}</b>
          <br />
          {t('pages.user.form.info.userType.deepup')}
        </Box>
      )}
    </Box>
  );
};
