import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { t } from 'i18next';
import {
  Controller,
  type Control,
  type DeepRequired,
  type FieldError,
  type FieldErrorsImpl,
  type FieldValues,
  type Merge,
  type Path,
  type PathValue,
} from 'react-hook-form';

interface UserLanguageSelect<T extends FieldValues> {
  defaultValue?: PathValue<T, Path<T>>;
  disabled?: boolean;
  control: Control<T>;
  error?: Merge<FieldError, FieldErrorsImpl<DeepRequired<unknown>>> | undefined;
  controllerName: Path<T>;
  helperText?: string;
}

const UserLanguageSelect = <T extends FieldValues>({
  disabled,
  control,
  error,
  defaultValue,
  controllerName,
  helperText,
}: UserLanguageSelect<T>) => {
  const defaultItem = defaultValue ? { id: defaultValue, name: defaultValue } : undefined;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const inputWidth = isDesktop ? '50%' : '100%';

  return (
    <Box display="flex" flexDirection="column" mb={2}>
      <FormControl
        disabled={!!disabled}
        sx={{ display: 'flex', flexDirection: 'row', width: inputWidth }}
      >
        <Box display="flex" flex="1 0 0" flexDirection="column">
          <Controller
            control={control}
            defaultValue={defaultValue}
            name={controllerName}
            render={({ field: { onChange } }) => (
              <Autocomplete
                defaultValue={defaultItem}
                getOptionLabel={(p) => p.name}
                id="combo-box-user-language"
                onChange={(_, value) => {
                  onChange(value?.id);
                }}
                options={[
                  { id: 'DE', name: 'DE' },
                  { id: 'EN', name: 'EN' },
                ]}
                renderInput={(params) => (
                  <TextField
                    label={t('entities.user.fields.userLanguage.label')}
                    {...params}
                    error={!!error}
                    helperText={
                      <Box component="span">
                        <Typography component="span" variant="body2">
                          {error?.message?.toString()}
                        </Typography>
                        <Typography component="span" variant="body2">
                          {helperText}
                        </Typography>
                      </Box>
                    }
                    placeholder={t('common.pleaseChoose')}
                  />
                )}
              />
            )}
          />
        </Box>
      </FormControl>
    </Box>
  );
};

export { UserLanguageSelect };
