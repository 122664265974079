import { ArrowUpBox } from '@deepup/icons';
import { useTheme } from '@mui/material';

import { ApiMappingContentBox } from './ApiMappingContentBox';

interface LatestUploadedFileProps {
  file?: File;
  onDelete: () => void;
  title: string;
}

export const LatestUploadedFile = ({ file, onDelete, title }: LatestUploadedFileProps) => {
  const theme = useTheme();

  if (!file) return <></>;

  return (
    <ApiMappingContentBox
      content={file?.name}
      contentInfo={`${file?.size} kB`}
      icon={
        <ArrowUpBox
          fill={theme.palette.primary.main}
          height={theme.spacing(6)}
          width={theme.spacing(6)}
        />
      }
      onDelete={onDelete}
      title={title}
    />
  );
};
