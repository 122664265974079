import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const WebHosting = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">{t('pages.privacyPolicy.sections.webHosting.title')}</Typography>
      <Box my={1} />
      <Typography>{t('pages.privacyPolicy.sections.webHosting.content.prelude')}</Typography>
      <ul>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.webHosting.content.list.general.processedTypes'
              }
            >
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Verarbeitete Datenarten:
              </Typography>{' '}
              Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen); Inhaltsdaten (z.B.
              Eingaben in Onlineformularen).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={'pages.privacyPolicy.sections.webHosting.content.list.general.dataSubjects'}
            >
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Betroffene Personen:
              </Typography>{' '}
              Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.webHosting.content.list.general.purposesOfProcessing'
              }
            >
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Zwecke der Verarbeitung:
              </Typography>{' '}
              Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit;
              Informationstechnische Infrastruktur (Betrieb und Bereitstellung von
              Informationssystemen und technischen Geräten (Computer, Server etc.).);
              Sicherheitsmaßnahmen; Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung
              wiederkehrender Besucher); Tracking (z.B. interessens-/verhaltensbezogenes Profiling,
              Nutzung von Cookies); Profile mit nutzerbezogenen Informationen (Erstellen von
              Nutzerprofilen).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={'pages.privacyPolicy.sections.webHosting.content.list.general.legalBasis'}
            >
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Rechtsgrundlagen:
              </Typography>{' '}
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); Einwilligung (Art. 6 Abs. 1
              S. 1 lit. a) DSGVO).
            </Trans>
          </Typography>
        </li>
      </ul>
      <Box>
        <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
          {t('pages.privacyPolicy.common.furtherNotes')}
        </Typography>
        <ul>
          <li>
            <Typography>
              <Trans
                i18nKey={
                  'pages.privacyPolicy.sections.webHosting.content.list.further.provisionHostingSpace'
                }
              >
                <Typography component="span" fontWeight="bold">
                  Bereitstellung Onlineangebot auf gemietetem Speicherplatz:
                </Typography>{' '}
                Für die Bereitstellung unseres Onlineangebotes nutzen wir Speicherplatz,
                Rechenkapazität und Software, die wir von einem entsprechenden Serveranbieter (auch
                &quot;Webhoster&quot; genannt) mieten oder anderweitig beziehen;
                <Typography component="span" fontWeight="bold">
                  Rechtsgrundlagen:
                </Typography>{' '}
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans
                i18nKey={
                  'pages.privacyPolicy.sections.webHosting.content.list.further.collectionLogFiles'
                }
              >
                <Typography component="span" fontWeight="bold">
                  Erhebung von Zugriffsdaten und Logfiles:
                </Typography>{' '}
                Der Zugriff auf unser Onlineangebot wird in Form von so genannten
                &quot;Server-Logfiles&quot; protokolliert. Zu den Serverlogfiles können die Adresse
                und Name der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs,
                übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst Version,
                das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im
                Regelfall IP-Adressen und der anfragende Provider gehören. Die Serverlogfiles können
                zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine Überlastung der
                Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen,
                sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre
                Stabilität sicherzustellen;{' '}
                <Typography component="span" fontWeight="bold">
                  Rechtsgrundlagen:
                </Typography>{' '}
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);{' '}
                <Typography component="span" fontWeight="bold">
                  Löschung von Daten:
                </Typography>{' '}
                Logfile-Informationen werden für die Dauer von maximal 30 Tagen gespeichert und
                danach gelöscht oder anonymisiert. Daten, deren weitere Aufbewahrung zu
                Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen
                Vorfalls von der Löschung ausgenommen.
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans
                i18nKey={
                  'pages.privacyPolicy.sections.webHosting.content.list.further.emailSendingHosting'
                }
              >
                <Typography component="span" fontWeight="bold">
                  E-Mail-Versand und -Hosting:
                </Typography>{' '}
                Die von uns in Anspruch genommenen Webhosting-Leistungen umfassen ebenfalls den
                Versand, den Empfang sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die
                Adressen der Empfänger sowie Absender als auch weitere Informationen betreffend den
                E-Mailversand (z.B. die beteiligten Provider) sowie die Inhalte der jeweiligen
                E-Mails verarbeitet. Die vorgenannten Daten können ferner zu Zwecken der Erkennung
                von SPAM verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im Internet
                grundsätzlich nicht verschlüsselt versendet werden. Im Regelfall werden E-Mails zwar
                auf dem Transportweg verschlüsselt, aber (sofern kein sogenanntes
                Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf den Servern, von
                denen sie abgesendet und empfangen werden. Wir können daher für den Übertragungsweg
                der E-Mails zwischen dem Absender und dem Empfang auf unserem Server keine
                Verantwortung übernehmen;{' '}
                <Typography component="span" fontWeight="bold">
                  Rechtsgrundlagen:
                </Typography>{' '}
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans
                i18nKey={
                  'pages.privacyPolicy.sections.webHosting.content.list.further.contentDeliveryNetwork'
                }
              >
                <Typography component="span" fontWeight="bold">
                  Content-Delivery-Network:
                </Typography>{' '}
                Wir setzen ein &quot;Content-Delivery-Network&quot; (CDN) ein. Ein CDN ist ein
                Dienst, mit dessen Hilfe Inhalte eines Onlineangebotes, insbesondere große
                Mediendateien, wie Grafiken oder Programm-Skripte, mit Hilfe regional verteilter und
                über das Internet verbundener Server schneller und sicherer ausgeliefert werden
                können;{' '}
                <Typography component="span" fontWeight="bold">
                  Rechtsgrundlagen:
                </Typography>{' '}
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans
                i18nKey={
                  'pages.privacyPolicy.sections.webHosting.content.list.further.cloudServices'
                }
              >
                <Typography component="span" fontWeight="bold">
                  Cloud-Dienste:
                </Typography>{' '}
                Wir nutzen Cloudspeicher, Cloudinfrastrukturdienste und cloudbasierte
                Anwendungssoftware auf DSGVO zertifizierten Rechenzentren innerhalb der EU;{' '}
                <Typography component="span" fontWeight="bold">
                  Rechtsgrundlagen:
                </Typography>{' '}
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. F) DSGVO);
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans
                i18nKey={'pages.privacyPolicy.sections.webHosting.content.list.further.analytics'}
              >
                <Typography component="span" fontWeight="bold">
                  Analytics:
                </Typography>{' '}
                Webanalyse, Reichweitenmessung sowie Messung von Nutzerströmen;
                <Typography component="span" fontWeight="bold">
                  Rechtsgrundlagen:
                </Typography>{' '}
                Einwilligung (Art. 6 Abs. 1 S. 1 lit. A) DSGVO);
              </Trans>
            </Typography>
          </li>
        </ul>
      </Box>
    </Box>
  );
};
