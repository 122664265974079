import { useJsonFetch } from '@hooks/api/useJsonFetch';
import type { User } from '@hooks/api/users';

export const useUserResendInvitationApi = () => {
  const jsonFetch = useJsonFetch<User>();

  return (userId: string, userConnection: string, organizationId: string) => {
    return jsonFetch(
      `/users/${userId}/resend-registration-mail?connection=${userConnection}&organizationId=${organizationId}`,
      {
        method: 'GET',
      },
    );
  };
};
