import Box from '@mui/material/Box';
import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteDialog } from '@components/DeleteDialog';
import { useUserDeleteApi, type User } from '@hooks/api/users';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

import { OrganizationUserTableContainer } from './OrganizationUserTable';

export const OrganizationsEditUser: FC = () => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();
  const deleteUser = useUserDeleteApi();

  const [userToDelete, setUserToDelete] = useState<User>();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

  const handleRequestDelete = (user: User) => () => {
    setConfirmDialogOpen(true);
    setUserToDelete(user);
  };

  const handleConfirmedDelete = async (confirmed: boolean) => {
    setConfirmDialogOpen(false);

    if (confirmed && userToDelete) {
      try {
        const { name: userName } = userToDelete;

        await deleteUser(userToDelete.id);
        setUserToDelete(undefined);
        revalidateCacheForEntity('/users');

        showMessage({
          message: t('pages.user.edit.messages.deleteSuccess', { userName }),
          type: 'success',
        });
      } catch (error) {
        console.error(error);
        showMessage({ message: (error as Error).toString(), type: 'error' });
      }
    }
  };

  return (
    <Box>
      <OrganizationUserTableContainer onRequestDelete={handleRequestDelete} />
      <DeleteDialog
        message={t('pages.user.edit.components.dialog.content')}
        onClick={handleConfirmedDelete}
        open={confirmDialogOpen}
        title={t('pages.user.edit.components.dialog.title')}
      />
    </Box>
  );
};
