import useSWR from 'swr';

import type { PaginatedServerResponse } from './types';
import { useJsonFetch } from './useJsonFetch';

interface UseApiWithOptionsConfig {
  context: string;
  queryString: string;
}

export function useApiWithOptions<T>({ context, queryString }: UseApiWithOptionsConfig) {
  const jsonFetch = useJsonFetch<PaginatedServerResponse<T>>();
  const query = `/${context}${queryString}`;

  const fetcher = async (url: string): Promise<PaginatedServerResponse<T>> => {
    return await jsonFetch(url, {}, false);
  };

  return useSWR<PaginatedServerResponse<T>>(query, fetcher);
}
