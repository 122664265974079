import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useApiAccessApi } from '@hooks/api/apiAccesses';

import type { FormNavigationState } from './types';

export const useFormApiAccessesState = () => {
  const params = useParams();
  const apiAccessId = params.id as string;
  const { data: apiAccessData } = useApiAccessApi(apiAccessId);
  const { state } = useLocation();

  const apiAccess = useMemo(() => {
    const formNavigationState = state as FormNavigationState;

    if (formNavigationState?.apiAccess) return formNavigationState.apiAccess;

    return apiAccessData;
  }, [state, apiAccessData]);

  return {
    apiAccess,
  };
};
