import { OfficeBuildingCrossedOutline } from '@deepup/icons';
import { Box, Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { User } from '@hooks/api/users';
import { getOpenOrgInvitations } from '@utils/user';

interface ResendInvitationDialogProps {
  user: User;
  open: boolean;
  onSubmit: (orgId: string) => void;
  onCancel: () => void;
}

export const ResendInvitationDialog: FC<ResendInvitationDialogProps> = ({
  user,
  open,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [selectedOrgId, setSelectedOrgId] = useState<string>();

  const handleCancel = () => {
    setSelectedOrgId(undefined);
    onCancel();
  };

  const handleSubmit = () => {
    if (selectedOrgId) onSubmit(selectedOrgId);
  };

  const organizationRadioButtons = getOpenOrgInvitations(user.organizationRelations).map(
    (orgRelation) => (
      <Box alignItems="center" display="flex" key={orgRelation.id}>
        <FormControlLabel
          control={
            <Radio
              checked={selectedOrgId === orgRelation.id}
              onChange={(e) => setSelectedOrgId(e.currentTarget.value)}
            />
          }
          label={orgRelation.name}
          value={orgRelation.id}
        />
        {orgRelation.isExternal && <OfficeBuildingCrossedOutline height={24} width={24} />}
      </Box>
    ),
  );

  return (
    <Dialog
      PaperProps={{
        elevation: 0,
      }}
      onClick={(e) => e.stopPropagation()}
      open={open}
    >
      <DialogTitle>
        <Typography fontWeight="bold" sx={(theme) => ({ fontSize: theme.spacing(3) })}>
          {t('pages.user.overview.components.resendInvitationDialog.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography mb={(theme) => theme.spacing(2)}>
          {t('pages.user.overview.components.resendInvitationDialog.description', {
            userName: user.name,
          })}
        </Typography>
        <RadioGroup>{organizationRadioButtons}</RadioGroup>
      </DialogContent>
      <DialogActions sx={(theme) => ({ padding: theme.spacing(2.5) })}>
        <Button
          color="secondary"
          onClick={handleCancel}
          sx={(theme) => ({
            minWidth: theme.spacing(16),
          })}
          variant="outlined"
        >
          {t('common.cancel')}
        </Button>
        <Button
          disabled={!selectedOrgId}
          onClick={handleSubmit}
          sx={(theme) => ({
            minWidth: theme.spacing(16),
          })}
          variant="contained"
        >
          {t('pages.user.overview.components.resendInvitationDialog.btnSubmit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
