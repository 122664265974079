import { ShieldPersonOutline, Hourglass, DocumentTextOutline } from '@deepup/icons';
import { Tab, Tabs, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { type FC, useMemo, useState, useCallback, type SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { DeleteDialog } from '@components/DeleteDialog';
import { PageHeader } from '@components/PageHeader';
import { UserContextMenu } from '@components/UserContextMenu';
import { useUserDeleteApi } from '@hooks/api/users';
import { useRenderConfig } from '@hooks/ui';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { DetailPageLayout } from '@pages/common';
import { hasOpenOrgInvitations } from '@utils/user';

import { useFormUserState } from '../useFormUserState';

const TabRoutesByIndex = ['edit', 'permissions'];

export const UserManagementEdit: FC = () => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isItemVisible } = useRenderConfig();

  const { id } = useParams();
  const { user } = useFormUserState();
  const deleteUser = useUserDeleteApi();

  const tabIndexByPath = useMemo(() => {
    const defaultTabIndex = TabRoutesByIndex.indexOf('edit');

    if (pathname) {
      const currentPath = TabRoutesByIndex.find((route) => pathname.includes(route));

      return currentPath ? TabRoutesByIndex.indexOf(currentPath) : defaultTabIndex;
    }

    return defaultTabIndex;
  }, [pathname]);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(tabIndexByPath);

  const handleTabChange = useCallback(
    async (_: SyntheticEvent<Element, Event>, newIndex: number) => {
      setCurrentTabIndex(newIndex);
      navigate(TabRoutesByIndex[newIndex]);
    },
    [navigate],
  );

  const handleConfirmedDelete = async (confirmed: boolean) => {
    if (!confirmed) {
      setConfirmDialogOpen(false);

      return;
    }
    if (confirmed && id) {
      try {
        const userName = user?.name;

        await deleteUser(id);

        showMessage({
          message: t('pages.user.edit.messages.deleteSuccess', { userName }),
          type: 'success',
        });
        navigate('/users?page=0&size=100');
      } catch (error) {
        console.error(error);
        showMessage({ message: (error as Error).toString(), type: 'error' });
      }
    }
  };

  const renderUserName = () => {
    if (user && hasOpenOrgInvitations(user.organizationRelations)) {
      return (
        <Box alignItems="center" display="flex">
          <Tooltip arrow title={t('common.waitingForRegistration')}>
            <Box alignItems="center" display="flex">
              <Hourglass height={32} width={32} />
            </Box>
          </Tooltip>{' '}
          {user?.name}
        </Box>
      );
    }

    return user?.name;
  };

  return (
    <DetailPageLayout
      title={
        <>
          <PageHeader>{renderUserName()}</PageHeader>
          <Box mx={1} />
          {isItemVisible('user.components.contextMenu.mode') && user && (
            <UserContextMenu
              hasResendInvitation={hasOpenOrgInvitations(user.organizationRelations)}
              onClickDelete={() => {
                setConfirmDialogOpen(true);
              }}
              user={user}
            />
          )}
        </>
      }
    >
      <Box my={2} />
      <Box>
        <Tabs aria-label="user-create-tabs" onChange={handleTabChange} value={currentTabIndex}>
          <Tab
            aria-controls="user-create-tabpanel-0"
            icon={<DocumentTextOutline height={24} width={24} />}
            iconPosition="start"
            id="user-create-tab-0"
            label={t('common.overview')}
          />
          <Tab
            aria-controls="user-create-tabpanel-1"
            icon={<ShieldPersonOutline height={24} width={24} />}
            iconPosition="start"
            id="user-create-tab-1"
            label={t('common.projectsGroups')}
          />
        </Tabs>
      </Box>
      <Box marginTop={3.5} />
      <Outlet />
      <DeleteDialog
        message={t('pages.user.edit.components.dialog.content')}
        onClick={handleConfirmedDelete}
        open={confirmDialogOpen}
        title={t('pages.user.edit.components.dialog.title')}
      />
    </DetailPageLayout>
  );
};
