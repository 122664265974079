import { KebabHorizontal } from '@deepup/icons';
import { IconButton, Menu } from '@mui/material';
import { useState, type FC, type MouseEvent, type ReactNode } from 'react';

interface ContextMenuProps {
  children: ReactNode;
  'data-testid'?: string;
}

export const ContextMenu: FC<ContextMenuProps> = ({ children, 'data-testid': dataTestId }) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleCloseContextMenu = (event: MouseEvent<HTMLDivElement>) => {
    setPopoverAnchorEl(null);
    event.stopPropagation();
  };

  const handleOpenContextMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  return (
    <>
      <IconButton
        color="secondary"
        data-testid={dataTestId ?? `context-menu`}
        onClick={handleOpenContextMenu}
        size="small"
      >
        <KebabHorizontal data-testid="KebabHorizontal" fill="currentColor" height={24} width={24} />
      </IconButton>
      <Menu
        anchorEl={popoverAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleCloseContextMenu}
        open={!!popoverAnchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {children}
      </Menu>
    </>
  );
};
