import { useCallback } from 'react';
import type { FieldValues } from 'react-hook-form';

import { useJsonFetch } from '@hooks/api/useJsonFetch';
import { buildBody } from '@hooks/api/utils';

import type { ApiAccess } from './types';

export const useApiAccessCreateApi = () => {
  const jsonFetch = useJsonFetch<ApiAccess>();

  const fetcher = useCallback(
    (newApiAccess: FieldValues) =>
      jsonFetch('/api-access', {
        method: 'POST',

        body: buildBody(newApiAccess),
      }),
    [jsonFetch],
  );

  return fetcher;
};
