import { bool, object, string } from 'yup';

interface SchemaErrors {
  name: string;
  email: string;
  password: {
    required: string;
    strength: string;
    confirm: string;
  };
  consent: string;
}

export const externalUserRegistrationSchema = ({ error }: { error: SchemaErrors }) =>
  object({
    name: string().required(error.name),
    email: string().required(error.email),
    password: string()
      .required(error.password.required)
      .matches(/^(?=.{10,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*=-_]).*$/g, error.password.strength),
    confirmPassword: string()
      .required(error.password.confirm)
      .matches(/^(?=.{10,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*=-_]).*$/g, error.password.strength),
    consent: bool().required(error.consent).oneOf([true], error.consent),
  });
