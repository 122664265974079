import { List, ListItemText, Box, ListItemButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { FormSectionHeader } from '@components/FormElements';
import type { Project } from '@hooks/api/projects';
import { useRenderConfig } from '@hooks/ui';

interface ProjectsFormServiceProvider {
  project: Project;
}

export const ProjectsFormServiceProvider = ({ project }: ProjectsFormServiceProvider) => {
  const { t } = useTranslation();
  const { isItemEnabled } = useRenderConfig();
  const isFormEnabled = isItemEnabled('projects.edit.form.mode');
  const noServiceProviders = !project?.serviceProviders?.length;

  const listItems = project?.serviceProviders?.map(({ groupId, name }) => (
    <ListItemButton component={Link} key={groupId} to={`/deepup-groups/${groupId}`}>
      <ListItemText primary={name} secondary={groupId} />
    </ListItemButton>
  ));

  if (isFormEnabled && noServiceProviders) return null;

  return (
    <Box>
      <FormSectionHeader title={t('pages.projects.form.header.serviceProviders')} />
      <List sx={{ width: '50%', bgcolor: 'background.paper', borderRadius: 1 }}>{listItems}</List>
    </Box>
  );
};
