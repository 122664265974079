import { Checkbox, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import { Controller, type Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormSectionHeader } from '@components/FormElements';
import type { Project } from '@hooks/api/projects';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';

interface ProjectsFormPipeDirectionProps {
  project?: Project;
  control: Control<Partial<Project>>;
}

export const ProjectsFormPipeDirection = ({ project, control }: ProjectsFormPipeDirectionProps) => {
  const { t } = useTranslation();
  const { isItemEnabled } = useRenderConfig();

  const isFormEnabled = isItemEnabled('projects.edit.form.mode');

  return (
    <Box mb={2}>
      <FormSectionHeader title={t('pages.projects.form.header.pipeDirection')} />
      <Controller
        control={control}
        defaultValue={project?.pipeDirection}
        name="pipeDirection"
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                disabled={!isFormEnabled}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            }
            label={t('entities.projects.fields.pipeDirection.label')}
          />
        )}
      />
    </Box>
  );
};
