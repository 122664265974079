import Box from '@mui/material/Box';
import { t } from 'i18next';
import { useState, type FC } from 'react';
import { Outlet } from 'react-router-dom';

import type { Project } from '@hooks/api/projects';
import { useProjectApiExportUpload } from '@hooks/api/projects/useProjectApiExportUpload';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

export const Projects: FC = () => {
  const uploadAssetToProject = useProjectApiExportUpload();
  const { showMessage } = useSnackbarMessage();

  const [apiExportMappingFile, setApiExportMappingFile] = useState<File>();

  const handleOnChangeApiExportMappingFile: (file?: File | undefined) => void =
    setApiExportMappingFile;

  const uploadApiExportData = async (response: Project) => {
    if (apiExportMappingFile) {
      try {
        await uploadAssetToProject(apiExportMappingFile, response.id);
        showMessage({
          message: t('pages.projects.create.messages.uploadAssetSuccess', {
            fileName: apiExportMappingFile.name,
          }),
          type: 'success',
        });
      } catch (error) {
        showMessage({ message: (error as Error).toString(), type: 'error' });
      }
    }
  };

  return (
    <Box flexDirection="column" flexGrow={1}>
      <Outlet context={{ handleOnChangeApiExportMappingFile, uploadApiExportData }} />
    </Box>
  );
};
