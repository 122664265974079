import { useTranslation } from 'react-i18next';

import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

import type { FetchError } from './customErrors';

type Entity = 'organization' | 'groupsPermissions' | 'apiAccesses';

type FallbackMessageKeyCases =
  | 'pages.apiAccesses.create.messages.errorCreateApiAccess'
  | 'pages.apiAccesses.create.messages.errorSaveProjects';

export const useHandleFetchError = () => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();

  return (error: FetchError, entity: Entity, fallbackMessageKey?: FallbackMessageKeyCases) => {
    if (error.status === 409) {
      showMessage({
        message: t(`pages.${entity}.create.messages.errorDuplicateName`),
        type: 'error',
      });
    } else if (fallbackMessageKey) {
      showMessage({
        message: t(fallbackMessageKey, { errorMessage: error }),
        type: 'error',
      });
    } else if (error instanceof Error) {
      showMessage({
        message: error.toString(),
        type: 'error',
      });
    } else {
      throw new Error(`Error type not handled in handleFetchError: ${error}`);
    }
  };
};
