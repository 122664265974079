import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const Preamble = () => {
  const { t } = useTranslation();

  return (
    <Box id="section-preamble" my={4}>
      <Typography variant="h5">{t('pages.privacyPolicy.sections.preamble.title')}</Typography>
      <Box my={1} />
      <Trans i18nKey="pages.privacyPolicy.sections.preamble.content">
        <Typography>
          Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten
          Ihrer personenbezogenen Daten (nachfolgend auch kurz als &quot;Daten&quot; bezeichnet) wir
          zu welchen Zwecken und in welchem Umfang im Rahmen der Bereitstellung unserer
          Applikationen verarbeiten.
        </Typography>
        <Typography>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</Typography>
        <Typography>Stand: 7. Dezember 2022</Typography>
      </Trans>
    </Box>
  );
};
