import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const BusinessServices = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">
        {t('pages.privacyPolicy.sections.businessServices.title')}
      </Typography>
      <Box my={1} />
      <Typography>
        <Trans i18nKey={'pages.privacyPolicy.sections.businessServices.content.prelude'}>
          Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden und
          Interessenten (zusammenfassend bezeichnet als &quot;Vertragspartner&quot;) im Rahmen von
          vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit verbundenen Maßnahmen und
          im Rahmen der Kommunikation mit den Vertragspartnern (oder vorvertraglich), z.B., um
          Anfragen zu beantworten.
          <br />
          Wir verarbeiten diese Daten, um unsere vertraglichen Verpflichtungen zu erfüllen. Dazu
          gehören insbesondere die Verpflichtungen zur Erbringung der vereinbarten Leistungen,
          etwaige Aktualisierungspflichten und Abhilfe bei Gewährleistungs- und sonstigen
          Leistungsstörungen. Darüber hinaus verarbeiten wir die Daten zur Wahrung unserer Rechte
          und zum Zwecke der mit diesen Pflichten verbundenen Verwaltungsaufgaben sowie der
          Unternehmensorganisation. Darüber hinaus verarbeiten wir die Daten auf Grundlage unserer
          berechtigten Interessen an einer ordnungsgemäßen und betriebswirtschaftlichen
          Geschäftsführung sowie an Sicherheitsmaßnahmen zum Schutz unserer Vertragspartner und
          unseres Geschäftsbetriebes vor Missbrauch, Gefährdung ihrer Daten, Geheimnisse,
          Informationen und Rechte (z.B. zur Beteiligung von Telekommunikations-, Transport- und
          sonstigen Hilfsdiensten sowie Subunternehmern, Banken, Steuer- und Rechtsberatern oder
          Behörden). Im Rahmen des geltenden Rechts geben wir die Daten von Vertragspartnern nur
          insoweit an Dritte weiter, als dies für die vorgenannten Zwecke oder zur Erfüllung
          gesetzlicher Pflichten erforderlich ist. Über weitere Formen der Verarbeitung werden die
          Vertragspartner im Rahmen dieser Datenschutzerklärung informiert. <br />
          Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den
          Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B. in Onlineformularen, durch
          besondere Kennzeichnung (z.B. Farben) bzw. Symbole (z.B. Sternchen o.ä.), oder persönlich
          mit. <br />
          Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer
          Pflichten, d.h., grundsätzlich nach Ablauf von 4 Jahren, es sei denn, dass die Daten in
          einem Kundenkonto gespeichert werden, z.B., solange sie aus gesetzlichen Gründen der
          Archivierung aufbewahrt werden müssen. Die gesetzliche Aufbewahrungsfrist beträgt bei
          steuerrechtlich relevanten Unterlagen sowie bei Handelsbüchern, Inventaren,
          Eröffnungsbilanzen, Jahresabschlüssen, die zum Verständnis dieser Unterlagen
          erforderlichen Arbeitsanweisungen und sonstigen Organisationsunterlagen und
          Buchungsbelegen zehn Jahre sowie bei empfangenen Handels- und Geschäftsbriefen und
          Wiedergaben der abgesandten Handels- und Geschäftsbriefe sechs Jahre. Die Frist beginnt
          mit Ablauf des Kalenderjahres, in dem die letzte Eintragung in das Buch gemacht, das
          Inventar, die Eröffnungsbilanz, der Jahresabschluss oder der Lagebericht aufgestellt, der
          Handels- oder Geschäftsbrief empfangen oder abgesandt worden oder der Buchungsbeleg
          entstanden ist, ferner die Aufzeichnung vorgenommen worden ist oder die sonstigen
          Unterlagen entstanden sind <br />
          Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen einsetzen,
          gelten im Verhältnis zwischen den Nutzern und den Anbietern die Geschäftsbedingungen und
          Datenschutzhinweise der jeweiligen Drittanbieter oder Plattformen.
        </Trans>
      </Typography>
      <ul>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.businessServices.content.list.general.processedTypes'
              }
            >
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Verarbeitete Datenarten:
              </Typography>{' '}
              Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern);
              Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie); Nutzungsdaten
              (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
            </Trans>
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.businessServices.content.list.general.dataSubjects'
              }
            >
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Betroffene Personen:
              </Typography>{' '}
              Kunden; Interessenten; Geschäfts- und Vertragspartner.
            </Trans>
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.businessServices.content.list.general.purposesOfProcessing'
              }
            >
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Zwecke der Verarbeitung:
              </Typography>{' '}
              Erbringung vertraglicher Leistungen und Kundenservice; Sicherheitsmaßnahmen;
              Kontaktanfragen und Kommunikation; Büro- und Organisationsverfahren; Verwaltung und
              Beantwortung von Anfragen.
            </Trans>
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.businessServices.content.list.general.legalBasis'
              }
            >
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Rechtsgrundlagen:
              </Typography>{' '}
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO);
              Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO); Berechtigte Interessen
              (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </Trans>
          </Typography>
        </li>
      </ul>
      <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
        {t('pages.privacyPolicy.common.furtherNotes')}
      </Typography>
      <ul>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.businessServices.content.list.further.customerAccount'
              }
            >
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Kundenkonto:
              </Typography>{' '}
              Vertragspartner können innerhalb unseres Onlineangebotes ein Konto anlegen (z.B.
              Kunden- bzw. Nutzerkonto, kurz &quot;Kundenkonto&quot;). Falls die Registrierung eines
              Kundenkontos erforderlich ist, werden Vertragspartner hierauf ebenso hingewiesen wie
              auf die für die Registrierung erforderlichen Angaben. Die Kundenkonten sind nicht
              öffentlich und können von Suchmaschinen nicht indexiert werden. Im Rahmen der
              Registrierung sowie anschließender Anmeldungen und Nutzungen des Kundenkontos
              speichern wir die IP-Adressen der Kunden nebst den Zugriffszeitpunkten, um die
              Registrierung nachweisen und etwaigem Missbrauch des Kundenkontos vorbeugen zu können.
              Wenn Kunden ihr Kundenkonto gekündigt haben, werden die das Kundenkonto betreffenden
              Daten gelöscht, vorbehaltlich, deren Aufbewahrung ist aus gesetzlichen Gründen
              erforderlich. Es obliegt den Kunden, ihre Daten bei erfolgter Kündigung des
              Kundenkontos zu sichern;{' '}
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Rechtsgrundlagen:
              </Typography>{' '}
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </Trans>
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.businessServices.content.list.further.agencyServices'
              }
            >
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Agenturdienstleistungen:
              </Typography>{' '}
              Wir verarbeiten die Daten unserer Kunden im Rahmen unserer vertraglichen Leistungen,
              zu denen z.B. konzeptionelle und strategische Beratung, Kampagnenplanung, Software-
              und Designentwicklung/-beratung oder -pflege, Umsetzung von Kampagnen und Prozessen,
              Handling, Serveradministration, Datenanalyse/ Beratungsleistungen und
              Schulungsleistungen gehören können;
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Rechtsgrundlagen:
              </Typography>{' '}
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </Trans>
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.businessServices.content.list.further.softwareServices'
              }
            >
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Angebot von Software- und Plattformleistungen:
              </Typography>{' '}
              Wir verarbeiten die Daten unserer Nutzer, angemeldeter und etwaiger Testnutzer
              (nachfolgend einheitlich als &quot;Nutzer&quot; bezeichnet), um ihnen gegenüber
              unseren vertraglichen Leistungen erbringen zu können sowie auf Grundlage berechtigter
              Interessen, um die Sicherheit unseres Angebotes gewährleisten und es weiterentwickeln
              zu können. Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-,
              Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und umfassen die zur
              Leistungserbringung und Abrechnung benötigten Angaben sowie Kontaktinformationen, um
              etwaige Rücksprachen halten zu können;{' '}
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Rechtsgrundlagen:
              </Typography>{' '}
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </Trans>
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.businessServices.content.list.further.technicalServices'
              }
            >
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Technische Dienstleistungen:
              </Typography>{' '}
              Wir verarbeiten die Daten unserer Kunden sowie Auftraggeber (nachfolgend einheitlich
              als &quot;Kunden&quot; bezeichnet), um ihnen die Auswahl, den Erwerb bzw. die
              Beauftragung der gewählten Leistungen oder Werke sowie verbundener Tätigkeiten als
              auch deren Bezahlung und Zurverfügungstellung bzw. Ausführung oder Erbringung zu
              ermöglichen. Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-,
              Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und umfassen die zur
              Leistungserbringung und Abrechnung benötigten Angaben sowie Kontaktinformationen, um
              etwaige Rücksprachen halten zu können. Soweit wir Zugang zu Informationen der
              Endkunden, Mitarbeitern oder anderer Personen erhalten, verarbeiten wir diese im
              Einklang mit den gesetzlichen und vertraglichen Vorgaben;
              <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Rechtsgrundlagen:
              </Typography>{' '}
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </Trans>
          </Typography>
        </li>
      </ul>
    </Box>
  );
};
