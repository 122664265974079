import {
  TextField,
  useMediaQuery,
  useTheme,
  type TextFieldProps,
  Typography,
  Stack,
} from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import type { ReactNode } from 'react';
import type {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  UseFormRegister,
} from 'react-hook-form';

type FormInputProps<T extends FieldValues> = Omit<TextFieldProps, 'error'> & {
  formFieldName: string;
  label?: ReactNode;
  register: UseFormRegister<T>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<T>> | undefined;
  helperText?: ReactNode;
  isRequired?: boolean;
};

export const FormInput = <T extends FieldValues>(props: FormInputProps<T>) => {
  const {
    register,
    label,
    error,
    defaultValue,
    formFieldName,
    disabled,
    helperText,
    isRequired,
    fullWidth,
    ...inputProps
  } = props;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const inputWidth = isDesktop && !fullWidth ? '50%' : '100%';
  const inputLabel = label ? (isRequired ? label : `${label} (optional)`) : '';

  return (
    <Box display="flex" flexDirection="column" marginBottom={2}>
      <FormControl
        component="div"
        sx={{ display: 'flex', flexDirection: 'row', width: inputWidth }}
      >
        <Box display="flex" flex="1 0 0" flexDirection="column">
          <TextField
            label={inputLabel}
            {...inputProps}
            aria-describedby={`input for setting ${formFieldName}`}
            defaultValue={defaultValue}
            disabled={disabled}
            id={`user-form-input-${formFieldName}`}
            inputProps={{ 'data-testid': `input-${formFieldName}` }}
            {...register(formFieldName as Path<T>)}
            error={!!error}
            helperText={
              <Stack component="span">
                <Typography component="span" variant="body2">
                  {error?.message?.toString()}
                </Typography>
                <Typography component="span" variant="body2">
                  {helperText}
                </Typography>
              </Stack>
            }
          />
        </Box>
      </FormControl>
    </Box>
  );
};
