import { ArrowUpBox } from '@deepup/icons';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFileValidation } from '@hooks/utils/useFileValidation';

import type { ProjectMappingApiExportProps } from './ProjectMappingApiExport';
import { type ProjectMappingError } from './ProjectMappingErrorAlert';
import { UploadScreen } from './UploadScreen';
import { ValidatingScreen } from './ValidatingScreen';

type ProjectMappingUploadDialogProps = Pick<ProjectMappingApiExportProps, 'disabled'> & {
  onCancel: () => void;
  open: boolean;
  onSubmit: (uploadedFile: File) => void;
  file?: File;
};

export const ProjectMappingUploadDialog = ({
  onCancel,
  open,
  onSubmit,
  file,
  disabled,
}: ProjectMappingUploadDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const validateUploadedFile = useFileValidation();
  const iconSize = theme.spacing(3);

  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const [isValidating, setIsValidating] = useState(false);
  const [error, setError] = useState<ProjectMappingError>();

  const importFileLabel = file
    ? t('pages.projects.create.uploadApiExport.btnReplaceMappingFile')
    : t('pages.projects.create.uploadApiExport.btnImport');

  useEffect(() => {
    setUploadedFile(undefined);
    setError(undefined);
  }, [open]);

  useEffect(() => {
    if (uploadedFile) setError(undefined);
  }, [open, uploadedFile]);

  const handleOnChangeUpload = useCallback(async () => {
    setError(undefined);
    setIsValidating(true);
    const [isSuccess, errorMessage] = await validateUploadedFile(uploadedFile);

    setIsValidating(false);

    if (!isSuccess) {
      setError(errorMessage);

      return false;
    }

    onSubmit(uploadedFile!);
  }, [onSubmit, uploadedFile, validateUploadedFile]);

  const handleOnClose = () => {
    setError(undefined);
    onCancel();
  };

  const handleDeleteUploadedFile = () => {
    setUploadedFile(undefined);
    setError(undefined);
  };

  const hasUploadedFile = !!uploadedFile;

  const dialogTitle = useMemo(() => {
    if (isValidating) return t('pages.projects.create.uploadApiExport.title');

    return t('pages.projects.create.uploadApiExport.validatingTitle');
  }, [isValidating, t]);

  return (
    <Dialog fullWidth onClose={handleOnClose} open={open}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        {isValidating && <ValidatingScreen uploadedFile={uploadedFile} />}
        {!isValidating && (
          <UploadScreen
            disabled={disabled}
            error={error}
            file={uploadedFile}
            onChange={setUploadedFile}
            onDelete={handleDeleteUploadedFile}
            onError={setError}
          />
        )}
        {!isValidating && (
          <DialogActions sx={{ padding: 0, gap: theme.spacing(2) }}>
            <Button onClick={handleOnClose} variant="text">
              {t('common.cancel')}
            </Button>
            <Button
              disabled={!hasUploadedFile}
              onClick={handleOnChangeUpload}
              startIcon={<ArrowUpBox height={iconSize} width={iconSize} />}
            >
              {importFileLabel}
            </Button>
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  );
};
