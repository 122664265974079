import { DocumentArrowOutline } from '@deepup/icons';
import { Box, Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { ProjectMappingApiExportProps } from './ProjectMappingApiExport';

export const ButtonDownloadCsvTemplateFile = ({
  disabled,
}: Pick<ProjectMappingApiExportProps, 'disabled'>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const iconSize = theme.spacing(3);

  const linkToAsset = disabled
    ? '#'
    : 'https://storage.googleapis.com/webassets-deepup-production/files/digitaleBaubegleitungTemplate.csv';

  return (
    <Box>
      <a href={linkToAsset}>
        <Button
          color="secondary"
          disabled={disabled}
          startIcon={<DocumentArrowOutline height={iconSize} width={iconSize} />}
          variant="outlined"
        >
          {t('pages.projects.create.uploadApiExport.btnDownloadCsvTemplate')}
        </Button>
      </a>
    </Box>
  );
};
