import { useCallback } from 'react';

import { useJsonFetch } from '@hooks/api/useJsonFetch';

import type { ApiAccessProjectsUpdateRequestData, ApiAccessProjectsUpdateResponse } from './types';

export const useApiAccessProjectsUpdateApi = () => {
  const jsonFetch = useJsonFetch<ApiAccessProjectsUpdateResponse>();

  const fetcher = useCallback(
    ({ id, projects, isOrganizationWide }: ApiAccessProjectsUpdateRequestData) => {
      const projectIds = projects.map(({ id }) => id);
      const body = {
        isOrganizationWide,
        nextProjectIds: projectIds,
      };

      return jsonFetch(`/api-access/${id}/projects`, {
        method: 'PUT',

        body: JSON.stringify(body),
      });
    },
    [jsonFetch],
  );

  return fetcher;
};
