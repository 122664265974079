import {
  Autocomplete,
  FormControl,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { Controller, type Control, type DeepRequired, type FieldErrorsImpl } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormSectionHeader } from '@components/FormElements';
import type { Project } from '@hooks/api/projects';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';

interface ProjectsFormNetworkDesignProps {
  formErrors: FieldErrorsImpl<DeepRequired<Project>>;
  project?: Project;
  control: Control<Partial<Project>>;
}

export const ProjectsFormNetworkDesign = ({
  project,
  formErrors,
  control,
}: ProjectsFormNetworkDesignProps) => {
  const { t } = useTranslation();
  const { isItemEnabled } = useRenderConfig();

  const isFormEnabled = isItemEnabled('projects.edit.form.mode');

  const networkStates = [
    { id: 'AVAILABLE', name: t('entities.projects.fields.networkDesign.state.states.available') },
    { id: 'INCOMPLETE', name: t('entities.projects.fields.networkDesign.state.states.incomplete') },
    { id: 'PENDING', name: t('entities.projects.fields.networkDesign.state.states.pending') },
    { id: 'UNKNOWN', name: t('entities.projects.fields.networkDesign.state.states.unknown') },
  ];
  const currentState = networkStates.find((state) => state.id === project?.networkDesign?.state);

  const networkFormats = [
    { id: 'PDF', name: 'PDF' },
    { id: 'GEOPACKAGE', name: 'Geopackage' },
    { id: 'DXF', name: 'DXF' },
  ];
  const currentFormat = networkFormats.find(
    (format) => format.id === project?.networkDesign?.format,
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const inputWidth = isDesktop ? '50%' : '100%';

  return (
    <Box mb={2}>
      <FormSectionHeader title={t('pages.projects.form.header.networkDesign')} />
      <Stack gap={2} width={inputWidth}>
        <FormControl disabled={!isFormEnabled}>
          <Controller
            control={control}
            defaultValue={currentState?.id}
            name={'networkDesign.state'}
            render={({ field: { onChange } }) => (
              <Autocomplete
                defaultValue={currentState}
                disabled={!isFormEnabled}
                fullWidth
                getOptionLabel={(p) => p.name}
                onChange={(_, value) => {
                  onChange(value?.id);
                }}
                options={networkStates}
                renderInput={(params) => (
                  <TextField
                    label={t('entities.projects.fields.networkDesign.state.label')}
                    {...params}
                    error={!!formErrors.networkDesign?.state}
                    helperText={
                      <Box component="span">
                        <Typography component="span" variant="body2">
                          {formErrors.networkDesign?.state?.message?.toString()}
                        </Typography>
                      </Box>
                    }
                    placeholder={t('common.pleaseChoose')}
                  />
                )}
              />
            )}
          />
        </FormControl>
        <FormControl disabled={!isFormEnabled}>
          <Controller
            control={control}
            defaultValue={currentFormat?.id}
            name={'networkDesign.format'}
            render={({ field: { onChange } }) => (
              <Autocomplete
                defaultValue={currentFormat}
                disabled={!isFormEnabled}
                fullWidth
                getOptionLabel={(p) => p.name}
                onChange={(_, value) => {
                  onChange(value?.id);
                }}
                options={networkFormats}
                renderInput={(params) => (
                  <TextField
                    label={t('entities.projects.fields.networkDesign.format.label')}
                    {...params}
                    error={!!formErrors.networkDesign?.format}
                    helperText={
                      <Box component="span">
                        <Typography component="span" variant="body2">
                          {formErrors.networkDesign?.format?.message?.toString()}
                        </Typography>
                      </Box>
                    }
                    placeholder={t('common.pleaseChoose')}
                  />
                )}
              />
            )}
          />
        </FormControl>
      </Stack>
    </Box>
  );
};
