import { Auth0Provider } from '@auth0/auth0-react';
import { LoadingScreen } from '@deepup/loading-screen';
import { ThemePaletteModeContext, ThemeProvider, useDeepUpTheme } from '@deepup/mui-theme-deepup';
import { CssBaseline, type Theme } from '@mui/material';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { SnackbarProvider } from 'notistack';
import { Suspense, useEffect, type FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { Auth } from '@components/Auth';
import { snackbarVariants } from '@components/DeepUpNotifications';
import { useEnvironment } from '@hooks/useEnvironment';

import { Router } from './Router';

export const App: FC = () => {
  const { auth0ClientId, auth0Domain, auth0Audience, flagsmithEnvId, flagsmithListeningInterval } =
    useEnvironment();
  const { themePaletteModeContextValue, theme } = useDeepUpTheme();

  useEffect(() => {
    if (!flagsmithListeningInterval) return;
    const interval = parseInt(flagsmithListeningInterval, 10);

    !isNaN(interval) && flagsmith.startListening(interval);
  }, [flagsmithListeningInterval]);

  const orgaManagementSpecificTheming: Theme = {
    ...theme,
    components: {
      ...theme.components,
      MuiTabs: {
        ...theme.components?.MuiTabs,
        styleOverrides: {
          ...theme.components?.MuiTabs?.styleOverrides,
          root: {
            ...(theme.components?.MuiTabs?.styleOverrides?.root as object),
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
        },
      },
      MuiButton: {
        ...theme.components?.MuiButton,
        defaultProps: {
          disableRipple: true,
          variant: 'contained',
          size: 'large',
        },
      },
    },
  };

  return (
    <FlagsmithProvider flagsmith={flagsmith} options={{ environmentID: flagsmithEnvId }}>
      <ThemePaletteModeContext.Provider value={themePaletteModeContextValue}>
        <ThemeProvider theme={orgaManagementSpecificTheming}>
          <CssBaseline />
          <SnackbarProvider Components={snackbarVariants} maxSnack={3}>
            <Auth0Provider
              authorizationParams={{
                redirect_uri: window.location.origin,
                audience: auth0Audience,
              }}
              clientId={auth0ClientId}
              domain={auth0Domain}
            >
              <Auth>
                <BrowserRouter>
                  <Suspense fallback={<LoadingScreen />}>
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                      <Router />
                    </QueryParamProvider>
                  </Suspense>
                </BrowserRouter>
              </Auth>
            </Auth0Provider>
          </SnackbarProvider>
        </ThemeProvider>
      </ThemePaletteModeContext.Provider>
    </FlagsmithProvider>
  );
};
