import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';

import { PlatformUserType, usePlatformPermissions } from '@hooks/usePlatformPermissions';
import { useFormGroupState } from '@pages/Groups/GroupsForm/useFormGroupState';

import { useGroupsUserApi } from './useGroupsUserApi';

export const useGroupsPermissionsApi = () => {
  const { user: currentUser } = useAuth0();
  const { group: currentGroup } = useFormGroupState();
  const {
    permissions: { platformUserType, organizationId: userOrgId },
  } = usePlatformPermissions();
  const usersOfGroupApi = useGroupsUserApi();

  const { id: groupId, organizationId: groupOrgId } = currentGroup ?? {};
  const { sub: userId } = currentUser || {};

  const [isGroupAdmin, setIsGroupAdmin] = useState<boolean>(false);

  useEffect(() => {
    const getIsUserGroupAdmin = async (userId: string, groupId: string): Promise<boolean> => {
      const users = await usersOfGroupApi(groupId);

      return !!users.find((user) => user.userId === userId && user.isGroupAdmin);
    };

    if (platformUserType === PlatformUserType.external || !groupId || !groupOrgId || !userId) {
      return setIsGroupAdmin(false);
    }
    if (platformUserType === PlatformUserType.platformAdmin) {
      return setIsGroupAdmin(true);
    }
    if (platformUserType === PlatformUserType.organizationAdmin && groupOrgId === userOrgId) {
      return setIsGroupAdmin(true);
    }

    getIsUserGroupAdmin(userId, groupId).then(setIsGroupAdmin);
  }, [groupId, groupOrgId, userId, userOrgId, platformUserType, usersOfGroupApi]);

  return {
    isGroupAdmin,
    group: currentGroup,
  };
};
