import { Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import type { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { Group } from '@hooks/api/groups';
import type { Organization } from '@hooks/api/organizations';
import { usePlatformPermissions } from '@hooks/usePlatformPermissions';

import { ProjectsFilterSelect } from './ProjectsFilterSelect';

interface ProjectsFilterBarProps {
  organizations: Organization[];
  groups: Group[];
  searchName?: string;
  networkOperatorId?: string;
  generalContractorId?: string;
  customerManager?: string;
  customerContactPerson?: string;
  contactPerson?: string;
  serviceProviderId?: string;
  networkDesignState?: string;
  networkDesignFormat?: string;
  activityState?: string;
  pipeDirection?: string;
  onChangeName: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeNetworkOperatorId: (newValue: string) => void;
  onChangeGeneralContractorId: (newValue: string) => void;
  onChangeCustomerManager: (newValue: string) => void;
  onChangeCustomerContactPerson: (newValue: string) => void;
  onChangeContactPerson: (newValue: string) => void;
  onChangeServiceProviderId: (newValue: string) => void;
  onChangeNetworkDesignState: (newValue: string) => void;
  onChangeNetworkDesignFormat: (newValue: string) => void;
  onChangeActivityState: (newValue: string) => void;
  onChangePipeDirection: (newValue: string) => void;
}

type NamedValue = {
  id: string;
  name: string;
};

export const ProjectsFilterBar: FC<ProjectsFilterBarProps> = ({
  organizations,
  groups,
  searchName,
  networkOperatorId,
  generalContractorId,
  customerManager,
  customerContactPerson,
  contactPerson,
  serviceProviderId,
  networkDesignState,
  networkDesignFormat,
  activityState,
  pipeDirection,
  onChangeName,
  onChangeNetworkOperatorId,
  onChangeGeneralContractorId,
  onChangeCustomerManager,
  onChangeCustomerContactPerson,
  onChangeContactPerson,
  onChangeServiceProviderId,
  onChangeNetworkDesignState,
  onChangeNetworkDesignFormat,
  onChangeActivityState,
  onChangePipeDirection,
}) => {
  const { t } = useTranslation();
  const { isPlatformAdmin } = usePlatformPermissions();

  const networkDesignStates = [
    { id: 'AVAILABLE', name: t('entities.projects.fields.networkDesign.state.states.available') },
    { id: 'INCOMPLETE', name: t('entities.projects.fields.networkDesign.state.states.incomplete') },
    { id: 'PENDING', name: t('entities.projects.fields.networkDesign.state.states.pending') },
    { id: 'UNKNOWN', name: t('entities.projects.fields.networkDesign.state.states.unknown') },
  ];

  const networkDesignFormats = [
    { id: 'PDF', name: 'PDF' },
    { id: 'GEOPACKAGE', name: 'Geopackage' },
    { id: 'DXF', name: 'DXF' },
  ];

  const activityStates = [
    { id: 'NOT_STARTED', name: t('entities.projects.fields.activityState.states.notStarted') },
    { id: 'PAUSED', name: t('entities.projects.fields.activityState.states.paused') },
    { id: 'ACTIVE', name: t('entities.projects.fields.activityState.states.active') },
    { id: 'FINISHED', name: t('entities.projects.fields.activityState.states.finished') },
    { id: 'CLOSED', name: t('entities.projects.fields.activityState.states.closed') },
    { id: 'UNKNOWN', name: t('entities.projects.fields.activityState.states.unknown') },
  ];

  const pipeDirections = [
    { id: 'true', name: t('entities.projects.fields.pipeDirection.states.enabled') },
    { id: 'false', name: t('entities.projects.fields.pipeDirection.states.disabled') },
  ];

  return (
    <Stack gap={2} mb={4}>
      <Box display="flex" gap={2}>
        <Box flexBasis="50%">
          <TextField
            data-testid="search-field"
            fullWidth
            onChange={onChangeName}
            placeholder={t('pages.projects.overview.components.filter.name')}
            type="search"
            value={searchName}
          />
        </Box>
        <Box flex={1}>
          <ProjectsFilterSelect<Organization>
            defaultLabel={t('pages.projects.overview.components.filter.networkProvider')}
            items={organizations}
            onChange={onChangeNetworkOperatorId}
            value={organizations.find((org) => org.id === networkOperatorId) || null}
          />
        </Box>
        <Box flex={1}>
          <ProjectsFilterSelect<Organization>
            defaultLabel={t('pages.projects.overview.components.filter.generalContractor')}
            items={organizations}
            onChange={onChangeGeneralContractorId}
            value={organizations.find((org) => org.id === generalContractorId) || null}
          />
        </Box>
      </Box>
      {isPlatformAdmin && (
        <Stack gap={2}>
          <Typography variant="h6">
            {t('pages.projects.overview.components.filter.internalFilters')}
          </Typography>
          <Stack direction="row" gap={2}>
            <TextField
              data-testid="customer-contact-person"
              onChange={(e) => onChangeCustomerContactPerson(e.currentTarget.value)}
              placeholder={t('entities.projects.fields.customerContactPerson.label')}
              style={{ flex: 1 }}
              type="search"
              value={customerContactPerson}
            />
            <TextField
              data-testid="contact-person"
              onChange={(e) => onChangeContactPerson(e.currentTarget.value)}
              placeholder={t('entities.projects.fields.contactPerson.label')}
              style={{ flex: 1 }}
              type="search"
              value={contactPerson}
            />
            <TextField
              data-testid="customer-manager"
              onChange={(e) => onChangeCustomerManager(e.currentTarget.value)}
              placeholder={t('pages.projects.overview.components.filter.customerManager')}
              style={{ flex: 1 }}
              type="search"
              value={customerManager}
            />
            <ProjectsFilterSelect<Group>
              defaultLabel={t('entities.projects.fields.serviceProviders.label')}
              items={groups}
              onChange={onChangeServiceProviderId}
              value={groups.find((group) => group.id === serviceProviderId) || null}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <ProjectsFilterSelect<NamedValue>
              defaultLabel={t('pages.projects.overview.components.filter.networkDesignState')}
              items={networkDesignStates}
              onChange={onChangeNetworkDesignState}
              value={networkDesignStates.find(({ id }) => id === networkDesignState) || null}
            />
            <ProjectsFilterSelect<NamedValue>
              defaultLabel={t('pages.projects.overview.components.filter.networkDesignFormat')}
              items={networkDesignFormats}
              onChange={onChangeNetworkDesignFormat}
              value={networkDesignFormats.find(({ id }) => id === networkDesignFormat) || null}
            />
            <ProjectsFilterSelect<NamedValue>
              defaultLabel={t('pages.projects.overview.components.filter.activityState')}
              items={activityStates}
              onChange={onChangeActivityState}
              value={activityStates.find(({ id }) => id === activityState) || null}
            />
            <ProjectsFilterSelect<NamedValue>
              defaultLabel={t('entities.projects.fields.pipeDirection.label')}
              items={pipeDirections}
              onChange={onChangePipeDirection}
              value={pipeDirections.find(({ id }) => id === pipeDirection) || null}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
