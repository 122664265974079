import { CircleExclamationMarkFilled, ChevronsUp, ChevronsDown } from '@deepup/icons';
import { Alert, AlertTitle, Stack, Typography, useTheme } from '@mui/material';
import { useState, type ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export interface ProjectMappingError {
  title: string;
  message: ReactNode;
  details?: ReactNode;
}

interface ProjectMappingErrorAlertProps {
  error?: ProjectMappingError;
}

export const ProjectMappingErrorAlert = ({ error }: ProjectMappingErrorAlertProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [showErrorDetails, setShowErrorDetails] = useState<boolean>(false);
  const iconSize = theme.spacing(3);

  useEffect(() => {
    setShowErrorDetails(false);
  }, [error]);

  if (!error) return <></>;

  return (
    <Alert
      iconMapping={{
        error: <CircleExclamationMarkFilled />,
      }}
      severity="error"
    >
      <AlertTitle sx={{ fontWeight: theme.typography.fontWeightBold }}>{error.title}</AlertTitle>
      <Stack gap={1}>
        <Typography fontWeight={theme.typography.fontWeightBold} variant="body2">
          {error.message}
        </Typography>
        {error.details && (
          <>
            <Stack
              alignItems="center"
              direction="row"
              gap={0.5}
              onClick={() => setShowErrorDetails((prevState) => !prevState)}
              sx={{ cursor: 'pointer' }}
            >
              <Typography fontWeight={theme.typography.fontWeightBold} variant="body2">
                {t('pages.projects.create.uploadApiExport.btnToggleErrorDetails')}
              </Typography>
              {showErrorDetails ? (
                <ChevronsUp height={iconSize} width={iconSize} />
              ) : (
                <ChevronsDown height={iconSize} width={iconSize} />
              )}
            </Stack>
            {showErrorDetails && <Typography>{error.details}</Typography>}
          </>
        )}
      </Stack>
    </Alert>
  );
};
