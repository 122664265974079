import type { FieldValues } from 'react-hook-form';

import { trimFields } from './trimFields';

const mapEmptyStringToNull = (values: FieldValues): FieldValues => {
  const newValues: FieldValues = { ...values };

  Object.entries(newValues).forEach(([key, value]) => {
    if (value === '') newValues[key] = null;
    if (typeof value === 'object' && value !== null) {
      newValues[key] = mapEmptyStringToNull(value);
    }
  });

  return newValues;
};

export const buildBody = (data: FieldValues): string => {
  const trimmedData = trimFields(data);
  const newData = mapEmptyStringToNull(trimmedData);

  return JSON.stringify(newData);
};
