import type { FC } from 'react';

import type { UserPermission } from '@hooks/api/groups';

import { UsersInGroupTable } from './UsersInGroupTable';

interface UsersInGroupTableContainerProps {
  selectedUsers: UserPermission[];
  onChangeUserPermissionConfiguration: (userInGroupConfiguration: UserPermission[]) => void;
  isLoading: boolean;
}

export const UsersInGroupTableContainer: FC<UsersInGroupTableContainerProps> = ({
  selectedUsers,
  onChangeUserPermissionConfiguration,
  isLoading,
}) => {
  const sortedSelectedUser = selectedUsers.sort((a, b) =>
    a.user?.name < b.user?.name ? -1 : a.user?.name > b.user?.name ? 1 : 0,
  );

  const handleDeleteUser = (userId: string) => {
    const allUsersWithoutRequestedOne = sortedSelectedUser.filter(
      (selectedUser) => selectedUser.user.id !== userId,
    );

    onChangeUserPermissionConfiguration(allUsersWithoutRequestedOne);
  };

  const handleDeleteAllUsers = () => {
    onChangeUserPermissionConfiguration([]);
  };

  const handleChangeUserPermission = (
    entity: UserPermission,
    permission: 'isGroupAdmin',
    value: boolean,
  ) => {
    const updatedListOfUser = selectedUsers.map((selectedUser: UserPermission) => {
      if (selectedUser.user?.id === entity.user?.id)
        return {
          ...selectedUser,
          [permission]: value,
        };

      return selectedUser;
    });

    onChangeUserPermissionConfiguration(updatedListOfUser);
  };

  return (
    <UsersInGroupTable
      entities={sortedSelectedUser}
      isLoading={isLoading}
      onChangeUserPermission={handleChangeUserPermission}
      onDeleteAllUsers={handleDeleteAllUsers}
      onDeleteUser={handleDeleteUser}
    />
  );
};
