import { List } from '@deepup/icons';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { ProjectMappingApiExport } from '@hooks/api/projects';

import { ApiMappingContentBox } from './ApiMappingContentBox';

interface LatestUploadedDataProps {
  apiExportMapping?: ProjectMappingApiExport;
  onDelete: () => void;
}

export const LatestUploadedData = ({ apiExportMapping, onDelete }: LatestUploadedDataProps) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  if (!apiExportMapping) return <></>;

  const { projectExportMapping } = apiExportMapping;

  const countRows = projectExportMapping.length;
  const hasRows = countRows > 0;

  const uploadDateObject = new Date(projectExportMapping?.[0]?.createdDate);
  const uploadDate = uploadDateObject?.toLocaleDateString(i18n.language);
  const uploadTime = uploadDateObject?.toLocaleTimeString(i18n.language);

  const contentInfo = hasRows
    ? t('pages.projects.create.uploadApiExport.lblUploadDate', {
        uploadDate,
        uploadTime,
      })
    : '';
  const onDeleteCallback = hasRows ? onDelete : undefined;
  const iconColor = hasRows ? theme.palette.primary.main : theme.palette.secondary.main;

  return (
    <ApiMappingContentBox
      content={t('pages.projects.create.uploadApiExport.lblDataRows', {
        count: countRows,
      })}
      contentInfo={contentInfo}
      icon={<List fill={iconColor} height={theme.spacing(6)} width={theme.spacing(6)} />}
      onDelete={onDeleteCallback}
      title={t('pages.projects.create.uploadApiExport.lblLatestUpload')}
    />
  );
};
