import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useProjectsApi } from '@hooks/api/projects';

export const useFormProjectState = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const { data: projectsData } = useProjectsApi();

  const project = useMemo(() => {
    if (state) return state?.project;

    return projectsData?.content.find((project) => project.id === id);
  }, [state, projectsData?.content, id]);

  return {
    project,
  };
};
