import { GlobalHeader } from '@deepup/global-header';
import { CheckmarkCircleOutline } from '@deepup/icons';
import { useDeepUpTheme } from '@deepup/mui-theme-deepup';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useEnvironment } from '@hooks/useEnvironment';

export const UserAcceptedInvitation: FC = () => {
  const { cssUrl, cockpitUrl } = useEnvironment();

  const [searchParams] = useSearchParams();
  const organizationName = searchParams.get('organization'),
    hasDocumentation = searchParams.get('hasDocumentation') === 'true',
    hasMonitoring = searchParams.get('hasMonitoring') === 'true';

  const openExternalLink = (url: string) => () => {
    window.open(url, '_blank');
  };
  const { theme } = useDeepUpTheme();
  const { t, i18n } = useTranslation();

  return (
    <Box>
      <GlobalHeader
        language={i18n.language as 'de' | 'en' | undefined}
        languageSelectors={[{ key: 'de' }, { key: 'en' }]}
        onChangeLanguage={(selectedLanguage) => {
          i18n.changeLanguage(selectedLanguage);
        }}
        showUserMenu={false}
        title="Organization Management"
      />
      <Box display="flex" justifyContent="center" paddingTop={20}>
        <Box sx={(theme) => ({ width: theme.spacing(80) })}>
          <Box>
            <Box alignItems="center" display="flex" flexDirection="column">
              <Typography align="center" fontWeight="bold" variant="h4">
                {t('pages.acceptedInvitation.title', { organizationName })}
              </Typography>
              <Box marginTop={8} />
              <Box alignItems="center" display="flex" justifyContent="center">
                <CheckmarkCircleOutline fill={theme.palette.primary.main} />
              </Box>
              <Box marginTop={8} />
              <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
                {hasDocumentation && (
                  <Button onClick={openExternalLink(cssUrl)} variant="contained">
                    {t('pages.acceptedInvitation.components.btnHasDocumentation')}
                  </Button>
                )}
                {hasMonitoring && (
                  <Button onClick={openExternalLink(cockpitUrl)} variant="contained">
                    {t('pages.acceptedInvitation.components.btnHasMonitoring')}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
