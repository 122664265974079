import { ApiOutline, DocumentTextOutline } from '@deepup/icons';
import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { useProjectApiAccessApi } from '@hooks/api/projects/useProjectApiAccessApi';
import { useRenderConfig } from '@hooks/ui';
import { useFormProjectState } from '@pages/Projects/useFormProjectState';
import { DetailPageLayout } from '@pages/common';

import type { ProjectOutletContext } from '../types';

export const ProjectsEdit: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const { data: apiAccessesData } = useProjectApiAccessApi(id);
  const { project } = useFormProjectState();
  const { isItemVisible } = useRenderConfig();
  const { handleOnChangeApiExportMappingFile, uploadApiExportData } =
    useOutletContext<ProjectOutletContext>();

  const EDIT_STEPS = [
    {
      pathname: 'overview',
      label: t('common.overview'),
      icon: DocumentTextOutline,
      hidden: false,
    },
    {
      pathname: 'api-accesses',
      label: t('pages.apiAccesses.overview.title'),
      icon: ApiOutline,
      hidden: !isItemVisible('projects.edit.components.tabApiAccesses'),
    },
  ];

  if (!id || !project) {
    return <Box>No project given</Box>;
  }

  const tabIndex = EDIT_STEPS.findIndex((step) => pathname.includes(step.pathname));

  const handleClickTab = (stepIndex: number) => () => {
    navigate(`/projects/${id}/${EDIT_STEPS[stepIndex].pathname}`, {
      state: { project },
    });
  };

  return (
    <DetailPageLayout title={project.name}>
      <Box>
        <Tabs aria-label="project-edit-tabs" onChange={() => ({})} value={tabIndex}>
          {EDIT_STEPS.map((stepData, index) => {
            const IconComponent = stepData.icon;
            let label = stepData.label;

            if (stepData.pathname === 'api-accesses')
              label = `${stepData.label} (${apiAccessesData?.length || 0})`;
            if (stepData.hidden) return null;

            return (
              <Tab
                aria-controls={`projects-edit-tabpanel-${index}`}
                icon={<IconComponent height={24} width={24} />}
                iconPosition="start"
                id={`projects-edit-tab-${index}`}
                key={stepData.pathname}
                label={label}
                onClick={handleClickTab(index)}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box my={2} />
      <Outlet context={{ handleOnChangeApiExportMappingFile, uploadApiExportData }} />
    </DetailPageLayout>
  );
};
