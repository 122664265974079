import { Autocomplete, Box, Skeleton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { Organization } from '@hooks/api/organizations';

interface SelectUserOrganizationAutocompleteProps {
  isLoading: boolean;
  isVisible: boolean;
  onChangeOrganization: (
    event: React.SyntheticEvent<Element, Event>,
    value?: { id: string; name: string } | null,
  ) => void;
  options: Organization[];
}

export const SelectUserOrganizationAutocomplete = ({
  isLoading,
  isVisible,
  options,
  onChangeOrganization,
}: SelectUserOrganizationAutocompleteProps) => {
  const { t } = useTranslation();

  if (isLoading)
    return (
      <Box flexBasis="25%">
        <Skeleton height={32} />
      </Box>
    );

  if (!isVisible) return <></>;

  return (
    <Box flexBasis="25%">
      <Autocomplete
        getOptionLabel={(p) => p.name}
        onChange={onChangeOrganization}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('pages.user.overview.components.filter.organization')}
          />
        )}
      />
    </Box>
  );
};
