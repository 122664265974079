import { useCallback } from 'react';

import type { Project } from '@hooks/api/projects/types';
import { useJsonFetch } from '@hooks/api/useJsonFetch';
import { buildBody } from '@hooks/api/utils';

export const useProjectUpdateApi = () => {
  const jsonFetch = useJsonFetch<Project>();

  const fetcher = useCallback(
    ({ id, ...formData }: Partial<Project>) =>
      jsonFetch(`/projects/${id}`, {
        method: 'PATCH',

        body: buildBody(formData),
      }),
    [jsonFetch],
  );

  return fetcher;
};
