import { object, string } from 'yup';

import { defaultSelectItem } from '@pages/common/constants';

export const schemaCreateStepOverview = ({
  error,
}: {
  error: {
    clientId: {
      required: string;
      pattern: string;
    };
    organizationId: {
      required: string;
    };
  };
}) =>
  object({
    organizationId: string()
      .notOneOf([defaultSelectItem.id], error.organizationId.required)
      .required(error.organizationId.required),
    clientId: string()
      .required(error.clientId.required)
      .matches(new RegExp('^([a-zA-Z0-9_|\\-=+]+)$'), error.clientId.pattern),
  });
