import { Box, Typography } from '@mui/material';
import { t } from 'i18next';

export const ToC = () => {
  return (
    <Box my={4}>
      <Typography variant="h5">{t('pages.privacyPolicy.common.toc')}</Typography>
      <Box my={1} />
      <ul>
        <li>
          <Typography>{t('pages.privacyPolicy.sections.preamble.title')}</Typography>
        </li>
        <li>
          <Typography>{t('pages.privacyPolicy.sections.controller.title')}</Typography>
        </li>
        <li>
          <Typography>{t('pages.privacyPolicy.sections.overviewProcessing.title')}</Typography>
        </li>
        <li>
          <Typography>{t('pages.privacyPolicy.sections.legalBasesProcessing.title')}</Typography>
        </li>
        <li>
          <Typography>{t('pages.privacyPolicy.sections.securityPrecautions.title')}</Typography>
        </li>
        <li>
          <Typography>
            {t('pages.privacyPolicy.sections.transmissionsPersonalData.title')}
          </Typography>
        </li>
        <li>
          <Typography>{t('pages.privacyPolicy.sections.cookies.title')}</Typography>
        </li>
        <li>
          <Typography>{t('pages.privacyPolicy.sections.businessServices.title')}</Typography>
        </li>
        <li>
          <Typography>{t('pages.privacyPolicy.sections.webHosting.title')}</Typography>
        </li>
        <li>
          <Typography>
            {t('pages.privacyPolicy.sections.specialNotesApplications.title')}
          </Typography>
        </li>
        <li>
          <Typography>{t('pages.privacyPolicy.sections.registration.title')}</Typography>
        </li>
        <li>
          <Typography>{t('pages.privacyPolicy.sections.singleSignOn.title')}</Typography>
        </li>
        <li>
          <Typography>
            {t('pages.privacyPolicy.sections.electronicCommunications.title')}
          </Typography>
        </li>
        <li>
          <Typography>{t('pages.privacyPolicy.sections.monitoring.title')}</Typography>
        </li>
        <li>
          <Typography>{t('pages.privacyPolicy.sections.plugins.title')}</Typography>
        </li>
        <li>
          <Typography>{t('pages.privacyPolicy.sections.rightsDataSubjects.title')}</Typography>
        </li>
        <li>
          <Typography>{t('pages.privacyPolicy.sections.definitions.title')}</Typography>
        </li>
      </ul>
    </Box>
  );
};
