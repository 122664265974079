import { Alert, Box, FormControlLabel, Switch } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FormSectionHeader } from '@components/FormElements';
import type { Project } from '@hooks/api/projects';

import { ProjectsOfApiAccessTable } from './ProjectsOfApiAccessTable';

export interface ProjectsOfApiAccessTableContainerProps {
  entities: Project[];
  onChangeProjects: (updatedProjectsofApiAccess: Project[]) => void;
  selectedProjects: Project[];
  isLoading: boolean;
  hasAssignedAllProjects: boolean;
  setHasAssignedAllProjects: (hasAssignedAllProjects: boolean) => void;
}

export const ProjectsOfApiAccessTableContainer: FC<ProjectsOfApiAccessTableContainerProps> = ({
  onChangeProjects,
  entities,
  selectedProjects,
  isLoading,
  hasAssignedAllProjects,
  setHasAssignedAllProjects,
}) => {
  const { t } = useTranslation();

  const handleDeleteProject = (projectId: string) => {
    const allProjectsWithoutRequestedOne = selectedProjects.filter(
      (project) => project.id !== projectId,
    );

    onChangeProjects(allProjectsWithoutRequestedOne);
  };

  const handleDeleteAllProjects = () => {
    onChangeProjects([]);
  };

  return (
    <>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <FormSectionHeader
          id="projects-of-api-access-section"
          title={t('pages.apiAccesses.form.projects.sections.projectsOfApiAccess')}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasAssignedAllProjects}
              onChange={(event) => setHasAssignedAllProjects(event.target.checked)}
            />
          }
          label={t('pages.apiAccesses.form.projects.components.switchAssignToAll')}
          labelPlacement="start"
        />
      </Box>
      {hasAssignedAllProjects && (
        <Alert severity="info">
          {t('pages.apiAccesses.form.projects.components.alertIsAssignedToAll')}
        </Alert>
      )}
      {!hasAssignedAllProjects && (
        <ProjectsOfApiAccessTable
          entities={entities}
          isLoading={isLoading}
          onDeleteAllProjects={handleDeleteAllProjects}
          onDeleteProject={handleDeleteProject}
        />
      )}
    </>
  );
};
