import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ValidatingScreenProps {
  uploadedFile?: File;
}

export const ValidatingScreen = ({ uploadedFile }: ValidatingScreenProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack
      alignItems="center"
      gap={2}
      justifyContent="center"
      mb={4}
      sx={{
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.action.disabledBackground,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <CircularProgress size={theme.spacing(6)} />
      <Stack alignItems="center" gap={1}>
        <Typography variant="body1">{uploadedFile?.name}</Typography>
        <Typography color="text.disabled" variant="body2">
          {t('pages.projects.create.uploadApiExport.validatingPlaceholder')}
        </Typography>
      </Stack>
    </Stack>
  );
};
