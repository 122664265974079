import { Autocomplete, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { type FC, type SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EmptyTableInfo } from '@components/PageEntityTable/EmptyTableInfo';
import { useGroupsPaginatedApi } from '@hooks/api/groups/useGroupsPaginatedApi';
import { useGroupsUrlSync } from '@hooks/api/groups/useGroupsUrlSync';
import { useOrganizationsPaginatedApi } from '@hooks/api/organizations';
import { useOrganizationsApiTotalCount } from '@hooks/api/organizations/useOrganizationsApiTotalCount';
import useDebounce from '@hooks/api/useDebounce';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { OverviewPageLayout } from '@pages/common';
import {
  filterGroupsByDeepUpOrganizations,
  filterGroupsByOrganizationId,
  filterGroupsWithOrgByName,
  getGroupsWithOrganization,
} from '@utils/groups';
import { filterOrganizationsByDeepUpOrg } from '@utils/organizations';

import { GroupsTable } from './GroupsTable';

interface GroupsOverviewProps {
  isDeepUpInternal?: boolean;
}

export const GroupsOverview: FC<GroupsOverviewProps> = ({ isDeepUpInternal = false }) => {
  const { t } = useTranslation();
  const { isItemVisible } = useRenderConfig();
  const navigate = useNavigate();

  const {
    page,
    size,
    sortModel,
    name,
    organizationId,
    handlePageChange,
    handleSizeChange,
    handleSortModelChange,
    handleNameChange,
    handleOrganizationChange,
  } = useGroupsUrlSync();

  const debouncedName = useDebounce(name);
  const totalCountOrganizations = useOrganizationsApiTotalCount();

  const {
    data: organizationsData,
    error: organizationsError,
    isValidating: isValidatingOrganizations,
  } = useOrganizationsPaginatedApi({
    page: 0,
    size: totalCountOrganizations || 0,
  });

  const {
    data: groupsData,
    isValidating: isValidatingGroups,
    error: groupsError,
  } = useGroupsPaginatedApi({
    page,
    size,
    sortModel,
    name: debouncedName,
    organizationId: organizationId,
    isDeepupOrg: isDeepUpInternal,
  });

  const isLoadingData =
    isValidatingOrganizations || isValidatingGroups || !groupsData || !organizationsData;
  const hasError = groupsError || organizationsError;
  const hasCreateButton = isItemVisible('groups.overview.components.btnCreate');

  const shownOrganizations = filterOrganizationsByDeepUpOrg(
    organizationsData?.content ?? [],
    isDeepUpInternal ? 'include' : 'exclude',
  );

  const groupsWithOrganization = getGroupsWithOrganization(
    groupsData?.content,
    organizationsData?.content,
  );

  const filteredGroupsByName = filterGroupsWithOrgByName(groupsWithOrganization, name);

  const filteredGroupsOfDeepUpOrganizations = filterGroupsByDeepUpOrganizations(
    filteredGroupsByName,
    isDeepUpInternal ? 'include' : 'exclude',
  );

  const filteredGroupsByOrganizationId =
    organizationId.length > 0
      ? filterGroupsByOrganizationId(filteredGroupsOfDeepUpOrganizations, organizationId)
      : filteredGroupsOfDeepUpOrganizations;

  const handleChangeSelectOrganization = (
    _: SyntheticEvent<Element, Event>,
    value: { id: string; name: string } | null,
  ) => {
    handleOrganizationChange(value?.id ?? '');
  };
  const handleRowClick = (groupsId: string) => {
    const groupOfRow = filteredGroupsByOrganizationId.find((group) => group.id === groupsId);

    navigate(`/${isDeepUpInternal ? 'deepup-groups' : 'groups'}/${groupsId}/user`, {
      state: { group: groupOfRow },
    });
  };

  const pageTitle = isDeepUpInternal
    ? t('pages.groupsPermissions.deepup.title')
    : t('pages.groupsPermissions.overview.title');
  const rowCount = groupsData?.totalElements || 0;

  if (hasError) return <div>failed to load</div>;

  return (
    <OverviewPageLayout
      createRoute={
        hasCreateButton ? `/${isDeepUpInternal ? 'deepup-groups' : 'groups'}/create/overview` : ''
      }
      createTitle={t('pages.groupsPermissions.overview.components.btnCreate')}
      title={pageTitle}
    >
      <Box display="flex" gap={2} mb={4}>
        <Box flexBasis="50%">
          <TextField
            fullWidth
            onChange={(event) => {
              const searchString = event.currentTarget.value;

              handleNameChange(searchString);
            }}
            placeholder={t('pages.groupsPermissions.overview.components.filter.name')}
            type="search"
            value={name}
          />
        </Box>
        <Box flexBasis="25%">
          <Autocomplete
            getOptionLabel={(p) => p.name}
            onChange={handleChangeSelectOrganization}
            options={shownOrganizations}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('pages.groupsPermissions.overview.components.filter.organization')}
              />
            )}
            value={organizationsData?.content.find((org) => org.id === organizationId) || null}
          />
        </Box>
      </Box>
      {(isLoadingData || filteredGroupsByOrganizationId.length > 0) && (
        <GroupsTable
          groups={filteredGroupsByOrganizationId}
          isDeepUpInternal={isDeepUpInternal}
          isLoading={isValidatingGroups}
          name={debouncedName}
          onEdit={handleRowClick}
          onPageChange={handlePageChange}
          onSizeChange={handleSizeChange}
          onSortModelChange={handleSortModelChange}
          organizationId={organizationId}
          page={page}
          rowCount={rowCount}
          size={size}
          sortModel={sortModel}
        />
      )}
      {!isLoadingData && filteredGroupsByOrganizationId.length <= 0 && (
        <EmptyTableInfo info={t('pages.groupsPermissions.overview.components.table.emptyInfo')} />
      )}
    </OverviewPageLayout>
  );
};
