import { useCallback } from 'react';

import { useJsonFetch } from '@hooks/api/useJsonFetch';
import type { User } from '@hooks/api/users';

import type { OrganizationUserPermission } from './types';

export const useUserProjectPermissions = () => {
  const jsonFetch = useJsonFetch<OrganizationUserPermission[]>();

  const fetcher = useCallback(
    ({ id, connection }: Partial<User>) =>
      jsonFetch(`/users/${id}/project-permissions?connection=${connection}`, {
        method: 'GET',
      }),
    [jsonFetch],
  );

  return fetcher;
};
