import { useCallback } from 'react';
import type { FieldValues } from 'react-hook-form';

import { useJsonFetch } from '@hooks/api/useJsonFetch';
import type { User } from '@hooks/api/users';
import { buildBody } from '@hooks/api/utils';

import type { UserType } from './types';

export const useUserCreateApi = () => {
  const jsonFetch = useJsonFetch<User>();

  const fetcher = useCallback(
    (newUser: FieldValues, type: UserType = 'internal') => {
      const apiRoutes = {
        internal: '/users',
        external: '/users/external',
        deepup: '/users/deepup',
      };

      return jsonFetch(apiRoutes[type], {
        method: 'POST',

        body: buildBody(newUser),
      });
    },
    [jsonFetch],
  );

  return fetcher;
};
