import Box from '@mui/material/Box';
import { useCallback, useEffect, useMemo, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { BottomFormNavigation } from '@components/BottomFormNavigation/BottomFormNavigation';
import {
  useApiAccessCreateApi,
  useApiAccessProjectsApi,
  useApiAccessProjectsUpdateApi,
} from '@hooks/api/apiAccesses';
import type { FetchError } from '@hooks/api/customErrors';
import { useProjectsApi, type Project } from '@hooks/api/projects';
import { useHandleFetchError } from '@hooks/api/useHandleFetchError';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { AllProjectsForApiAccessTableContainer } from '@pages/ApiAccesses';

import type { OutletContext } from '../types';
import { useFormApiAccessesState } from '../useFormApiAccessesState';

import { ProjectsOfApiAccessTableContainer } from './ProjectsOfApiAccessTable';

interface ApiAccessesFormProjectsProps {
  isCreate?: boolean;
}

export const ApiAccessesFormProjects: FC<ApiAccessesFormProjectsProps> = ({ isCreate = false }) => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();
  const { data: projectsData, error: projectsError } = useProjectsApi();
  const { id } = useParams();
  const { data: apiAccessProjectsData } = useApiAccessProjectsApi(id);
  const createApiAccess = useApiAccessCreateApi();
  const updateApiAccessProjects = useApiAccessProjectsUpdateApi();
  const { handleGoBack, handleGoNext } = useOutletContext<OutletContext>();
  const { apiAccess } = useFormApiAccessesState();
  const [hasAssignedAllProjects, setHasAssignedAllProjects] = useState(
    Boolean(apiAccessProjectsData?.isOrganizationWide),
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);
  const [error, setError] = useState<string>();
  const handleFetchError = useHandleFetchError();

  useEffect(() => {
    setHasAssignedAllProjects(Boolean(apiAccessProjectsData?.isOrganizationWide));
  }, [apiAccessProjectsData?.isOrganizationWide]);

  const handleSubmitAndFinish = async () => {
    if (selectedProjects.length === 0 && !hasAssignedAllProjects) {
      setError(t('pages.apiAccesses.create.messages.errorAddOneProject'));

      return;
    }

    if (!apiAccess) return;

    if (isCreate) {
      try {
        await createApiAccess(apiAccess);
        await updateApiAccessProjects({
          id: apiAccess.clientId,
          isOrganizationWide: hasAssignedAllProjects,
          projects: selectedProjects,
        });
        revalidateCacheForEntity('/api-accesses');

        showMessage({
          message: t('pages.apiAccesses.create.messages.successCreateApiAccess'),
          type: 'success',
        });

        handleGoNext(apiAccess);
      } catch (error) {
        handleFetchError(
          error as FetchError,
          'apiAccesses',
          'pages.apiAccesses.create.messages.errorCreateApiAccess',
        );
      }
    } else {
      try {
        const response = await updateApiAccessProjects({
          id: apiAccess.clientId,
          isOrganizationWide: hasAssignedAllProjects,
          projects: selectedProjects,
        });

        showMessage({
          message: t('pages.apiAccesses.edit.messages.successUpdateProjects'),
          type: 'success',
        });

        setSelectedProjects(response.projectIds as Project[]);
      } catch (error) {
        handleFetchError(
          error as FetchError,
          'apiAccesses',
          'pages.apiAccesses.create.messages.errorSaveProjects',
        );
      }
    }
  };

  const handleProjectsUpdate = (updatedProjectsList: Project[]) => {
    setSelectedProjects(updatedProjectsList);
  };

  const handleReset = () => {
    if (apiAccess) mutate(`/api-access/${apiAccess?.clientId}/projects`);
    initProjectsOfApiAccess();
  };

  const initProjectsOfApiAccess = useCallback(async () => {
    if (isCreate) {
      setSelectedProjects([]);
    } else if (apiAccess?.clientId && apiAccessProjectsData) {
      const sortedProjects = apiAccessProjectsData.projectIds.sort((a, b) =>
        a?.name < b?.name ? -1 : a?.name > b?.name ? 1 : 0,
      );

      setSelectedProjects(sortedProjects);
    }

    setIsLoading(false);
  }, [apiAccess?.clientId, apiAccessProjectsData, isCreate]);

  const selectableProjects = useMemo(() => {
    if (!projectsData?.content) return [];

    return projectsData?.content.filter(
      (project) => project.organizationId === apiAccess?.organizationId,
    );
  }, [projectsData?.content, apiAccess]);

  useEffect(() => {
    setError(undefined);
  }, [selectedProjects]);

  useEffect(() => {
    if (error) {
      showMessage({ message: error, type: 'error' });
      setError(undefined);
    }
  }, [error, showMessage]);

  useEffect(() => {
    initProjectsOfApiAccess();
  }, [id, apiAccess, apiAccessProjectsData, initProjectsOfApiAccess]);

  if (!projectsData) return <Box>{t('common.loading')}</Box>;
  if (projectsError) return <Box>{t('common.failedLoading')}</Box>;

  return (
    <Box display="flex" flexDirection="column">
      <Box marginBottom={4}>
        <ProjectsOfApiAccessTableContainer
          entities={selectedProjects}
          hasAssignedAllProjects={hasAssignedAllProjects}
          isLoading={isLoading}
          onChangeProjects={handleProjectsUpdate}
          selectedProjects={selectedProjects}
          setHasAssignedAllProjects={setHasAssignedAllProjects}
        />
      </Box>
      {!hasAssignedAllProjects && (
        <Box marginBottom={4}>
          <AllProjectsForApiAccessTableContainer
            isLoading={isLoading}
            onAddProject={handleProjectsUpdate}
            projects={selectableProjects}
            selectedProjects={selectedProjects}
          />
        </Box>
      )}
      <BottomFormNavigation
        backTitle={isCreate ? t('common.goBack') : t('common.reset')}
        nextTitle={isCreate ? `${t('common.save')} & ${t('common.finish')}` : t('common.save')}
        onGoBack={isCreate ? handleGoBack : handleReset}
        onGoNext={handleSubmitAndFinish}
      />
    </Box>
  );
};
