import { type Theme } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useRenderConfig } from '@hooks/ui';

import { NavigationSidebarItem } from './NavigationSidebarItem';
import { getNavigationItems } from './config';

export const NavigationSidebar = () => {
  const { t } = useTranslation();
  const { isRouteEnabled } = useRenderConfig();
  const { pathname } = useLocation();
  const navigationSidebarItems = getNavigationItems(t, pathname);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      marginX={4}
      minWidth={(theme: Theme) => theme.spacing(32)}
      paddingBottom={2}
      paddingTop={14}
      position="fixed"
    >
      <List>
        {navigationSidebarItems
          .filter(({ sidebarItem }) => isRouteEnabled(sidebarItem))
          .map(({ label, icon, to, disabled, selected, sidebarItem, iconSelected }) => (
            <NavigationSidebarItem
              data-testid={`sidebar-item-${sidebarItem}`}
              disabled={disabled}
              icon={icon}
              iconSelected={iconSelected}
              key={`${label}_${to}`}
              label={label}
              selected={selected}
              to={to}
            />
          ))}
      </List>
    </Box>
  );
};
