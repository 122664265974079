import { Box, Skeleton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SearchUserInputProps {
  isLoading: boolean;
  onChangeSearchText: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchUserInput = ({ isLoading, onChangeSearchText }: SearchUserInputProps) => {
  const { t } = useTranslation();

  if (isLoading)
    return (
      <Box flexBasis="50%">
        <Skeleton height={32} />
      </Box>
    );

  return (
    <Box flexBasis="50%">
      <TextField
        fullWidth
        onChange={onChangeSearchText}
        placeholder={t('pages.user.overview.components.filter.name')}
        type="search"
      />
    </Box>
  );
};
