import { LockClosedOutline, MailOutline, ShieldPersonOutline } from '@deepup/icons';
import { Divider, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ContextMenu,
  ContextMenuDeleteItem,
  ContextMenuEditItem,
  ContextMenuItem,
} from '@components/ContextMenu';
import { ResendInvitationDialog } from '@components/ResendInvitationDialog';
import { useUserResendInvitationApi, type User } from '@hooks/api/users';
import { useRenderConfig } from '@hooks/ui';
import { useEnvironment } from '@hooks/useEnvironment';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { getOpenOrgInvitationCount, getOpenOrgInvitations } from '@utils/user';

import { LoadingBackdrop } from './LoadingBackdrop';

interface UserContextMenuProps {
  hasEdit?: boolean;
  onClickEdit?: () => void;
  onClickDelete: () => void;
  user: User;
  hasResendInvitation?: boolean;
}

export const UserContextMenu: FC<UserContextMenuProps> = ({
  user,
  hasEdit = false,
  onClickEdit,
  onClickDelete,
  hasResendInvitation = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth0Manage } = useEnvironment();
  const { isItemVisible } = useRenderConfig();
  const resendInvitation = useUserResendInvitationApi();
  const { showMessage } = useSnackbarMessage();

  const [isSelectingOrg, setIsSelectingOrg] = useState(false);
  const [isSendingInvitation, setIsSendingInvitation] = useState(false);

  const handleAuth0Click = () => {
    const auth0Link = `${auth0Manage}/users/${user.connection}|${user.id}`;

    window.open(auth0Link, '_blank');
  };

  const handleClickDelete = () => {
    onClickDelete();
  };

  const handleClickViewPermissions = () => {
    navigate(`/users/${user.id}/permissions`);
  };

  const handleResendInvitation = async (orgId: string) => {
    setIsSendingInvitation(true);

    try {
      await resendInvitation(user.id, user.connection, orgId);
      setIsSelectingOrg(false);

      showMessage({
        message: t('pages.user.edit.messages.resendInvitationSuccess'),
        type: 'success',
      });
    } catch (error) {
      showMessage({
        message: `${t('pages.user.edit.messages.resendInvitationError')} (${(
          error as Error
        ).toString()})`,
        type: 'error',
      });
    }

    setIsSendingInvitation(false);
  };

  const handleClickResendInvitation = () => {
    const openOrgInvitationCount = getOpenOrgInvitationCount(user.organizationRelations);

    if (openOrgInvitationCount > 1) {
      setIsSelectingOrg(true);

      return;
    }

    const onlyOpenOrgInvitation = getOpenOrgInvitations(user.organizationRelations)[0];

    handleResendInvitation(onlyOpenOrgInvitation.id);
  };

  const handleCancelResendInvitationDialog = () => {
    setIsSelectingOrg(false);
  };

  return (
    <>
      <ContextMenu data-testid={`user-context-menu-${user.name}`}>
        {hasEdit && (
          <Box display="flex" flexDirection="column">
            <ContextMenuEditItem onClick={onClickEdit} />
            <Divider />
            <ContextMenuItem
              data-testid="context-menu-item-view-permissions"
              icon={<ShieldPersonOutline fill="currentColor" height={24} width={24} />}
              label={t('pages.user.overview.components.contextMenu.viewPermissions')}
              onClick={handleClickViewPermissions}
            />
          </Box>
        )}
        {hasResendInvitation && (
          <Stack>
            {hasEdit && <Box height={(theme) => theme.spacing(1)} />}
            <ContextMenuItem
              data-testid="context-menu-item-resend-invitation"
              icon={<MailOutline fill="currentColor" height={24} width={24} />}
              label={t('pages.user.overview.components.contextMenu.resendInvitation')}
              onClick={handleClickResendInvitation}
            />
          </Stack>
        )}
        {isItemVisible('user.components.contextMenu.elements.auth0') && (
          <Stack>
            {(hasEdit || hasResendInvitation) && <Box height={(theme) => theme.spacing(1)} />}
            <ContextMenuItem
              data-testid="context-menu-item-view-user-in-auth0"
              icon={<LockClosedOutline fill="currentColor" height={24} width={24} />}
              label={t('pages.user.overview.components.contextMenu.openInAuth0')}
              onClick={handleAuth0Click}
            />
            <Divider />
          </Stack>
        )}
        <ContextMenuDeleteItem data-testid={`delete-user-${user.id}`} onClick={handleClickDelete} />
      </ContextMenu>
      <ResendInvitationDialog
        onCancel={handleCancelResendInvitationDialog}
        onSubmit={handleResendInvitation}
        open={isSelectingOrg}
        user={user}
      />
      <LoadingBackdrop open={isSendingInvitation} />
    </>
  );
};
