import useSWR from 'swr';

import { useJsonFetch } from '@hooks/api/useJsonFetch';

import type { ApiAccessListItem } from '../apiAccesses';

export const useProjectApiAccessApi = (id?: string) => {
  const jsonFetch = useJsonFetch<ApiAccessListItem[]>();

  return useSWR(id ? `/projects/${id}/api-accesses` : null, jsonFetch);
};
