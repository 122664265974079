import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { DeleteDialog } from '@components/DeleteDialog';
import { PageHeader } from '@components/PageHeader';
import { useApiAccessDeleteApi } from '@hooks/api/apiAccesses';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { DetailPageLayout } from '@pages/common';

import { ApiAccessContextMenu } from '../ApiAccessContextMenu';

import type { ApiAccessesFormModeProps } from './types';
import { useFormApiAccessesState } from './useFormApiAccessesState';

export const ApiAccessesFormDetails: FC<ApiAccessesFormModeProps> = ({
  STEPS,
  currentStep,
  handleNavigateByTab,
  handleCancel,
  handleGoBack,
  handleGoNext,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showMessage } = useSnackbarMessage();
  const { apiAccess } = useFormApiAccessesState();
  const deleteApiAccess = useApiAccessDeleteApi();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleClickDeleteApiAccess = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmedDelete = async (confirmed: boolean) => {
    setConfirmDialogOpen(false);

    if (apiAccess && confirmed) {
      try {
        await deleteApiAccess(apiAccess.clientId);
        revalidateCacheForEntity('/api-accesses');
        navigate('/api-accesses');

        showMessage({
          message: t('pages.apiAccesses.edit.messages.deleteSuccess'),
          type: 'success',
        });
      } catch (error) {
        console.error(error);
        showMessage({ message: (error as Error).toString(), type: 'error' });
      }
    }
  };

  return (
    <>
      <DetailPageLayout
        title={
          <Box alignItems="center" display="flex">
            <PageHeader>{apiAccess?.clientId}</PageHeader>
            <Box mx={1} />
            {apiAccess && (
              <ApiAccessContextMenu
                apiAccessId={apiAccess.clientId}
                onClickDelete={handleClickDeleteApiAccess}
              />
            )}
          </Box>
        }
      >
        <Box>
          <Tabs aria-label="api-accesses-details-tabs" onChange={() => ({})} value={currentStep}>
            {STEPS.map(({ path, label, icon }, index) => (
              <Tab
                aria-controls={`api-accesses-details-tabpanel-${path}`}
                data-testid={`api-accesses-details-tabpanel-${path}`}
                icon={icon}
                iconPosition="start"
                id={`api-accesses-details-tab-${index}`}
                key={path}
                label={label}
                onClick={handleNavigateByTab(index)}
              />
            ))}
          </Tabs>
        </Box>
        <Box my={2} />
        <Outlet context={{ handleCancel, handleGoBack, handleGoNext }} />
      </DetailPageLayout>
      <DeleteDialog
        message={t('pages.apiAccesses.overview.components.dialog.content')}
        onClick={handleConfirmedDelete}
        open={confirmDialogOpen}
        title={t('pages.apiAccesses.overview.components.dialog.title')}
      />
    </>
  );
};
