import { useCallback } from 'react';

import { useJsonFetch } from '@hooks/api/useJsonFetch';
import type { User } from '@hooks/api/users';
import { buildBody } from '@hooks/api/utils';

export const useUserUpdateApi = () => {
  const jsonFetch = useJsonFetch<User>();

  const fetcher = useCallback(
    ({ id, ...formData }: Partial<User>) =>
      jsonFetch(`/users/${id}?connection=auth0`, {
        method: 'PATCH',

        body: buildBody(formData),
      }),
    [jsonFetch],
  );

  return fetcher;
};
