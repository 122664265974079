import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { type FC, useState, type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { FormSectionHeader } from '@components/FormElements';
import type { Project, ProjectPermission } from '@hooks/api/projects';

import { AllProjectsTable } from './AllProjectsTable';

export interface AllProjectsTableContainerProps {
  onAddProject: (updatedProjectList: ProjectPermission[]) => void;
  projects: Project[];
  selectedProjects: ProjectPermission[];
  isLoading: boolean;
}

export const AllProjectsTableContainer: FC<AllProjectsTableContainerProps> = ({
  onAddProject,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [searchName, setSearchName] = useState<string>('');

  const handleChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    const searchString = event.currentTarget.value;

    if (searchString.length > 1 || searchString.length === 0) {
      setSearchName(searchString);
    }
  };

  const handleAddProject = (projects: Project[]) => {
    const newProjectPermissions: ProjectPermission[] = projects.map((project) => ({
      id: project.id,
      projectId: project.id,
      project,
      backofficeRole: null,
      hasDocumentation: false,
      hasMonitoring: false,
      hasInvoiceTool: false,
    }));

    const { selectedProjects } = otherProps;

    onAddProject([...selectedProjects, ...newProjectPermissions]);
  };

  return (
    <>
      <FormSectionHeader
        id="all-projects-section"
        title={t('pages.groupsPermissions.create.components.header.allProjects')}
      />
      <Box display="flex" mb={4}>
        <Box flexBasis="50%">
          <TextField
            fullWidth
            onChange={handleChangeSearchText}
            placeholder={t('pages.groupsPermissions.create.components.filter.project')}
            type="search"
          />
        </Box>
      </Box>
      <AllProjectsTable onAddProject={handleAddProject} {...otherProps} searchString={searchName} />
    </>
  );
};
