import Box from '@mui/material/Box';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const InvoiceToolAdminInfo: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <b>{t('entities.user.fields.isInvoiceToolAdmin.help.features.prefix')}</b>
      <Box component="ul">
        <li>
          {t('entities.user.fields.isInvoiceToolAdmin.help.features.manageInvoiceSpecifications')}
        </li>
        <li>{t('entities.user.fields.isInvoiceToolAdmin.help.features.manageOrders')}</li>
        <li>{t('entities.user.fields.isInvoiceToolAdmin.help.features.manageInvoiceDatasets')}</li>
      </Box>
    </>
  );
};
