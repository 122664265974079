import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useGroupsApi } from '@hooks/api/groups';

import type { FormNavigationState } from './types';

export const useFormGroupState = () => {
  const { data: groupsData } = useGroupsApi();
  const { state } = useLocation();
  const { id } = useParams();

  const group = useMemo(() => {
    const formNavigationState = state as FormNavigationState;

    if (formNavigationState?.group) return formNavigationState.group;

    return groupsData?.content.find((group) => group.id === id);
  }, [state, id, groupsData]);

  return {
    group,
  };
};
