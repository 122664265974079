export const ERROR_CODES = [
  'InvalidSMA',
  'InvalidHeader',
  'InvalidEntry',
  'InvalidHierarchy',
  'MissingNVTNumber',
  'MissingHKNumber',
  'MissingASBNumber',
  'MissingHierarchy',
] as const;
type ErrorCodes = typeof ERROR_CODES;
export type ErrorCode = ErrorCodes[number];
export interface ErrorLocalised {
  message: string;
  details: string;
}

export interface ErrorBackend {
  code: ErrorCode;
  message: string;
  details: string;
  line: number | string;
}
