import type { TypographyProps } from '@mui/material/Typography';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';

export const PageHeader: FC<TypographyProps> = ({ children, ...componentProps }) => {
  return (
    <Typography
      {...componentProps}
      component="span"
      data-testid="page-header"
      sx={(theme) => ({ fontSize: theme.spacing(4), fontWeight: theme.typography.fontWeightBold })}
    >
      {children}
    </Typography>
  );
};
