import { Divider, Stack } from '@mui/material';
import type { FC } from 'react';

import { ContextMenu, ContextMenuDeleteItem, ContextMenuEditItem } from '@components/ContextMenu';

export interface ApiAccessContextMenuProps {
  apiAccessId: string;
  hasEdit?: boolean;
  onClickEdit?: () => void;
  onClickDelete: () => void;
}

export const ApiAccessContextMenu: FC<ApiAccessContextMenuProps> = ({
  apiAccessId,
  hasEdit,
  onClickEdit,
  onClickDelete,
}) => {
  const handleClickDelete = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    onClickDelete();
  };

  return (
    <ContextMenu data-testid={`context-menu-api-access-${apiAccessId}`}>
      {hasEdit && (
        <Stack>
          <ContextMenuEditItem onClick={onClickEdit} />
          <Divider />
        </Stack>
      )}
      <ContextMenuDeleteItem
        data-testid={`delete-api-access-${apiAccessId}`}
        onClick={handleClickDelete}
      />
    </ContextMenu>
  );
};
