import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const Plugins = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">{t('pages.privacyPolicy.sections.plugins.title')}</Typography>
      <Box my={1} />
      <Typography>
        <Trans i18nKey={'pages.privacyPolicy.sections.plugins.content.prelude'}>
          Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von den Servern
          ihrer jeweiligen Anbieter (nachfolgend bezeichnet als &quot;Drittanbieter&quot;) bezogen
          werden. Dabei kann es sich zum Beispiel um Grafiken, Videos oder Stadtpläne handeln
          (nachfolgend einheitlich bezeichnet als &quot;Inhalte&quot;). <br />
          Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse
          der Nutzer verarbeiten, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser
          senden könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte oder
          Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige
          Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. <br />
          Drittanbieter können ferner sogenannte Pixel-Tags (unsichtbare Grafiken, auch als
          &quot;Web Beacons&quot; bezeichnet) für statistische oder Marketingzwecke verwenden. Durch
          die &quot;Pixel-Tags&quot; können Informationen, wie der Besucherverkehr auf den Seiten
          dieser Webseite, ausgewertet werden. Die pseudonymen Informationen können ferner in
          Cookies auf dem Gerät der Nutzer gespeichert werden und unter anderem technische
          Informationen zum Browser und zum Betriebssystem, zu verweisenden Webseiten, zur
          Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten als auch
          mit solchen Informationen aus anderen Quellen verbunden werden.
        </Trans>
      </Typography>
      <ul>
        <li>
          <Typography>
            <Trans
              i18nKey={'pages.privacyPolicy.sections.plugins.content.list.general.processedTypes'}
            >
              <Typography component="span" fontWeight="bold">
                Verarbeitete Datenarten:
              </Typography>{' '}
              Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen); Standortdaten
              (Angaben zur geografischen Position eines Gerätes oder einer Person).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={'pages.privacyPolicy.sections.plugins.content.list.general.dataSubjects'}
            >
              <Typography component="span" fontWeight="bold">
                Betroffene Personen:
              </Typography>{' '}
              Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.plugins.content.list.general.purposesOfProcessing'
              }
            >
              <Typography component="span" fontWeight="bold">
                Zwecke der Verarbeitung:
              </Typography>{' '}
              Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans i18nKey={'pages.privacyPolicy.sections.plugins.content.list.general.legalBasis'}>
              <Typography component="span" fontWeight="bold">
                Rechtsgrundlagen:
              </Typography>{' '}
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </Trans>
          </Typography>
        </li>
      </ul>
      <Typography component="span" fontWeight="bold">
        {t('pages.privacyPolicy.common.furtherNotes')}
      </Typography>
      <ul>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.plugins.content.list.further.thirdPartyIntegration'
              }
            >
              <Typography component="span" fontWeight="bold">
                Einbindung von Drittsoftware, Skripten oder Frameworks (z. B. jQuery):
              </Typography>{' '}
              Wir binden in unser Onlineangebot Software ein, die wir von Servern anderer Anbieter
              abrufen (z.B. Funktions-Bibliotheken, die wir zwecks Darstellung oder
              Nutzerfreundlichkeit unseres Onlineangebotes verwenden). Hierbei erheben die
              jeweiligen Anbieter die IP-Adresse der Nutzer und können diese zu Zwecken der
              Übermittlung der Software an den Browser der Nutzer sowie zu Zwecken der Sicherheit,
              als auch zur Auswertung und Optimierung ihres Angebotes verarbeiten. - Wir binden in
              unser Onlineangebot Software ein, die wir von Servern anderer Anbieter abrufen (z.B.
              Funktions-Bibliotheken, die wir zwecks Darstellung oder Nutzerfreundlichkeit unseres
              Onlineangebotes verwenden). Hierbei erheben die jeweiligen Anbieter die IP-Adresse der
              Nutzer und können diese zu Zwecken der Übermittlung der Software an den Browser der
              Nutzer sowie zu Zwecken der Sicherheit, als auch zur Auswertung und Optimierung ihres
              Angebotes verarbeiten;{' '}
              <Typography component="span" fontWeight="bold">
                Rechtsgrundlagen:
              </Typography>{' '}
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans
              i18nKey={'pages.privacyPolicy.sections.plugins.content.list.further.cloudMapService'}
            >
              <Typography component="span" fontWeight="bold">
                Cloud-Kartendienst:
              </Typography>{' '}
              Bereitstellung und Bearbeitung von geografischen und anderen Karten, Plänen und
              standortbezogenen Informationen;{' '}
              <Typography component="span" fontWeight="bold">
                Rechtsgrundlagen:
              </Typography>{' '}
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);
            </Trans>
          </Typography>
        </li>
      </ul>
    </Box>
  );
};
