import {
  Autocomplete,
  FormControl,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { Controller, type Control, type DeepRequired, type FieldErrorsImpl } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { Organization } from '@hooks/api/organizations';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';

import type { OrganizationFormSchema } from '../schema';

interface OrganizationsFormInvoiceTypeProps {
  formErrors: FieldErrorsImpl<DeepRequired<Organization>>;
  organization?: Organization;
  control: Control<OrganizationFormSchema>;
}

export const OrganizationsFormInvoiceType = ({
  organization,
  formErrors,
  control,
}: OrganizationsFormInvoiceTypeProps) => {
  const { t } = useTranslation();
  const { isItemEnabled } = useRenderConfig();

  const isFormEnabled = isItemEnabled('organizations.edit.form.mode');

  const invoiceTypes = [
    {
      id: 'ONE_FOR_ALL_PROJECTS',
      name: t('entities.organization.fields.billing.invoiceType.types.oneForAllProjects'),
    },
    {
      id: 'ONE_PER_PROJECT',
      name: t('entities.organization.fields.billing.invoiceType.types.onePerProject'),
    },
  ];
  const currentInvoiceType = invoiceTypes.find(
    (invoiceType) => invoiceType.id === organization?.labeledMeterInvoiceFormat,
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const inputWidth = isDesktop ? '50%' : '100%';

  return (
    <Stack mb={2} width={inputWidth}>
      <FormControl disabled={!isFormEnabled}>
        <Controller
          control={control}
          defaultValue={currentInvoiceType?.id}
          name={'labeledMeterInvoiceFormat'}
          render={({ field: { onChange } }) => (
            <Autocomplete
              defaultValue={currentInvoiceType}
              disabled={!isFormEnabled}
              fullWidth
              getOptionLabel={(p) => p.name}
              onChange={(_, value) => {
                onChange(value?.id);
              }}
              options={invoiceTypes}
              renderInput={(params) => (
                <TextField
                  label={t('entities.organization.fields.billing.invoiceType.label')}
                  {...params}
                  error={!!formErrors.labeledMeterInvoiceFormat}
                  helperText={
                    <Box component="span">
                      <Typography component="span" variant="body2">
                        {formErrors.labeledMeterInvoiceFormat?.message?.toString()}
                      </Typography>
                    </Box>
                  }
                  placeholder={t('common.pleaseChoose')}
                />
              )}
            />
          )}
        />
      </FormControl>
    </Stack>
  );
};
