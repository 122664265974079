import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useOrganizationsApi } from '@hooks/api/organizations';

export const useFormOrganizationState = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { data: organizationsData } = useOrganizationsApi();

  const organization = useMemo(() => {
    if (state) return state?.organization;

    return organizationsData?.content.find((organization) => organization.id === id);
  }, [id, state, organizationsData]);

  return {
    organization,
  };
};
