import useSWR from 'swr';

import { useJsonFetch } from '@hooks/api/useJsonFetch';
import type { User } from '@hooks/api/users';

import type { PaginatedServerResponse } from '../types';

export const useOrganizationsApiTotalCount = () => {
  const jsonFetch = useJsonFetch<PaginatedServerResponse<User>>();
  const response = useSWR('/organizations?page0&size=1', jsonFetch);
  const totalElements = response.data?.totalElements;

  return totalElements;
};
