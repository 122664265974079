import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const Cookies = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">{t('pages.privacyPolicy.sections.cookies.title')}</Typography>
      <Box my={1} />
      <Typography>
        <Trans i18nKey={'pages.privacyPolicy.sections.cookies.content.prelude'}>
          Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die Informationen auf
          Endgeräten speichern und Informationen aus den Endgeräten auslesen. Z.B. um den
          Login-Status in einem Nutzerkonto, einen Warenkorbinhalt in einem E-Shop, die aufgerufenen
          Inhalte oder verwendete Funktionen eines Onlineangebotes speichern. Cookies können ferner
          zu unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der Funktionsfähigkeit,
          Sicherheit und Komfort von Onlineangeboten sowie der Erstellung von Analysen der
          Besucherströme.
          <br />
          <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
            Hinweise zur Einwilligung:
          </Typography>{' '}
          Wir setzen Cookies im Einklang mit den gesetzlichen Vorschriften ein. Daher holen wir von
          den Nutzern eine vorhergehende Einwilligung ein, außer wenn diese gesetzlich nicht
          gefordert ist. Eine Einwilligung ist insbesondere nicht notwendig, wenn das Speichern und
          das Auslesen der Informationen, also auch von Cookies, unbedingt erforderlich sind, um dem
          den Nutzern einen von ihnen ausdrücklich gewünschten Telemediendienst (also unser
          Onlineangebot) zur Verfügung zu stellen. Die widerrufliche Einwilligung wird gegenüber den
          Nutzern deutlich kommuniziert und enthält die Informationen zu der jeweiligen
          Cookie-Nutzung.
          <br />
          <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
            Hinweise zu datenschutzrechtlichen Rechtsgrundlagen:
          </Typography>{' '}
          Auf welcher datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen Daten der
          Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Nutzer um eine
          Einwilligung bitten. Falls die Nutzer einwilligen, ist die Rechtsgrundlage der
          Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von
          Cookies verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z.B. an einem
          betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung seiner
          Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung unserer vertraglichen
          Pflichten erfolgt, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen
          Verpflichtungen zu erfüllen. Zu welchen Zwecken die Cookies von uns verarbeitet werden,
          darüber klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren
          Einwilligungs- und Verarbeitungsprozessen auf.
          <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
            Speicherdauer:
          </Typography>{' '}
          Im Hinblick auf die Speicherdauer werden die folgenden Arten von Cookies unterschieden:
        </Trans>
        <ul>
          <li>
            <Typography>
              <Trans i18nKey={'pages.privacyPolicy.sections.cookies.content.list.temporary'}>
                <Typography
                  component="span"
                  fontWeight={(theme) => theme.typography.fontWeightBold}
                >
                  Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):
                </Typography>{' '}
                Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot
                verlassen und sein Endgerät (z.B. Browser oder mobile Applikation) geschlossen hat.
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans i18nKey={'pages.privacyPolicy.sections.cookies.content.list.permanent'}>
                <Typography
                  component="span"
                  fontWeight={(theme) => theme.typography.fontWeightBold}
                >
                  Permanente Cookies:
                </Typography>{' '}
                Permanente Cookies bleiben auch nach dem Schließen des Endgerätes gespeichert. So
                können beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte direkt
                angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die mit
                Hilfe von Cookies erhobenen Daten der Nutzer zur Reichweitenmessung verwendet
                werden. Sofern wir Nutzern keine expliziten Angaben zur Art und Speicherdauer von
                Cookies mitteilen (z. B. im Rahmen der Einholung der Einwilligung), sollten Nutzer
                davon ausgehen, dass Cookies permanent sind und die Speicherdauer bis zu zwei Jahre
                betragen kann.
              </Trans>
            </Typography>
          </li>
        </ul>
        <Typography>
          <Trans i18nKey={'pages.privacyPolicy.sections.cookies.content.epilogue'}>
            <Typography component="span" fontWeight={(theme) => theme.typography.fontWeightBold}>
              Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out):
            </Typography>{' '}
            Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit widerrufen und zudem
            einen Widerspruch gegen die Verarbeitung entsprechend den gesetzlichen Vorgaben im Art.
            21 DSGVO einlegen. Nutzer können ihren Widerspruch auch über die Einstellungen ihres
            Browsers erklären, z.B. durch Deaktivierung der Verwendung von Cookies (wobei dadurch
            auch die Funktionalität unserer Online-Dienste eingeschränkt sein kann). Ein Widerspruch
            gegen die Verwendung von Cookies zu Online-Marketing-Zwecken kann auch über die Websites{' '}
            <a href="https://optout.aboutads.info" rel="noreferrer" target="_blank">
              https://optout.aboutads.info
            </a>
            und{' '}
            <a href="https://www.youronlinechoices.com/" rel="noreferrer" target="_blank">
              https://www.youronlinechoices.com/
            </a>{' '}
            erklärt werden.
          </Trans>
        </Typography>
      </Typography>
    </Box>
  );
};
