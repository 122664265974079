import { DeepUpLogoFull } from '@deepup/icons';
import { AppBar, Box, Toolbar, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BusinessServices } from './sections/BusinessServices';
import { Controller } from './sections/Controller';
import { Cookies } from './sections/Cookies';
import { Definitions } from './sections/Definitions';
import { ElectronicCommunications } from './sections/ElectronicCommunications';
import { LegalBasesProcessing } from './sections/LegalBasesProcessing';
import { Monitoring } from './sections/Monitoring';
import { OverviewProcessing } from './sections/OverviewProcessing';
import { Plugins } from './sections/Plugins';
import { Preamble } from './sections/Preamble';
import { Registration } from './sections/Registration';
import { RightsDataSubjects } from './sections/RightsDataSubjects';
import { SecurityPrecautions } from './sections/SecurityPrecautions';
import { SingleSignOn } from './sections/SingleSignOn';
import { SpecialNotesApplications } from './sections/SpecialNotesApplications';
import { ToC } from './sections/ToC';
import { WebHosting } from './sections/WebHosting';

export const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box>
      <AppBar
        elevation={2}
        position="fixed"
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: theme.palette.background.paper,
          boxShadow:
            // TODO: make it right
            '0px 3px 1px -2px rgb(0 0 0 / 7%), 0px 2px 2px 0px rgb(0 0 0 / 3%), 0px 1px 5px 0px rgb(0 0 0 / 0%)',
        })}
      >
        <Toolbar component={Box} display="flex" justifyContent="center">
          <Box alignItems="center" display="flex" onClick={() => navigate(`/`)}>
            <DeepUpLogoFull fill={theme.palette.text.primary} height={64} width={100} />
          </Box>
        </Toolbar>
      </AppBar>
      <Box display="flex" justifyContent="center" paddingTop={20}>
        <Box sx={(theme) => ({ width: theme.spacing(80) })}>
          <Box>
            <Typography variant="h4">{t('pages.privacyPolicy.title')}</Typography>
          </Box>

          <Preamble />

          <ToC />

          <Controller />

          <OverviewProcessing />

          <LegalBasesProcessing />

          <SecurityPrecautions />

          <Box my={1}>
            <Typography variant="h5">
              {t('pages.privacyPolicy.sections.transmissionsPersonalData.title')}
            </Typography>
            <Box my={1} />
            <Typography>
              {t('pages.privacyPolicy.sections.transmissionsPersonalData.content')}
            </Typography>
          </Box>

          <Cookies />

          <BusinessServices />

          <WebHosting />

          <SpecialNotesApplications />

          <Registration />

          <SingleSignOn />

          <ElectronicCommunications />

          <Monitoring />

          <Plugins />

          <RightsDataSubjects />

          <Definitions />
        </Box>
      </Box>
    </Box>
  );
};
