import type { ReactNode, JSXElementConstructor, ReactElement } from 'react';

import type { Group } from '@hooks/api/groups';

export interface FormNavigationState {
  group?: Group;
}

export interface OutletContext {
  handleGoBack: () => void;
  handleGoNext: (response?: Group) => void;
  handleCancel: () => void;
}

export interface NavigationOptions {
  mode: 'next' | 'prev';
  groupId?: string;
  state?: FormNavigationState;
}

export enum StepPath {
  overview = 'overview',
  projects = 'projects',
  users = 'user',
}

export interface Step {
  path: StepPath;
  label: string;
  icon?: string | ReactElement<ReactNode, string | JSXElementConstructor<ReactNode>>;
}

export interface GroupsFormModeProps {
  STEPS: Step[];
  MAX_STEPS: number;
  currentStep: number;
  handleNavigateByTab: (index: number) => () => void;
  handleCancel: () => void;
  handleGoBack: () => void;
  handleGoNext: () => void;
}
