import { TrashcanOutline } from '@deepup/icons';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ContextMenuItem, type ContextMenuItemProps } from './ContextMenuItem';

type ContextMenuDeleteItemProps = Pick<ContextMenuItemProps, 'onClick' | 'data-testid'>;

export const ContextMenuDeleteItem = ({
  'data-testid': dataTestId,
  onClick,
}: ContextMenuDeleteItemProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ContextMenuItem
      data-testid={dataTestId}
      icon={<TrashcanOutline fill={theme.palette.error.main} height={24} width={24} />}
      label={t('common.delete')}
      onClick={onClick}
    />
  );
};
