import { useCallback } from 'react';

import { useJsonFetch } from '@hooks/api/useJsonFetch';

import type { ApiAccess } from './types';

export const useApiAccessesDeleteApi = () => {
  const jsonFetch = useJsonFetch<ApiAccess>();

  const fetcher = useCallback(
    (id: string) =>
      jsonFetch(`/api-access/${id}`, {
        method: 'DELETE',
      }),
    [jsonFetch],
  );

  return fetcher;
};
