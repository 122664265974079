import { useAuth0 } from '@auth0/auth0-react';

import type { User } from '@hooks/api/users';

const buildUserId = (user: User): string => `${user.connection}|${user.id}`;

export interface IsLoggedInUserHook {
  isLoggedInUser: (user?: User | null) => boolean;
}

export const useIsLoggedInUser = (): IsLoggedInUserHook => {
  const { user: loggedInUser } = useAuth0();

  return {
    isLoggedInUser: (user) => !!user && buildUserId(user) === loggedInUser?.sub,
  };
};
