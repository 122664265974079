import type { ProjectPeriod } from '@hooks/api/projects';

export const mapPeriod = (period: unknown): ProjectPeriod | undefined => {
  if (!period) return undefined;
  if (typeof period !== 'object') return undefined;
  const { startDate: sd, endDate: ed } = period as { startDate: unknown; endDate: unknown };

  if (!sd && !ed) return undefined;

  return {
    startDate: sd && typeof sd === 'string' ? new Date(sd).toISOString() : undefined,
    endDate: ed && typeof ed === 'string' ? new Date(ed).toISOString() : undefined,
  } as ProjectPeriod;
};
