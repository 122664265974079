import { CopyOutline, EyeOutline, EyeSlashedOutline } from '@deepup/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState, type FC } from 'react';
import { useForm, useFormState, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';

import { BottomFormNavigation } from '@components/BottomFormNavigation';
import { FormInput, FormSectionHeader } from '@components/FormElements';
import { OrganizationSelect } from '@components/FormElements/OrganizationSelect';
import type { ApiAccess } from '@hooks/api/apiAccesses';
import { useOrganizationsApi } from '@hooks/api/organizations';
import { usePlatformPermissions } from '@hooks/usePlatformPermissions';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

import { schemaCreateStepOverview } from './schema';
import type { OutletContext } from './types';
import { useFormApiAccessesState } from './useFormApiAccessesState';

interface ApiAccessesFormOverviewProps {
  isCreate?: boolean;
  isDeepUpInternal?: boolean;
}

export const ApiAccessesFormOverview: FC<ApiAccessesFormOverviewProps> = ({ isCreate = false }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { showMessage } = useSnackbarMessage();
  const { handleCancel, handleGoNext } = useOutletContext<OutletContext>();

  const {
    permissions: { organizationId: adminOrganizationId },
  } = usePlatformPermissions();

  const { data: organizationsData, error: organizationsError } = useOrganizationsApi();
  const { apiAccess } = useFormApiAccessesState();

  const [showSecret, setShowSecret] = useState(false);

  const schemaCreateStepOverviewWithTranslations = schemaCreateStepOverview({
    error: {
      organizationId: {
        required: t('entities.apiAccesses.fields.organizationId.error.required'),
      },
      clientId: {
        required: t('entities.apiAccesses.fields.clientId.error.required'),
        pattern: t('entities.apiAccesses.fields.clientId.error.pattern'),
      },
    },
  });

  const { register, handleSubmit, control } = useForm({
    resolver: yupResolver(schemaCreateStepOverviewWithTranslations),
  });
  const { errors: formErrors, dirtyFields } = useFormState({ control });

  const handleNext = async (data: FieldValues) => {
    if (isCreate) {
      try {
        handleGoNext(data as ApiAccess);
      } catch (error) {
        console.warn('error when creating api access', error);

        showMessage({
          message: t('pages.apiAccesses.create.messages.errorCreateApiAccess', {
            errorMessage: error,
          }),
          type: 'error',
        });
      }
    }
  };

  const toggleShowSecret = () => {
    setShowSecret(!showSecret);
  };

  const createCopyToClipboardHandler = (label: string, value?: string) => () => {
    if (value) {
      navigator.clipboard.writeText(value);

      showMessage({
        message: t('common.copiedToClipboard', { entity: label }),
        type: 'success',
      });
    }
  };

  useEffect(() => {
    const hasFormErrors = Object.keys(formErrors).length > 0;
    const hasDirtyFields = Object.keys(dirtyFields).length > 0;

    if (hasFormErrors && !hasDirtyFields) {
      showMessage({ message: t('common.missingData'), type: 'error' });
    }
  }, [formErrors, t, showMessage, dirtyFields]);

  if (!organizationsData || (id && !apiAccess)) return <Box>{t('common.loading')}</Box>;
  if (organizationsError) return <Box>{t('common.failedLoading')}</Box>;

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(handleNext)}>
        <Box display="flex" flexDirection="column">
          <Box marginBottom={1}>
            <FormSectionHeader title={t('pages.apiAccesses.form.overview.sections.connections')} />
            <Box display="flex" flexDirection="column">
              {isCreate ? (
                <OrganizationSelect
                  control={control}
                  data-testid={'api-access-organization-select'}
                  defaultValue={adminOrganizationId}
                  error={formErrors.organizationId}
                  formFieldName="organizationId"
                  isRequired
                  items={organizationsData.content}
                  label={t('entities.apiAccesses.fields.organizationId.label')}
                />
              ) : (
                <FormInput
                  defaultValue={apiAccess?.organizationName}
                  disabled
                  formFieldName="organizationName"
                  isRequired
                  label={t('entities.apiAccesses.fields.organizationId.label')}
                  register={register}
                />
              )}
              {!isCreate && (
                <>
                  <FormInput
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end" sx={{ marginRight: 2 }}>
                          <IconButton
                            aria-label="copy token address"
                            edge="end"
                            onClick={createCopyToClipboardHandler(
                              t('entities.apiAccesses.fields.authTokenAddress.label'),
                              apiAccess?.authTokenAddress,
                            )}
                          >
                            <CopyOutline height={24} width={24} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    defaultValue={apiAccess?.authTokenAddress}
                    disabled
                    formFieldName="authTokenAddress"
                    isRequired
                    label={t('entities.apiAccesses.fields.authTokenAddress.label')}
                    register={register}
                  />
                  <FormInput
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end" sx={{ marginRight: 2 }}>
                          <IconButton
                            aria-label="copy API URL"
                            edge="end"
                            onClick={createCopyToClipboardHandler(
                              t('entities.apiAccesses.fields.apiUrl.label'),
                              apiAccess?.apiUrl,
                            )}
                          >
                            <CopyOutline height={24} width={24} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    defaultValue={apiAccess?.apiUrl}
                    disabled
                    formFieldName="apiUrl"
                    isRequired
                    label={t('entities.apiAccesses.fields.apiUrl.label')}
                    register={register}
                  />
                </>
              )}
            </Box>
          </Box>
          <Box marginBottom={4}>
            <FormSectionHeader title={t('pages.apiAccesses.form.overview.sections.accessData')} />
            <Box display="flex" flexDirection="column">
              <FormInput
                InputProps={{
                  endAdornment: !isCreate && (
                    <InputAdornment position="end" sx={{ marginRight: 2 }}>
                      <IconButton
                        aria-label="copy secret"
                        edge="end"
                        onClick={createCopyToClipboardHandler(
                          t('entities.apiAccesses.fields.clientId.label'),
                          apiAccess?.clientId,
                        )}
                      >
                        <CopyOutline height={24} width={24} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                defaultValue={apiAccess?.clientId}
                disabled={!isCreate}
                error={formErrors.clientId}
                formFieldName="clientId"
                isRequired
                label={t('entities.apiAccesses.fields.clientId.label')}
                register={register}
              />
              {!isCreate && (
                <FormInput
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end" sx={{ marginRight: 2 }}>
                          <IconButton
                            aria-label="toggle secret visibility"
                            data-testid={'toggle-secret-visibility'}
                            edge="end"
                            onClick={toggleShowSecret}
                          >
                            {showSecret ? (
                              <EyeSlashedOutline height={24} width={24} />
                            ) : (
                              <EyeOutline height={24} width={24} />
                            )}
                          </IconButton>
                        </InputAdornment>
                        <InputAdornment position="end" sx={{ marginRight: 2 }}>
                          <IconButton
                            aria-label="copy secret"
                            edge="end"
                            onClick={createCopyToClipboardHandler(
                              t('entities.apiAccesses.fields.secret.label'),
                              apiAccess?.secret,
                            )}
                          >
                            <CopyOutline height={24} width={24} />
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                  }}
                  defaultValue={apiAccess?.secret}
                  disabled
                  formFieldName="secret"
                  label={t('entities.apiAccesses.fields.secret.label')}
                  register={register}
                  sx={{ flexGrow: 1 }}
                  type={showSecret ? 'text' : 'password'}
                />
              )}
            </Box>
          </Box>
          {isCreate && (
            <BottomFormNavigation
              hasNext
              nextTitle={t('common.next')}
              nextType="submit"
              onGoBack={handleCancel}
            />
          )}
        </Box>
      </form>
    </Box>
  );
};
