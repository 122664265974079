import { Divider, Stack } from '@mui/material';
import type { FC, MouseEvent } from 'react';

import { ContextMenu, ContextMenuDeleteItem, ContextMenuEditItem } from '@components/ContextMenu';

interface GroupsContextMenuProps {
  hasEdit?: boolean;
  onClickEdit?: () => void;
  onClickDelete: () => void;
  groupId: string;
  groupName: string;
}

export const GroupsContextMenu: FC<GroupsContextMenuProps> = ({
  groupId,
  groupName,
  hasEdit = false,
  onClickEdit,
  onClickDelete,
}) => {
  const handleClickDelete = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    onClickDelete();
  };

  return (
    <ContextMenu data-testid={`group-context-menu-${groupName}`}>
      {hasEdit && (
        <Stack>
          <ContextMenuEditItem onClick={onClickEdit} />
          <Divider />
        </Stack>
      )}
      <ContextMenuDeleteItem data-testid={`delete-group-${groupId}`} onClick={handleClickDelete} />
    </ContextMenu>
  );
};
