import { LinearProgress, type LinearProgressProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type WizardProgressBarProps = LinearProgressProps & { step: number; maxSteps: number };

export const WizardProgressBar: React.FC<WizardProgressBarProps> = ({ step, maxSteps }) => {
  const progressValue = step * (100 / maxSteps);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box alignSelf="flex-end">
        <Typography variant="body2">
          Step {step}/{maxSteps}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress value={progressValue} variant="determinate" />
      </Box>
    </Box>
  );
};
