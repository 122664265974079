import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const Definitions = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">{t('pages.privacyPolicy.sections.definitions.title')}</Typography>
      <Box my={1} />
      <Typography>{t('pages.privacyPolicy.sections.definitions.content.prelude')}</Typography>
      <ul>
        <li>
          <Typography>
            <Trans i18nKey={'pages.privacyPolicy.sections.definitions.content.list.personalData'}>
              <Typography component="span" fontWeight="bold">
                Personenbezogene Daten:
              </Typography>{' '}
              &quot;Personenbezogene Daten&quot; sind alle Informationen, die sich auf eine
              identifizierte oder identifizierbare natürliche Person (im Folgenden &quot;betroffene
              Person&quot;) beziehen; als identifizierbar wird eine natürliche Person angesehen, die
              direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
              zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu
              einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der
              physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen
              oder sozialen Identität dieser natürlichen Person sind.
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans i18nKey={'pages.privacyPolicy.sections.definitions.content.list.profiles'}>
              <Typography component="span" fontWeight="bold">
                Profile mit nutzerbezogenen Informationen:
              </Typography>{' '}
              Die Verarbeitung von &quot;Profilen mit nutzerbezogenen Informationen&quot;, bzw. kurz
              &quot;Profilen&quot; umfasst jede Art der automatisierten Verarbeitung
              personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten
              verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche
              Person beziehen (je nach Art der Profilbildung können dazu unterschiedliche
              Informationen betreffend die Demographie, Verhalten und Interessen, wie z.B. die
              Interaktion mit Webseiten und deren Inhalten, etc.) zu analysieren, zu bewerten oder,
              um sie vorherzusagen (z.B. die Interessen an bestimmten Inhalten oder Produkten, das
              Klickverhalten auf einer Webseite oder den Aufenthaltsort). Zu Zwecken des Profilings
              werden häufig Cookies und Web-Beacons eingesetzt.
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans i18nKey={'pages.privacyPolicy.sections.definitions.content.list.webAnalytics'}>
              <Typography component="span" fontWeight="bold">
                Reichweitenmessung:
              </Typography>{' '}
              Die Reichweitenmessung (auch als Web Analytics bezeichnet) dient der Auswertung der
              Besucherströme eines Onlineangebotes und kann das Verhalten oder Interessen der
              Besucher an bestimmten Informationen, wie z.B. Inhalten von Webseiten, umfassen. Mit
              Hilfe der Reichweitenanalyse können Webseiteninhaber z.B. erkennen, zu welcher Zeit
              Besucher ihre Webseite besuchen und für welche Inhalte sie sich interessieren. Dadurch
              können sie z.B. die Inhalte der Webseite besser an die Bedürfnisse ihrer Besucher
              anpassen. Zu Zwecken der Reichweitenanalyse werden häufig pseudonyme Cookies und
              Web-Beacons eingesetzt, um wiederkehrende Besucher zu erkennen und so genauere
              Analysen zur Nutzung eines Onlineangebotes zu erhalten.
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans i18nKey={'pages.privacyPolicy.sections.definitions.content.list.locationData'}>
              <Typography component="span" fontWeight="bold">
                Standortdaten:
              </Typography>{' '}
              Standortdaten entstehen, wenn sich ein mobiles Gerät (oder ein anderes Gerät mit den
              technischen Voraussetzungen einer Standortbestimmung) mit einer Funkzelle, einem WLAN
              oder ähnlichen technischen Mittlern und Funktionen der Standortbestimmung, verbindet.
              Standortdaten dienen der Angabe, an welcher geografisch bestimmbaren Position der Erde
              sich das jeweilige Gerät befindet. Standortdaten können z. B. eingesetzt werden, um
              Kartenfunktionen oder andere von einem Ort abhängige Informationen darzustellen.
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans i18nKey={'pages.privacyPolicy.sections.definitions.content.list.targeting'}>
              <Typography component="span" fontWeight="bold">
                Tracking:
              </Typography>{' '}
              Vom &quot;Tracking&quot; spricht man, wenn das Verhalten von Nutzern über mehrere
              Onlineangebote hinweg nachvollzogen werden kann. Im Regelfall werden im Hinblick auf
              die genutzten Onlineangebote Verhaltens- und Interessensinformationen in Cookies oder
              auf Servern der Anbieter der Trackingtechnologien gespeichert (sogenanntes Profiling).
              Diese Informationen können anschließend z.B. eingesetzt werden, um den Nutzern
              Werbeanzeigen anzuzeigen, die voraussichtlich deren Interessen entsprechen.
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans i18nKey={'pages.privacyPolicy.sections.definitions.content.list.controller'}>
              <Typography component="span" fontWeight="bold">
                Verantwortlicher:
              </Typography>{' '}
              Als &quot;Verantwortlicher&quot; wird die natürliche oder juristische Person, Behörde,
              Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke
              und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
            </Trans>
          </Typography>
        </li>

        <li>
          <Typography>
            <Trans i18nKey={'pages.privacyPolicy.sections.definitions.content.list.processing'}>
              <Typography component="span" fontWeight="bold">
                Verarbeitung:
              </Typography>{' '}
              &quot;Verarbeitung&quot; ist jeder mit oder ohne Hilfe automatisierter Verfahren
              ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
              personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang
              mit Daten, sei es das Erheben, das Auswerten, das Speichern, das Übermitteln oder das
              Löschen.
            </Trans>
          </Typography>
        </li>
      </ul>
    </Box>
  );
};
