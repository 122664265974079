import type { User } from '@auth0/auth0-react';
import { XOutline, Hourglass } from '@deepup/icons';
import { Box, Button, Checkbox, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  DataGrid,
  type GridColDef,
  type GridRenderCellParams,
  type GridRowsProp,
} from '@mui/x-data-grid';
import { useCallback, useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { renderCellContent } from '@components/TableCell/TableCell';
import { useGroupsPermissionsApi, type UserPermission } from '@hooks/api/groups';
import { useRenderConfig } from '@hooks/ui';
import { hasOpenOrgInvitations } from '@utils/user';

import { useFormGroupState } from '../../useFormGroupState';

interface UsersInGroupTableProps {
  entities: UserPermission[];
  onChangeUserPermission: (
    entity: UserPermission,
    permission: 'isGroupAdmin',
    value: boolean,
  ) => void;
  onDeleteUser: (userId: string) => void;
  onDeleteAllUsers: () => void;
  isLoading: boolean;
}

export const UsersInGroupTable: FC<UsersInGroupTableProps> = ({
  entities,
  onChangeUserPermission,
  onDeleteAllUsers,
  onDeleteUser,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { isGroupAdmin } = useGroupsPermissionsApi();
  const { isItemEnabled } = useRenderConfig();
  const { group } = useFormGroupState();
  const theme = useTheme();

  const isGroupCreateEnabled = isItemEnabled('groups.create.form.mode');
  const canEdit = isGroupCreateEnabled || isGroupAdmin;

  const handleDeleteUser = useCallback(
    (userId: string) => () => {
      onDeleteUser(userId);
    },
    [onDeleteUser],
  );

  const getCellStyle = useCallback(
    (user: User) => {
      return hasOpenOrgInvitations(user.organizationRelations)
        ? { color: theme.palette.text.disabled }
        : { color: theme.palette.text.primary };
    },
    [theme],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('entities.user.fields.name.label'),
        flex: 1,
        renderCell: (params) => {
          const userName = params.row.user.name;
          const userHasOpenInvitations = hasOpenOrgInvitations(
            params.row.user.organizationRelations,
          );

          return renderCellContent({
            entityType: 'users',
            params,
            isLoading,
            columnField: 'name',
            renderContent: () => (
              <Box alignItems="center" display="flex">
                {userHasOpenInvitations && (
                  <Tooltip title={t('common.waitingForRegistration')}>
                    <Hourglass style={{ marginRight: theme.spacing(1) }} />
                  </Tooltip>
                )}
                <Typography style={getCellStyle(params.row.user)}>{userName}</Typography>
              </Box>
            ),
          });
        },
      },
      {
        field: 'email',
        headerName: t('entities.user.fields.email.label'),
        flex: 1,
        type: 'string',
        renderCell: (params: GridRenderCellParams<UserPermission>) => {
          const userEmail = params.row.user.email;

          return renderCellContent({
            entityType: 'users',
            params,
            isLoading,
            columnField: 'email',
            renderContent: () => (
              <Typography style={getCellStyle(params.row.user)}>{userEmail}</Typography>
            ),
          });
        },
      },
      {
        field: 'workPosition',
        headerName: t('entities.user.fields.workPosition.label'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<UserPermission>) => {
          const userWorkPosition = params.row.user.workPosition;

          return renderCellContent({
            entityType: 'users',
            params,
            isLoading,
            columnField: 'workPosition',
            renderContent: () => <Typography>{userWorkPosition}</Typography>,
          });
        },
      },
      {
        field: 'isGroupAdmin',
        headerName: t('entities.groupsPermissions.fields.isGroupAdmin.label'),
        flex: 1,
        type: 'boolean',
        renderCell: (params: GridRenderCellParams<UserPermission>) => {
          const isSameOrg = params.row.user.organizationId === group?.organizationId;

          return isSameOrg ? (
            <Checkbox
              checked={params.row.isGroupAdmin}
              disabled={!canEdit}
              onChange={(e) => {
                onChangeUserPermission(params.row, 'isGroupAdmin', e.target.checked);
              }}
            />
          ) : (
            <Tooltip title={t('entities.groupsPermissions.fields.isGroupAdmin.help.external')}>
              <span>
                <Checkbox checked={params.row.isGroupAdmin} disabled />
              </span>
            </Tooltip>
          );
        },
      },
      {
        field: 'context-menu',
        headerName: '',
        flex: 1,
        type: 'string',
        renderHeader: () => (
          <Box display="flex" justifyContent="end" width="100vw">
            <Button
              color="secondary"
              disabled={entities.length === 0 || !canEdit}
              onClick={onDeleteAllUsers}
              startIcon={<XOutline />}
              variant="text"
            >
              {t('pages.groupsPermissions.create.components.btnRemoveAllUsers')}
            </Button>
          </Box>
        ),
        renderCell: (params: GridRenderCellParams<UserPermission>) => (
          <Box display="flex" justifyContent="end" width="100%">
            <Button
              color="secondary"
              disabled={!canEdit}
              onClick={handleDeleteUser(params.row.user.id)}
              startIcon={<XOutline />}
              variant="text"
            >
              {t('pages.groupsPermissions.create.components.btnRemoveUser')}
            </Button>
          </Box>
        ),
        sortable: false,
      },
    ],
    [
      t,
      isLoading,
      theme,
      getCellStyle,
      group?.organizationId,
      canEdit,
      onChangeUserPermission,
      entities.length,
      onDeleteAllUsers,
      handleDeleteUser,
    ],
  );

  const rows: GridRowsProp = entities.map((entity) => ({
    id: entity.user.id,
    isGroupAdmin: entity.isGroupAdmin,
    user: entity.user,
  }));

  return (
    <Box width="100%">
      <DataGrid
        autoHeight
        columns={columns}
        disableColumnMenu
        disableRowSelectionOnClick
        loading={isLoading}
        rows={rows}
        sortingOrder={['asc', 'desc']}
      />
    </Box>
  );
};
