import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { useEffect, type FC } from 'react';
import { useForm, useFormState, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';

import { BottomFormNavigation } from '@components/BottomFormNavigation';
import { FormInput, FormSectionHeader } from '@components/FormElements';
import { OrganizationSelect } from '@components/FormElements/OrganizationSelect';
import type { FetchError } from '@hooks/api/customErrors';
import {
  useGroupsCreateApi,
  useGroupsPermissionsApi,
  useGroupsUpdateApi,
  type Group,
} from '@hooks/api/groups';
import { useOrganizationsApi } from '@hooks/api/organizations';
import { useHandleFetchError } from '@hooks/api/useHandleFetchError';
import { useRenderConfig } from '@hooks/ui';
import { usePlatformPermissions } from '@hooks/usePlatformPermissions';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

import { schemaCreateStepOverview } from './schema';
import type { OutletContext } from './types';
import { useFormGroupState } from './useFormGroupState';

interface GroupsFormOverviewProps {
  isEdit?: boolean;
  isDeepUpInternal?: boolean;
}

export const GroupsFormOverview: FC<GroupsFormOverviewProps> = ({
  isEdit = false,
  isDeepUpInternal = false,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { showMessage } = useSnackbarMessage();
  const { handleCancel, handleGoNext } = useOutletContext<OutletContext>();

  const {
    permissions: { organizationId: adminOrganizationId },
  } = usePlatformPermissions();
  const { isGroupAdmin } = useGroupsPermissionsApi();

  const createGroup = useGroupsCreateApi();
  const updateGroupOverview = useGroupsUpdateApi();
  const { data: organizationsData, error: organizationsError } = useOrganizationsApi({
    isDeepUpOrg: isDeepUpInternal,
  });
  const { group } = useFormGroupState();
  const { isItemEnabled } = useRenderConfig();
  const isFormEnabled = isItemEnabled('groups.edit.form.mode');
  const { register, handleSubmit, control } = useForm({
    resolver: yupResolver(
      schemaCreateStepOverview({
        name: t('entities.groupsPermissions.fields.name.error'),
        organization: t('entities.groupsPermissions.fields.organization.error'),
        onlySpaces: t('entities.groupsPermissions.fields.name.errorOnlySpaces'),
      }),
    ),
  });
  const { errors: formErrors, dirtyFields } = useFormState({ control });
  const handleFetchError = useHandleFetchError();

  const handleSubmitAndNext = async (data: FieldValues) => {
    let response;

    try {
      if (group?.id) {
        response = await updateGroupOverview({
          ...data,
          id: group?.id,
          version: group?.version,
        } as Group);

        showMessage({
          message: t('pages.groupsPermissions.edit.messages.successUpdateOverview'),
          type: 'success',
        });
      } else {
        response = await createGroup(data);
      }

      handleGoNext(response);
    } catch (error) {
      handleFetchError(error as FetchError, 'groupsPermissions');
    }
  };

  const isOrganizationEnabled = isItemEnabled('groups.overview.form.fields.organization');
  const isGroupCreateEnabled = isItemEnabled('groups.create.form.mode');
  const canEdit = isGroupCreateEnabled || isGroupAdmin;

  useEffect(() => {
    const hasFormErrors = Object.keys(formErrors).length > 0;
    const hasDirtyFields = Object.keys(dirtyFields).length > 0;

    if (hasFormErrors && !hasDirtyFields) {
      showMessage({ message: t('common.missingData'), type: 'error' });
    }
  }, [formErrors, t, showMessage, dirtyFields]);

  if (!organizationsData || (id && !group)) return <Box>Waiting for data...</Box>;
  if (organizationsError) return <Box>Error while fetching organizations data</Box>;

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit(handleSubmitAndNext)}>
        <Box display="flex" flexDirection="column">
          <Box marginBottom={4}>
            <FormSectionHeader title={t('common.masterData')} />
            <Box display="flex" flexDirection="column">
              <FormInput
                defaultValue={group?.name}
                disabled={!canEdit}
                error={formErrors.name}
                formFieldName="name"
                isRequired
                label={t('entities.groupsPermissions.fields.name.label')}
                register={register}
              />
              <OrganizationSelect
                control={control}
                defaultValue={group?.organizationId ?? adminOrganizationId}
                disabled={isEdit || !isOrganizationEnabled}
                error={formErrors.organizationId}
                formFieldName="organizationId"
                isRequired
                items={organizationsData.content}
                label={t('entities.groupsPermissions.fields.organization.label')}
              />
            </Box>
          </Box>
          <BottomFormNavigation
            hasNext={isFormEnabled || isGroupAdmin}
            nextTitle={`${t('common.save')} & ${t('common.next')}`}
            nextType="submit"
            onGoBack={handleCancel}
          />
        </Box>
      </form>
    </Box>
  );
};
