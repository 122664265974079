import { useTranslation } from 'react-i18next';

import { useProjectApiExportValidation } from '@hooks/api/projects';
import type { ProjectMappingError } from '@pages/Projects/ProjectsForm';

import {
  getConsolidatedErrorMessages,
  getFormattedErrorDetails,
  getFormattedErrorMessages,
  getLocalisedErrorMessages,
} from './helper';

export const useFileValidation = () => {
  const { t } = useTranslation();
  const validateFile = useProjectApiExportValidation();

  return async (
    uploadedFile?: File,
  ): Promise<[success: boolean, message?: ProjectMappingError]> => {
    if (uploadedFile) {
      try {
        await validateFile(uploadedFile);
      } catch (error) {
        const errorStringRaw = (error as Error).toString().replace('FetchError: ', '');
        const errors = JSON.parse(errorStringRaw).errors;

        const consolidatedErrors = getConsolidatedErrorMessages(errors);
        const fileErrorsLocalised = getLocalisedErrorMessages(t, consolidatedErrors);

        return [
          false,
          {
            title: t('pages.projects.create.uploadApiExport.messages.validationFailed.title'),
            message: getFormattedErrorMessages(fileErrorsLocalised),
            details: getFormattedErrorDetails(fileErrorsLocalised),
          },
        ];
      }
    } else
      return [
        false,
        {
          title: t('pages.projects.create.uploadApiExport.messages.errorNoFilesUploaded.title'),
          message: t('pages.projects.create.uploadApiExport.messages.errorNoFilesUploaded.message'),
          details: undefined,
        },
      ];

    return [true, undefined];
  };
};
