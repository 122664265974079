import { TrashcanOutline } from '@deepup/icons';
import { Stack, Typography, useTheme } from '@mui/material';
import type { ReactNode } from 'react';

export const ApiMappingContentBox = ({
  content,
  contentInfo,
  onDelete,
  title,
  icon,
}: {
  content: ReactNode;
  contentInfo?: ReactNode;
  onDelete?: () => void;
  title: string;
  icon: ReactNode;
}) => {
  const theme = useTheme();

  return (
    <Stack gap={1} marginTop={2.5}>
      <Typography color="secondary" fontWeight={theme.typography.fontWeightBold} variant="overline">
        {title}
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        gap={2}
        padding={1.5}
        sx={{
          borderWidth: 1,
          borderColor: theme.palette.secondary.light,
          borderStyle: 'solid',
          borderRadius: theme.spacing(1),
          backgroundColor: theme.palette.background.paper,
        }}
      >
        {icon}
        <Stack flex={1}>
          <Typography fontWeight={theme.typography.fontWeightBold}>{content}</Typography>
          <Typography color="secondary">{contentInfo}</Typography>
        </Stack>
        {onDelete && (
          <Stack mx={1} onClick={onDelete} sx={{ cursor: 'pointer' }}>
            <TrashcanOutline
              fill={theme.palette.secondary.main}
              height={theme.spacing(3)}
              width={theme.spacing(3)}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
