import { useAuth0 } from '@auth0/auth0-react';
import { GlobalHeader } from '@deepup/global-header';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

const AuthenticatedInfo: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box display="flex" justifyContent="center" padding={2}>
      <Box alignItems="center" display="flex" flexDirection="column">
        <Typography>{t('common.alreadyRegistered')}</Typography>
        <Box my={2} />
        <Box>
          <Button
            onClick={() => {
              navigate('/');
            }}
            variant="contained"
          >
            {t('common.goBack')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const UserRegister: FC = () => {
  const { isAuthenticated } = useAuth0();
  const { i18n } = useTranslation();

  if (isAuthenticated) return <AuthenticatedInfo />;

  return (
    <Box>
      <GlobalHeader
        language={i18n.language as 'de' | 'en' | undefined}
        languageSelectors={[{ key: 'de' }, { key: 'en' }]}
        onChangeLanguage={(selectedLanguage) => {
          i18n.changeLanguage(selectedLanguage);
        }}
        showUserMenu={false}
        title="Organization Management"
      />
      <Box display="flex" justifyContent="center">
        <Outlet />
      </Box>
    </Box>
  );
};
