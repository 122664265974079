import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const RightsDataSubjects = () => {
  const { t } = useTranslation();

  return (
    <Box my={4}>
      <Typography variant="h5">
        {t('pages.privacyPolicy.sections.rightsDataSubjects.title')}
      </Typography>
      <Box my={1} />
      <Typography>
        {t('pages.privacyPolicy.sections.rightsDataSubjects.content.prelude')}
      </Typography>
      <ul>
        <li>
          <Typography component="span" fontWeight="bold">
            {t('pages.privacyPolicy.sections.rightsDataSubjects.content.list.rightToObject')}
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.rightsDataSubjects.content.list.rightOfWithdrawal'
              }
            >
              <Typography component="span" fontWeight="bold">
                Widerrufsrecht bei Einwilligungen:
              </Typography>{' '}
              Sie haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen.
            </Trans>
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={'pages.privacyPolicy.sections.rightsDataSubjects.content.list.rightOfAccess'}
            >
              <Typography component="span" fontWeight="bold">
                Auskunftsrecht:
              </Typography>{' '}
              Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten
              verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen
              und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
            </Trans>
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.rightsDataSubjects.content.list.rightToRectification'
              }
            >
              <Typography component="span" fontWeight="bold">
                Recht auf Berichtigung:
              </Typography>{' '}
              Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die Vervollständigung der
              Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu
              verlangen.
            </Trans>
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.rightsDataSubjects.content.list.rightToErasure'
              }
            >
              <Typography component="span" fontWeight="bold">
                Recht auf Löschung und Einschränkung der Verarbeitung:
              </Typography>{' '}
              Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie
              betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der
              gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen. Dies
              kann zur Folge haben, dass Sie unsere Dienste nicht mehr bzw. nur noch stark
              eingeschränkt nutzen können.
            </Trans>
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.rightsDataSubjects.content.list.rightToDataPortability'
              }
            >
              <Typography component="span" fontWeight="bold">
                Recht auf Datenübertragbarkeit:
              </Typography>{' '}
              Sie haben das Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach
              Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und
              maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen anderen
              Verantwortlichen zu fordern.
            </Trans>
          </Typography>
        </li>
        <li>
          <Typography>
            <Trans
              i18nKey={
                'pages.privacyPolicy.sections.rightsDataSubjects.content.list.complaintAuthority'
              }
            >
              <Typography component="span" fontWeight="bold">
                Beschwerde bei Aufsichtsbehörde:
              </Typography>{' '}
              Entsprechend den gesetzlichen Vorgaben und unbeschadet eines anderweitigen
              verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs, haben Sie ferner das Recht,
              bei einer Datenschutzaufsichtsbehörde, insbesondere einer Aufsichtsbehörde im
              Mitgliedstaat, in dem Sie sich gewöhnlich aufhalten, der Aufsichtsbehörde Ihres
              Arbeitsplatzes oder des Ortes des mutmaßlichen Verstoßes, eine Beschwerde einzulegen,
              wenn Sie der Ansicht sei sollten, dass die Verarbeitung der Ihre Person betreffenden
              personenbezogenen Daten gegen die DSGVO verstößt.
            </Trans>
          </Typography>
        </li>
      </ul>
    </Box>
  );
};
